import { Component, Input, OnDestroy } from "@angular/core";
import { SpinnerService } from "../../services/spinner.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
})
export class SpinnerComponent implements OnDestroy {
  showSpinner = false;
  subscription: Subscription;
  disableBackdropClick = true;
  _threshold = 40;
  _zIndex = 9999;

  constructor(private spinnerService: SpinnerService) {
    this.createServiceSubscription();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Sets z-index
   */
  @Input()
  public set zIndex(value: number) {
    this._zIndex = value;
  }

  /**
   * Gets z-index
   */
  public get zIndex(): number {
    return this._zIndex;
  }

  /**
   * Accepts external threshold
   */
  @Input()
  public set threshold(value: number) {
    this._threshold = value;
  }

  /**
   * gets the threshold
   */
  public get threshold(): number {
    return this._threshold;
  }

  private createServiceSubscription(): void {
    let timer: any;

    this.subscription = this.spinnerService.getMessage().subscribe((show) => {
      if (show) {
        if (timer) {
          return;
        }
        timer = setTimeout(() => {
          timer = null;
          this.showSpinner = show;
        }, this.threshold);
      } else {
        if (timer) {
          clearTimeout(timer);
          timer = null;
        }
        this.showSpinner = false;
      }
    });
  }
}
