// **********************************************************************
// START Trimester-based anatomy constants which are deprecated as of v1.11
// **********************************************************************
// EARLY PREGNANCY VARIABLES
export const epGeneralEvalFields = [
  "gestationsObs",
  "embryoVis",
  "fetalPosition",
  "cervixObsEval",
  "cervixObs",
  "cervixLength",
  "placentaVisualized",
  "placentalVisualization",
  "placentaObs",
  "placentalGradeEval",
  "placentalGrade",
  "yolkSacVis",
  "yolkSacEval",
  "yolkSacObs",
  "yolkSacLength",
  "afvObs",
];

// EP Fetal bio
export const epFetalBioFields = [
  "estimatedFetalAge",
  "crownRumpLength",
  "crownRumpLengthCalcGestAge",
  "crownRumpLengthCalcPerc",
  "gestationalSacLocation",
  "gestationalSacDiameter",
  "gestationalSacDiameterCalcGestAge",
  "gestationalSacDiameterCalcPerc",
  "bestOverallAssessment",
  "bestGestationAgeWeeks",
  "bestGestationAgeDays",
];

// EP Maternal structures
export const epMaternalStructuresFields = [
  "uterusObsEval",
  "uterusObs",
  "extrauterineMassObsEval",
  "extrauterineMassObs",
  "extrauterineMassLength",
  "extrauterineMassHeight",
  "extrauterineMassWidth",
  "rightOvaryObsEval",
  "rightOvaryObs",
  "rightOvaryLength",
  "rightOvaryHeight",
  "rightOvaryWidth",
  "rightAdnexalMassObsEval",
  "rightAdnexalMassObs",
  "rightAdnexalMassLength",
  "rightAdnexalMassHeight",
  "rightAdnexalMassWidth",
  "leftOvaryObsEval",
  "leftOvaryObs",
  "leftOvaryLength",
  "leftOvaryHeight",
  "leftOvaryWidth",
  "leftAdnexalMassObsEval",
  "leftAdnexalMassObs",
  "leftAdnexalMassLength",
  "leftAdnexalMassHeight",
  "leftAdnexalMassWidth",
];

// EP Brain
export const epBrainFields = ["craniumObsEval", "craniumObs"];

// EP Cardiac
export const epCardiacFields = ["cardiacActivity", "fetalHeartRate"];

// EP Impressions
export const epImpressionsFields = [
  "impressionEval",
  "impressionObs",
  "impressionNotes",
  "additionalNotes",
  "viewLimitations",
];

export const epDiagnosisFields = [
  "impressionEval",
  "impressionObs",
  "impressionNotes",
  "viewLimitations",
  "additionalNotes",
  "officeVisit",
  "followUp",
];

/* *** FIRST TRIMESTER VARIABLES *** */

export const ftGeneralEvalFields = [
  "gestationsObs",
  "embryoVis",
  "fetalPosition",
  "cervixObsEval",
  "cervixObs",
  "cervixLength",
  "placentaVisualized",
  "placentalVisualization",
  "placentaObs",
  "placentalGradeEval",
  "placentalGrade",
  "placentalCordInsertionObs",
  "umbCordObsEval",
  "umbCordObs",
  "yolkSacVis",
  "yolkSacEval",
  "yolkSacLength",
  "yolkSacObs",
  "afvObs",
];

export const ftMaternalStructuresFields = [
  "uterusObsEval",
  "uterusObs",
  "extrauterineMassObsEval",
  "extrauterineMassObs",
  "extrauterineMassLength",
  "extrauterineMassHeight",
  "extrauterineMassWidth",
  "rightOvaryObsEval",
  "rightOvaryObs",
  "rightOvaryLength",
  "rightOvaryHeight",
  "rightOvaryWidth",
  "rightAdnexalMassObsEval",
  "rightAdnexalMassObs",
  "rightAdnexalMassLength",
  "rightAdnexalMassHeight",
  "rightAdnexalMassWidth",
  "leftOvaryObsEval",
  "leftOvaryObs",
  "leftOvaryLength",
  "leftOvaryHeight",
  "leftOvaryWidth",
  "leftAdnexalMassObsEval",
  "leftAdnexalMassObs",
  "leftAdnexalMassLength",
  "leftAdnexalMassHeight",
  "leftAdnexalMassWidth",
];

export const ftFetalBioFields = [
  "estimatedFetalAge",
  "crownRumpLength",
  "crownRumpLengthCalcGestAge",
  "crownRumpLengthCalcPerc",
  "gestationalSacLocation",
  "gestationalSacLocationObs",
  "gestationalSacDiameter",
  "gestationalSacDiameterCalcGestAge",
  "gestationalSacDiameterCalcPerc",
];

export const ftBrainFields = [
  "posteriorFossaObsEval",
  "posteriorFossaObs",
  "nuchalTranslucency",
  "choroidPlexusObsEval", // Supports backwards compatibility of study notes from before v1.10
  "choroidPlexusObs", // Supports backwards compatibility of study notes from before v1.10
  "rightChoroidPlexusObsEval",
  "rightChoroidPlexusObs",
  "leftChoroidPlexusObsEval",
  "leftChoroidPlexusObs",
  "midlineFalxObsEval",
  "midlineFalxObs",
  "craniumObsEval",
  "craniumObs",
];

export const ftFaceFields = [
  "profileObsEval",
  "profileObs",
  "nasalBoneObsEval",
  "nasalBoneObs",
  "orbitsObsEval",
  "orbitsObs",
];

export const ftCardiacFields = [
  "cardiacActivity",
  "fetalHeartRate",
  "heart4ObsEval",
  "heart4Obs",
  "lvotObsEval",
  "lvotObs",
  "rvotObsEval",
  "rvotObs",
  "heart3ObsEval",
  "heart3Obs",
  "situsObsEval",
  "situsObs",
  "aorticObsEval",
  "aorticObs",
  "siVcObsEval",
  "siVcObs",
  "ductalArchObsEval",
  "ductalArchObs",
  "diaphragmObsEval",
  "diaphragmObs",
];

export const ftAbdomenFields = [
  "stomachObsEval",
  "stomachObs",
  "kidneysObsEval", // Supports backwards compatibility of study notes from before v1.10
  "kidneysObs", // Supports backwards compatibility of study notes from before v1.10
  "rightKidneyObsEval",
  "rightKidneyObs",
  "leftKidneyObsEval",
  "leftKidneyObs",
  "bladderObsEval",
  "bladderObs",
  "abdCordObsEval",
  "abdCordObs",
];

export const ftSpineFields = [
  "spineObsEval", // Supports backwards compatibility of study notes from before v1.10
  "spineObs", // Supports backwards compatibility of study notes from before v1.10
  "cSpineObsEval",
  "cSpineObs",
  "tSpineObsEval",
  "tSpineObs",
  "lSpineObsEval",
  "lSpineObs",
  "sSpineObsEval",
  "sSpineObs",
];

export const ftExtremitiesFields = [
  "legsObsEval", // Supports backwards compatibility of study notes from before v1.10
  "legsObs", // Supports backwards compatibility of study notes from before v1.10
  "armsObsEval", // Supports backwards compatibility of study notes from before v1.10
  "armsObs", // Supports backwards compatibility of study notes from before v1.10
  "handsObsEval", // Supports backwards compatibility of study notes from before v1.10
  "handsObs", // Supports backwards compatibility of study notes from before v1.10
  "feetObsEval", // Supports backwards compatibility of study notes from before v1.10
  "feetObs", // Supports backwards compatibility of study notes from before v1.10
  "rightLegObsEval",
  "rightLegObs",
  "rightFootObsEval",
  "rightFootObs",
  "rightArmObsEval",
  "rightArmObs",
  "rightHandObsEval",
  "rightHandObs",
  "leftLegObsEval",
  "leftLegObs",
  "leftFootObsEval",
  "leftFootObs",
  "leftArmObsEval",
  "leftArmObs",
  "leftHandObsEval",
  "leftHandObs",
];

export const ftImpressionsFields = [
  "impressionEval",
  "impressionObs",
  "impressionNotes",
  "additionalNotes",
  "viewLimitations",
  // "officeVisit",
  // "followUp",
];

export const ftDiagnosisFields = [
  "impressionEval",
  "impressionObs",
  "impressionNotes",
  "additionalNotes",
  "viewLimitations",
  "officeVisit",
  "followUp",
];

/* *** SECOND TRIMESTER VARIABLES *** */

export const stGeneralEvalFields = [
  "gestationsObs",
  "fetusPresentation",
  "cervixObsEval",
  "cervixObs",
  "cervixLength",
  "placentaVisualized",
  "placentalVisualization",
  "placentaObs",
  "placentalGradeEval",
  "placentalGrade",
  "placentalCordInsertionObs",
  "umbCordObsEval",
  "umbCordObs",
  "placentaToCervix",
  "maxVerticalPocketLength",
  "afvObs",
  "amnioticFluidIndexS1Q1",
  "amnioticFluidIndexS1Q2",
  "amnioticFluidIndexS1Q3",
  "amnioticFluidIndexS1Q4",
  // "afiTotalCalc", // TODO: Do we want this field? Commented out as part of T1-1292
];

export const stMaternalStructuresFields = [
  "uterusObsEval",
  "uterusObs",
  "extrauterineMassObsEval",
  "extrauterineMassObs",
  "extrauterineMassLength",
  "extrauterineMassHeight",
  "extrauterineMassWidth",
  "rightOvaryObsEval",
  "rightOvaryObs",
  "rightOvaryLength",
  "rightOvaryHeight",
  "rightOvaryWidth",
  "rightAdnexalMassObsEval",
  "rightAdnexalMassObs",
  "rightAdnexalMassLength",
  "rightAdnexalMassHeight",
  "rightAdnexalMassWidth",
  "leftOvaryObsEval",
  "leftOvaryObs",
  "leftOvaryLength",
  "leftOvaryHeight",
  "leftOvaryWidth",
  "leftAdnexalMassObsEval",
  "leftAdnexalMassObs",
  "leftAdnexalMassLength",
  "leftAdnexalMassHeight",
  "leftAdnexalMassWidth",
];

export const stFetalBioFields = [
  "estimatedFetalAge",
  "estimatedFetalWeight",
  "biparietalDiameter",
  "biparietalDiameterCalcPerc",
  "biparietalDiameterCalcGestAge",
  "headCircumference",
  "headCircumferenceCalcPerc",
  "headCircumferenceCalcGestAge",
  "occipitalFrontDiameterCalc",
  "cephalicIndexCalc",
  "cephalicIndexRange",
  "abdominalCircumference",
  "abdominalCircumferenceCalcPerc",
  "abdominalCircumferenceCalcGestAge",
  "hcAcRatioCalc",
  "hcAcRatioRange",
  "femurLength",
  "femurLengthCalcPerc",
  "femurLengthCalcGestAge",
  "flBpdRatioCalc",
  "flBpdRatioRange",
  "flAcRatioCalc",
  "flAcRatioRange",
  "tibiaLength",
  "fibulaLength",
  "humerusLength",
  "humerusLengthCalcPerc",
  "humerusLengthCalcGestAge",
  "ulnaLength",
  "radiusLength",
];

export const stBrainFields = [
  "posteriorFossaObsEval",
  "posteriorFossaObs",
  "cerebellarDiameter",
  "cerebellarDiameterCalcPerc",
  "cerebellarDiameterCalcGestAge",
  "cisternaMagna",
  "nuchalFold",
  "lateralVentricle",
  "choroidPlexusObsEval", // Supports backwards compatibility of study notes from before v1.10
  "choroidPlexusObs", // Supports backwards compatibility of study notes from before v1.10
  "rightChoroidPlexusObsEval",
  "rightChoroidPlexusObs",
  "leftChoroidPlexusObsEval",
  "leftChoroidPlexusObs",
  "midlineFalxObsEval",
  "midlineFalxObs",
  "cavumSeptiPellucidiObsEval",
  "cavumSeptiPellucidiObs",
  "craniumObsEval",
  "craniumObs",
  "neckSkinObsEval",
  "neckSkinObs",
];

export const stFaceFields = [
  "profileObsEval",
  "profileObs",
  "nasalBoneObsEval",
  "nasalBoneObs",
  "noseLipsObsEval",
  "noseLipsObs",
  "palateObsEval",
  "palateObs",
  "orbitsObsEval",
  "orbitsObs",
];

export const stCardiacFields = [
  "cardiacActivity",
  "fetalHeartRate",
  "heart4ObsEval",
  "heart4Obs",
  "lvotObsEval",
  "lvotObs",
  "rvotObsEval",
  "rvotObs",
  "thymusObsEval",
  "thymusObs",
  "heart3ObsEval",
  "heart3Obs",
  "situsObsEval",
  "situsObs",
  "aorticObsEval",
  "aorticObs",
  "siVcObsEval",
  "siVcObs",
  "ductalArchObsEval",
  "ductalArchObs",
  "diaphragmObsEval",
  "diaphragmObs",
];

export const stAbdomenFields = [
  "stomachObsEval",
  "stomachObs",
  "kidneysObsEval", // Supports backwards compatibility of study notes from before v1.10
  "kidneysObs", // Supports backwards compatibility of study notes from before v1.10
  "rightKidneyObsEval",
  "rightKidneyObs",
  "leftKidneyObsEval",
  "leftKidneyObs",
  "bladderObsEval",
  "bladderObs",
  "abdCordObsEval",
  "abdCordObs",
  "genitaliaObsEval",
  "genitaliaObs",
  "sexEval",
];

export const stSpineFields = [
  "spineObsEval", // Supports backwards compatibility of study notes from before v1.10
  "spineObs", // Supports backwards compatibility of study notes from before v1.10
  "cSpineObsEval",
  "cSpineObs",
  "tSpineObsEval",
  "tSpineObs",
  "lSpineObsEval",
  "lSpineObs",
  "sSpineObsEval",
  "sSpineObs",
];

export const stExtremitiesFields = [
  "legsObsEval", // Supports backwards compatibility of study notes from before v1.10
  "legsObs", // Supports backwards compatibility of study notes from before v1.10
  "armsObsEval", // Supports backwards compatibility of study notes from before v1.10
  "armsObs", // Supports backwards compatibility of study notes from before v1.10
  "handsObsEval", // Supports backwards compatibility of study notes from before v1.10
  "handsObs", // Supports backwards compatibility of study notes from before v1.10
  "feetObsEval", // Supports backwards compatibility of study notes from before v1.10
  "feetObs", // Supports backwards compatibility of study notes from before v1.10
  "rightLegObsEval",
  "rightLegObs",
  "rightFootObsEval",
  "rightFootObs",
  "rightArmObsEval",
  "rightArmObs",
  "rightHandObsEval",
  "rightHandObs",
  "leftLegObsEval",
  "leftLegObs",
  "leftFootObsEval",
  "leftFootObs",
  "leftArmObsEval",
  "leftArmObs",
  "leftHandObsEval",
  "leftHandObs",
];

// BPP
export const stBPPFields = [
  "grossBodyMovement",
  "grossBodyMovementObs",
  "fetalTone",
  "fetalToneObs",
  "fetalBreathing",
  "fetalBreathingObs",
  "afv",
  "amnioticFluidVolumeObs",
];

export const stImpressionsFields = [
  "impressionEval",
  "impressionObs",
  "impressionNotes",
  "additionalNotes",
  "viewLimitations",
];

export const stDiagnosisFields = [
  "impressionEval",
  "impressionObs",
  "impressionNotes",
  "additionalNotes",
  "viewLimitations",
  "officeVisit",
  "followUp",
];

/* *** THIRD TRIMESTER VARIABLES *** */
export const ttGeneralEvalFields = [
  "gestationsObs",
  "fetusPresentation",
  "cervixObsEval",
  "cervixObs",
  "cervixLength",
  "placentaVisualized",
  "placentalVisualization",
  "placentaObs",
  "placentalGradeEval",
  "placentalGrade",
  "placentalCordInsertionObs",
  "umbCordObsEval",
  "umbCordObs",
  "placentaToCervix",
  "maxVerticalPocketLength",
  "afvObs",
  "amnioticFluidIndexS1Q1",
  "amnioticFluidIndexS1Q2",
  "amnioticFluidIndexS1Q3",
  "amnioticFluidIndexS1Q4",
  // "afiTotalCalc", // TODO: Do we want this field? Commented out as part of T1-1292
];

export const ttMaternalStructuresFields = [
  "uterusObsEval",
  "uterusObs",
  "extrauterineMassObsEval",
  "extrauterineMassObs",
  "extrauterineMassLength",
  "extrauterineMassHeight",
  "extrauterineMassWidth",
  "rightOvaryObsEval",
  "rightOvaryObs",
  "rightOvaryLength",
  "rightOvaryHeight",
  "rightOvaryWidth",
  "rightAdnexalMassObsEval",
  "rightAdnexalMassObs",
  "rightAdnexalMassLength",
  "rightAdnexalMassHeight",
  "rightAdnexalMassWidth",
  "leftOvaryObsEval",
  "leftOvaryObs",
  "leftOvaryLength",
  "leftOvaryHeight",
  "leftOvaryWidth",
  "leftAdnexalMassObsEval",
  "leftAdnexalMassObs",
  "leftAdnexalMassLength",
  "leftAdnexalMassHeight",
  "leftAdnexalMassWidth",
];

export const ttFetalBioFields = [
  "estimatedFetalAge",
  "estimatedFetalWeight",
  "biparietalDiameter",
  "biparietalDiameterCalcPerc",
  "biparietalDiameterCalcGestAge",
  "headCircumference",
  "headCircumferenceCalcPerc",
  "headCircumferenceCalcGestAge",
  "occipitalFrontDiameterCalc",
  "cephalicIndexCalc",
  "cephalicIndexRange",
  "abdominalCircumference",
  "abdominalCircumferenceCalcPerc",
  "abdominalCircumferenceCalcGestAge",
  "hcAcRatioCalc",
  "hcAcRatioRange",
  "femurLength",
  "femurLengthCalcPerc",
  "femurLengthCalcGestAge",
  "flBpdRatioCalc",
  "flBpdRatioRange",
  "flAcRatioCalc",
  "flAcRatioRange",
  "tibiaLength",
  "fibulaLength",
  "humerusLength",
  "humerusLengthCalcPerc",
  "humerusLengthCalcGestAge",
  "ulnaLength",
  "radiusLength",
];

export const ttBrainFields = [
  "posteriorFossaObsEval",
  "posteriorFossaObs",
  "cerebellarDiameter",
  "cerebellarDiameterCalcPerc",
  "cerebellarDiameterCalcGestAge",
  "cisternaMagna",
  "lateralVentricle",
  "choroidPlexusObsEval", // Supports backwards compatibility of study notes from before v1.10
  "choroidPlexusObs", // Supports backwards compatibility of study notes from before v1.10
  "rightChoroidPlexusObsEval",
  "rightChoroidPlexusObs",
  "leftChoroidPlexusObsEval",
  "leftChoroidPlexusObs",
  "midlineFalxObsEval",
  "midlineFalxObs",
  "cavumSeptiPellucidiObsEval",
  "cavumSeptiPellucidiObs",
  "craniumObsEval",
  "craniumObs",
  "neckSkinObsEval",
  "neckSkinObs",
];

export const ttFaceFields = [
  "profileObsEval",
  "profileObs",
  "nasalBoneObsEval",
  "nasalBoneObs",
  "noseLipsObsEval",
  "noseLipsObs",
  "palateObsEval",
  "palateObs",
  "orbitsObsEval",
  "orbitsObs",
];

export const ttCardiacFields = [
  "cardiacActivity",
  "fetalHeartRate",
  "heart4ObsEval",
  "heart4Obs",
  "lvotObsEval",
  "lvotObs",
  "rvotObsEval",
  "rvotObs",
  "thymusObsEval",
  "thymusObs",
  "heart3ObsEval",
  "heart3Obs",
  "situsObsEval",
  "situsObs",
  "aorticObsEval",
  "aorticObs",
  "siVcObsEval",
  "siVcObs",
  "ductalArchObsEval",
  "ductalArchObs",
  "diaphragmObsEval",
  "diaphragmObs",
];

export const ttAbdomenFields = [
  "stomachObsEval",
  "stomachObs",
  "kidneysObsEval", // Supports backwards compatibility of study notes from before v1.10
  "kidneysObs", // Supports backwards compatibility of study notes from before v1.10
  "rightKidneyObsEval",
  "rightKidneyObs",
  "leftKidneyObsEval",
  "leftKidneyObs",
  "bladderObsEval",
  "bladderObs",
  "abdCordObsEval",
  "abdCordObs",
  "genitaliaObsEval",
  "genitaliaObs",
  "sexEval",
];

export const ttSpineFields = [
  "spineObsEval", // Supports backwards compatibility of study notes from before v1.10
  "spineObs", // Supports backwards compatibility of study notes from before v1.10
  "cSpineObsEval",
  "cSpineObs",
  "tSpineObsEval",
  "tSpineObs",
  "lSpineObsEval",
  "lSpineObs",
  "sSpineObsEval",
  "sSpineObs",
];

export const ttExtremitiesFields = [
  "legsObsEval", // Supports backwards compatibility of study notes from before v1.10
  "legsObs", // Supports backwards compatibility of study notes from before v1.10
  "armsObsEval", // Supports backwards compatibility of study notes from before v1.10
  "armsObs", // Supports backwards compatibility of study notes from before v1.10
  "handsObsEval", // Supports backwards compatibility of study notes from before v1.10
  "handsObs", // Supports backwards compatibility of study notes from before v1.10
  "feetObsEval", // Supports backwards compatibility of study notes from before v1.10
  "feetObs", // Supports backwards compatibility of study notes from before v1.10
  "rightLegObsEval",
  "rightLegObs",
  "rightFootObsEval",
  "rightFootObs",
  "rightArmObsEval",
  "rightArmObs",
  "rightHandObsEval",
  "rightHandObs",
  "leftLegObsEval",
  "leftLegObs",
  "leftFootObsEval",
  "leftFootObs",
  "leftArmObsEval",
  "leftArmObs",
  "leftHandObsEval",
  "leftHandObs",
];

export const ttBPPFields = stBPPFields;

export const ttImpressionsFields = [
  "impressionEval",
  "impressionObs",
  "impressionNotes",
  "additionalNotes",
  "viewLimitations",
  // "officeVisit",
  // "followUp",
];

export const ttDiagnosisFields = [
  "impressionEval",
  "impressionObs",
  "impressionNotes",
  "additionalNotes",
  "viewLimitations",
  "officeVisit",
  "followUp",
];
// **********************************************************************
// END Trimester-based anatomy constants which are deprecated as of v1.11
// **********************************************************************

// Deprecated as of v1.11 w/ shift to CPT code from pregnancy stage - Andrew 11/28/23
export const earlyPregEvaluationSections = [
  { type: "general-eval", label: "General Evaluation" },
  { type: "maternal-structures", label: "Maternal Structures" },
  { type: "fetal-bio", label: "Fetal Biometry" },
  { type: "brain", label: "Brain" },
  { type: "cardiac", label: "Cardiac" },
  { type: "impressions", label: "Impressions" },
  { type: "diagnosis", label: "Diagnosis" },
];

// Deprecated as of v1.11 w/ shift to CPT code from pregnancy stage - Andrew 11/28/23
export const firstTriEvaluationSections = [
  { type: "general-eval", label: "General Evaluation" },
  { type: "maternal-structures", label: "Maternal Structures" },
  { type: "fetal-bio", label: "Fetal Biometry" },
  { type: "brain", label: "Brain" },
  { type: "face", label: "Face" },
  { type: "cardiac", label: "Cardiac" },
  { type: "abdomen", label: "Abdomen" },
  { type: "spine", label: "Spine" },
  { type: "extremities", label: "Extremities" },
  { type: "impressions", label: "Impressions" },
  { type: "diagnosis", label: "Diagnosis" },
];

// Deprecated as of v1.11 w/ shift to CPT code from pregnancy stage - Andrew 11/28/23
export const secondTriEvaluationSections = [
  { type: "general-eval", label: "General Evaluation" },
  { type: "maternal-structures", label: "Maternal Structures" },
  { type: "fetal-bio", label: "Fetal Biometry" },
  { type: "brain", label: "Brain" },
  { type: "face", label: "Face" },
  { type: "cardiac", label: "Cardiac" },
  { type: "abdomen", label: "Abdomen" },
  { type: "spine", label: "Spine" },
  { type: "extremities", label: "Extremities" },
  { type: "bpp", label: "Biophysical Profile" },
  { type: "impressions", label: "Impressions" },
  { type: "diagnosis", label: "Diagnosis" },
];

// Deprecated as of v1.11 w/ shift to CPT code from pregnancy stage - Andrew 11/28/23
export const thirdTriEvaluationSections = [
  { type: "general-eval", label: "General Evaluation" },
  { type: "maternal-structures", label: "Maternal Structures" },
  { type: "fetal-bio", label: "Fetal Biometry" },
  { type: "brain", label: "Brain" },
  { type: "face", label: "Face" },
  { type: "cardiac", label: "Cardiac" },
  { type: "abdomen", label: "Abdomen" },
  { type: "spine", label: "Spine" },
  { type: "extremities", label: "Extremities" },
  { type: "bpp", label: "Biophysical Profile" },
  { type: "impressions", label: "Impressions" },
  { type: "diagnosis", label: "Diagnosis" },
];

export const evaluationSections = [
  { type: "general-eval", label: "General" },
  { type: "maternal-structures", label: "Maternal" },
  { type: "fetal-bio", label: "Biometry" },
  { type: "brain", label: "Brain" },
  { type: "face", label: "Face" },
  { type: "cardiac", label: "Cardiac" },
  { type: "abdomen", label: "Abdomen" },
  { type: "spine", label: "Spine" },
  { type: "extremities", label: "Extremities" },
  { type: "bpp", label: "BPP" },
  { type: "impressions", label: "Impressions" },
  { type: "diagnosis", label: "Diagnosis" },
];

export const deprecatedStudyNotes = {
  // Supports backwards compatibility of study notes from before v1.10
  // All initialized false and updated in object copy when found in a study
  choroidPlexusObs: false,
  kidneysObs: false,
  spineObs: false,
  legsObs: false,
  armsObs: false,
  handsObs: false,
  feetObs: false,
};

export const impressionsSelections = [
  { key: "normalFetalAnatomy", value: "Normal Fetal Anatomy" },
  { key: "incompleteFetalAnatomy", value: "Incomplete Fetal Anatomy" },
  { key: "followUpFetalAnatomy", value: "Follow Up Fetal Anatomy" },
];
