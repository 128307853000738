import { PipeTransform, Pipe } from "@angular/core";
import { SonographerCalc } from "../services/sonographer-calc";

@Pipe({ name: "calcDisplay" })
export class CalcDisplayPipe implements PipeTransform {
  constructor(private _sonographerCalc: SonographerCalc) {}
  transform(val: string | number, type: string): string {
    let valToDisplay: string;
    if (!val || !type) {
      return null;
    }
    val = val.toString();
    if (type === "percentile") {
      valToDisplay =
        val === "Unable to Calculate"
          ? val
          : `${Number.parseFloat(val).toFixed(1)} %`;
    }
    if (type === "ga") {
      valToDisplay =
        val !== " > 40 weeks" ? this._sonographerCalc.formatAge(val) : val;
    }
    if (type === "lbs") {
      const poundsOunces = Number.parseFloat(val) * 0.0022046;
      const pounds = Math.floor(poundsOunces);
      const ounces = (poundsOunces - Math.floor(poundsOunces)) * 16;
      valToDisplay = `${pounds} lbs ${ounces.toFixed(2)} oz`;
    }
    if (type === "ratio") {
      valToDisplay = `${Number.parseFloat(val).toFixed(2)}`;
    }
    return valToDisplay;
  }
}
