import { NgModule, Optional, SkipSelf } from "@angular/core";
import { GlobalModule } from "../global/global.module";
import { LoggedInLayoutComponent } from "./components/logged-in-layout/logged-in-layout.component";

@NgModule({
  declarations: [LoggedInLayoutComponent],
  imports: [GlobalModule],
})
export class CoreModule {
  public constructor(@SkipSelf() @Optional() parent: CoreModule) {
    if (parent) {
      throw new Error(
        "CoreModule is already loaded. Import it in the AppModule only"
      );
    }
  }
}
