import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { TenantService } from "src/app/core/services/tenant.service";

@Injectable()
export class TenantGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _ts: TenantService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const { tenant } = route.params;
    const tenantUpper = tenant.toUpperCase();

    if (this._ts.getTenant()) {
      if (this._ts.getTenant() === tenantUpper) {
        return true;
      }

      this._ts
        .checkValidTenants(tenantUpper)
        .then((res) => {
          if (res) {
            this._ts.setTenant(tenantUpper);
            this._router.navigate([`${tenantUpper}/login`]);
            return false;
          }
          this._router.navigate([`/institution/invalid`]);
          return false;
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this._ts
        .checkValidTenants(tenantUpper)
        .then((res) => {
          if (res) {
            this._ts.setTenant(tenantUpper);
            if (route.params.verification_token) {
              this._router.navigate([
                `${tenantUpper}/your-images/${route.params.verification_token}`,
              ]);
              return true;
            }
            if (route.url[1]?.path === "reset-password" && route.url[2]) {
              this._router.navigate([
                `${tenantUpper}/reset-password/${route.url[2].path}`,
              ]);
              return true;
            }

            if (route.url[1]?.path === "register" && route.url[2]) {
              this._router.navigate([
                `${tenantUpper}/register/${route.url[2].path}`,
              ]);
              return true;
            }

            this._router.navigate([`${tenantUpper}/login`]);
            return true;
          }
          this._router.navigate([`/institution/invalid`]);
          return false;
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return false;
  }
}
