import { Directive, ElementRef, HostListener, Input } from "@angular/core";
import {
  Validator,
  NG_VALIDATORS,
  ValidatorFn,
  FormControl,
} from "@angular/forms";

@Directive({
  selector: "[numberValidator]",
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useClass: IntakeNumberValidatorDirective,
      multi: true,
    },
  ],
})
export class IntakeNumberValidatorDirective implements Validator {
  validator: ValidatorFn;
  constructor(private _elementRef: ElementRef) {
    this.validator = this.numberValidator();
  }

  @Input() latestInputValue: number;
  @HostListener("keydown", ["$event"])
  onInput(e: any): void {
    if (e.which === 69) {
      e.preventDefault();
    }
  }

  validate(c: FormControl): any {
    return this.validator(c);
  }

  numberValidator(): ValidatorFn {
    // Set min and max here
    const min = this._elementRef.nativeElement.attributes.min
      ? this._elementRef.nativeElement.attributes.min.value
      : -1;
    const max = this._elementRef.nativeElement.attributes.max
      ? this._elementRef.nativeElement.attributes.max.value
      : 999999;
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return (control: FormControl) => {
      if (control.value != null && control.value !== "") {
        const isValid = control.value >= min && control.value < max;
        if (isValid) {
          return null;
        }
        return {
          minvalidator: { valid: control.value >= min },
          maxvalidator: { valid: control.value < max },
        };
      }
      return null;
    };
  }
}
