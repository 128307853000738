/**
 * An enum of formula authors.
 */
export enum Author {
  SampleAuthor = "John Smith",
  NoAuthor = "NoAuthor",
  Hellman = "Hellman",
  Hadlock = "Hadlock",
  Hohler = "Hohler",
  Campbell = "Campbell",
  Salomon = "Salomon",
  Hill = "Hill",
  Jeanty = "Jeanty",
}
