import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "dateDisplay" })
export class DateDisplayPipe implements PipeTransform {
  transform(val: string): string {
    if (!val) {
      return null;
    }

    const dateObj = typeof val === "string" ? new Date(val) : val;

    // Date string formated as M/D/YYYY
    return `${
      dateObj.getMonth() + 1
    }/${dateObj.getDate()}/${dateObj.getFullYear()}`;
  }
}
