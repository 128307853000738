import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Patient } from "src/app/models/patient";
import { ActivatedRoute } from "@angular/router";
import { CPTCodesType, PatientOrderForm } from "../../../models";
import { PatientOrderService } from "../../services/patient-order.service";
import { JWTTokenService } from "../../../core/services/jwt.service";
import { CptService } from "../../services/cpt.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
  selector: "cpt-dropdown",
  templateUrl: "./cpt-dropdown.component.html",
  styleUrls: ["./cpt-dropdown.component.scss"],
})
export class CptDropdownComponent implements OnInit, OnDestroy {
  @Input() patient: Patient;
  @ViewChild("patientNotesDropdown") patientNotesDropdown: ElementRef;
  changeTimeout: ReturnType<typeof setTimeout>;
  private isOpen = false;
  public isModal = false;
  public patientOrder: PatientOrderForm;
  public cptCodes: CPTCodesType;
  public cptText = "";

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private _patientOrderService: PatientOrderService,
    private _jwtService: JWTTokenService,
    private _cptService: CptService
  ) {}

  toggled(): void {
    this.isOpen = !this.isOpen;
  }

  ngOnInit(): void {
    this.patientOrder = this._patientOrderService.get();
    // when the intake survey gets updated names we need the current patient name
    this._patientOrderService
      .getPatientOrderObs()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((patientOrder) => {
        this.patientOrder = patientOrder;
        this.cptCodes = this._cptService.filterGrowthFollowUp(
          this._cptService.getCptCodes(this.patientOrder.reportConfig)
        );
        this.cptText = this._cptService.getCptLabel(
          this.patientOrder.reportConfig,
          true
        );
      });

    this.route.queryParams.subscribe((params) => {
      this.isModal = !!params["isModal"];
    });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
