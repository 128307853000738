// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoggedInLayoutComponent } from "src/app/core/components/logged-in-layout/logged-in-layout.component";
import { AuthGuard } from "src/app/core/guard/auth.guard";
import { TenantGuard } from "src/app/core/guard/tenant.guard";
import { ForgotPasswordComponent } from "src/app/modules/user/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "src/app/modules/user/reset-password/reset-password.component";
import { RegisterComponent } from "src/app/modules/user/register/register.component";
import { AdminGuard } from "src/app/core/guard/admin.guard";
import { RedirectGuard } from "./core/guard/redirect.guard";

const routes: Routes = [
  {
    path: "",
    canActivate: [RedirectGuard],
    component: RedirectGuard,
    data: {
      externalUrl: "https://www.bbimaging.net/telescan",
    },
  },
  {
    path: ":tenant",
    redirectTo: ":tenant/login",
    pathMatch: "full",
  },
  {
    path: ":tenant/patient-order",
    component: LoggedInLayoutComponent,
    canActivate: [TenantGuard, AuthGuard],
    loadChildren: () =>
      import("./modules/patient-order/patient-order.module").then(
        (module) => module.PatientOrderModule
      ),
  },
  {
    path: ":tenant/user",
    canActivate: [TenantGuard, AuthGuard],
    loadChildren: () =>
      import("./modules/user/user.module").then((module) => module.UserModule),
  },
  {
    path: ":tenant/admin",
    component: LoggedInLayoutComponent,
    canActivate: [AdminGuard, AuthGuard],
    loadChildren: () =>
      import("./modules/admin/admin.module").then(
        (module) => module.AdminModule
      ),
  },
  {
    path: ":tenant/register/:authId",
    component: RegisterComponent,
    canActivate: [TenantGuard],
  },
  {
    path: ":tenant/forgot-password",
    component: ForgotPasswordComponent,
    canActivate: [TenantGuard],
  },
  {
    path: ":tenant/reset-password/:token",
    component: ResetPasswordComponent,
    canActivate: [TenantGuard],
  },
  {
    path: ":tenant/download",
    component: LoggedInLayoutComponent,
    canActivate: [TenantGuard, AuthGuard],
    loadChildren: () =>
      import("./modules/download/download.module").then(
        (module) => module.DownloadModule
      ),
  },
  {
    path: ":tenant/login",
    canActivate: [TenantGuard],
    loadChildren: () =>
      import("./modules/login/login.module").then(
        (module) => module.LoginModule
      ),
  },
  {
    path: ":tenant/your-images",
    loadChildren: () =>
      import("./modules/images-for-mom/images-for-mom.module").then(
        (module) => module.ImagesForMomModule
      ),
  },
  {
    path: ":tenant/backend/patient-order/:poID/report",
    loadChildren: () =>
      import("./modules/patient-order/report/report.module").then(
        (module) => module.ReportModule
      ),
  },
  {
    path: ":tenant/backend/image-generator",
    loadChildren: () =>
      import("./modules/images-for-mom/images-for-mom.module").then(
        (module) => module.ImagesForMomModule
      ),
  },
  {
    path: ":tenant/backend/patient-order/:poID/final-images/:token",
    loadChildren: () =>
      import("./modules/backend/backend.module").then(
        (module) => module.BackendModule
      ),
  },
  {
    path: "institution/invalid",
    loadChildren: () =>
      import("./modules/institution-invalid/institution-invalid.module").then(
        (module) => module.InstitutionInvalidModule
      ),
  },
  { path: "**", redirectTo: "institution/invalid", pathMatch: "full" },
  // Fallback when no prior routes are matched
  // { path: "**", redirectTo: "/login", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
      scrollOffset: [0, 0],
      anchorScrolling: "enabled",
      relativeLinkResolution: "legacy",
      paramsInheritanceStrategy: "always",
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
