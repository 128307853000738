import { Component, OnInit } from "@angular/core";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
import { TenantService } from "./core/services/tenant.service";
import { SpinnerService } from "./global/services/spinner.service";
import { RxjsService } from "./global/services/rxjs-service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  public render = false;

  constructor(
    private _router: Router,
    private _ts: TenantService,
    private _spinnerService: SpinnerService,
    private _rxjsService: RxjsService
  ) {
    this.render = true;
  }

  ngOnInit(): void {
    this._router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        this._spinnerService.show();
        const url = event.url === "/" ? "" : event.url;
        const urlAndQuery = url.split("?");
        const pathMap = urlAndQuery[0].split("/");
        const firstPathPart = pathMap[1];
        const validUrls = ["login", "patient-order", "user"];

        if (this._ts.getTenant() && validUrls.includes(firstPathPart)) {
          const updateUrl = `/${this._ts.getTenant()}${url}`;
          this._router.navigate([updateUrl]);
        }
      }
      if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError
      ) {
        this._spinnerService.hide();
      }
    });
  }
}
