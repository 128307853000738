import * as yup from "yup";
import { evaluationSelectors } from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographer3rdTriV2SpineExtremitiesSchema = yup
  .object()
  .shape({
    spineObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Spine",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    spineObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    legsObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Legs",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    legsObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    armsObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Arms",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    armsObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    handsObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Hands",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    handsObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    feetObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Feet",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    feetObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
  })
  .required();

type Sonographer3rdTriV2SpineExtremetiesType = yup.InferType<
  typeof sonographer3rdTriV2SpineExtremitiesSchema
>;

export {
  sonographer3rdTriV2SpineExtremitiesSchema,
  Sonographer3rdTriV2SpineExtremetiesType,
};
