import { PipeTransform, Pipe } from "@angular/core";

/**
 * Takes in number of days and outputs X weeks Y days
 */
@Pipe({ name: "weeksDaysTransform" })
export class WeeksDaysTransformPipe implements PipeTransform {
  transform(val: number | string, isShort?: boolean): string {
    if (typeof val !== "number") return val;
    if (!val) return "--";
    const roundedVal = Math.round(val);
    let weeks = Math.floor(roundedVal / 7);
    // Days need to be rounded (normal rounding rules, i.e. round up if greater than 5) to match GE Volusion Machine
    let days = roundedVal % 7;
    if (days === 7) {
      days = 0;
      weeks += 1;
    }

    if (isShort) {
      return `${weeks}w ${days}d`;
    }

    return `${weeks} week${weeks > 1 ? "s" : ""} ${days} day${
      days > 1 ? "s" : ""
    }`;
  }
}
