import { Injectable, OnDestroy } from "@angular/core";

import { Subscription } from "rxjs";
import { ConfigStore } from "../stores/config-store";

@Injectable({ providedIn: "root" })
export class BootstrapService implements OnDestroy {
  public subscription: Subscription;
  constructor(private _configStore: ConfigStore) {}

  private _bootstrapped = false;
  public isBootstrapped(): boolean {
    return this._bootstrapped;
  }

  public clear(): void {
    this._bootstrapped = false;
  }

  public bootstrap(): Promise<any> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line unicorn/no-useless-undefined
      this._configStore.setProfile(undefined);
      // this._userService
      //   .getMyProfile()
      //   .then((data) => {
      //     this._bootstrapped = true;
      //     this._configStore.setProfile(data);
      //     return resolve(this._bootstrapped);
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //     reject(error);
      //   });
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
