import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PopoverModule } from "ngx-bootstrap/popover";
import { StateButtonComponent } from "./state-button.component";

@NgModule({
  declarations: [StateButtonComponent],
  imports: [CommonModule, PopoverModule.forRoot()],
  providers: [],
  exports: [StateButtonComponent],
})
export class ComponentsStateButtonModule {}
