import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class SpinnerService {
  public spinnerSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor() {}

  /**
   * show a loading spinner
   */
  show(): void {
    this.spinnerSubject.next(true);
  }

  /**
   * hide a loading spinner
   */
  hide(): void {
    this.spinnerSubject.next(false);
  }

  getMessage(): Observable<any> {
    return this.spinnerSubject.asObservable();
  }
}
