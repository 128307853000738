import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { JWTTokenService } from "src/app/core/services/jwt.service";
import { CptService } from "src/app/global/services/cpt.service";
import { PatientOrderService } from "src/app/global/services/patient-order.service";
import { PatientOrderForm, CPTCodesType } from "src/app/models";

@Component({
  selector: "cpt-code-selector",
  templateUrl: "./cpt-code-selector.component.html",
  styleUrls: ["./cpt-code-selector.component.scss"],
})
export class CPTCodeSelectorComponent implements OnInit {
  public patientOrder: PatientOrderForm;
  public cptCodes: CPTCodesType;
  public currentUserRole: string;

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private _patientOrderService: PatientOrderService,
    private _jwtService: JWTTokenService,
    private _cptService: CptService
  ) {}

  ngOnInit(): void {
    this._patientOrderService
      .getPatientOrderObs()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((patientOrder) => {
        this.patientOrder = patientOrder;
        this.cptCodes = this._cptService.filterGrowthFollowUp(
          this._cptService.getCptCodes(this.patientOrder.reportConfig)
        );
      });

    const token = this._jwtService.getParsedToken();
    this.currentUserRole = token.role;
  }

  /**
   * Sets a cptCode as checked or unchecked and sets anatomy for the
   * CPT code as checked or unchecked in the patient order.
   * @param cptCode
   * @returns
   */
  async setVisitType(cptCode: CPTCodesType[number]): Promise<void> {
    const codeIdx = this.cptCodes.findIndex((code) => code.id === cptCode.id);
    if (codeIdx < 0) return;
    this.cptCodes[codeIdx].checked = !this.cptCodes[codeIdx].checked;
    this.cptCodes = this._cptService.filterGrowthFollowUp(
      await this._cptService.setCptCode(this.cptCodes[codeIdx])
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
