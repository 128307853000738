import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AlertService } from "src/app/global/services/alert.service";
import { environment } from "../../../environments/environment";

@Injectable({ providedIn: "root" })
/**
 * Error handling codes are expected to align with those listed at https://httpstatuses.com/
 */
export class BBIErrorHandler implements ErrorHandler {
  private _env: any;
  constructor(
    private _http: HttpClient,
    private _injector: Injector
  ) {
    this._env = environment;
  }

  // private get alertService(): AlertService {
  //   return this._injector.get(AlertService);
  // }

  /**
   * handleError is required for the ErrorHandler hook. Global error notification might stem from here (11/1/21, Josh Cross).
   * @param error - this should be of type HTTPErrorResponse
   */
  handleError(error: Error | HttpErrorResponse): any {
    const alertService = this._injector.get(AlertService);
    // TODO: Add this back in after we have an endpoint for logging errors
    // this.logError(error);
    let message;
    const options = {
      autoClose: false,
      keepAfterRouteChange: true,
    };
    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = error.error.message;
      return alertService.error(message, options);
    }
    // Client Error
    // Ideally client errors will only be sent when we call them in each component
    // message = !navigator.onLine
    //   ? "No Internet Connection.  Please check your network and try again."
    //   : null;
    return message ? alertService.error(message, options) : null;
  }

  async logError(error: any): Promise<void> {
    if (this._env.stage !== "localhost") {
      const url = `${this._env.bbApiUrl}/ops/log/`;
      await this._http
        .post(url, {
          message: error.message,
          err: error,
          stacktrace: error.stack,
        })
        .toPromise();
    }
    throw error;
  }
}
