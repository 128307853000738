export interface APIResponse {
  somethign: string;
}

// SWEEP_EVENTS exists as an ENUM in study-service/src/sweep/sweep.dto.ts
export const SWEEP_EVENTS = [
  "HEART_BEAT",
  "SWEEP_RECEIVED",
  "LOW_RES_SWEEP_UPLOAD_BEGIN",
  "LOW_RES_SWEEP_UPLOAD_COMPLETE",
  "SWEEP_UPLOAD_BEGIN",
  "SWEEP_UPLOAD_COMPLETE",
] as const;

export interface StreamEvent {
  data: {
    clinicId: string;
    examRoomId: string;
    institutionId: string;
    message: string;
    studyId: string;
    sweepIndex: string;
    timestamp: string;
    type: string;
  };
  eventType: (typeof SWEEP_EVENTS)[number];
}
