<nav class="navbar">
  <div class="align-items-center navbar-left scrolling-navbar ml-4">

    <a gaEvent="header_mobile_menu" gaCategory="header_logged_in" gaLabel="Header Mobile Menu"
    (click)="mobileMenuButtonClick($event)" href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>
    <div class="row">
        <img src="./assets/images/telescanlogo-registered.svg" class="logo-nav">
    </div>
    <div class="row mt-3">
      <div class="col-12">{{institutionName}}</div>
    </div>
  </div>

  <div class="navbar-right" *ngIf="createUserRegisterPage == false">
    <div class="user-icons">
      <a gaEvent="header_orders" gaCategory="header_logged_in" gaLabel="Header Orders"
        class="d-inline-block navmenu-icon-spacing icon-link" (click)="routeTo('orderList')">
        <img src="assets/images/ordersicon.svg" class="d-inline-block align-text-bottom">
        <span class="navmenu-icon-text">Orders</span>
      </a>
      <a gaEvent="header_display_name" gaCategory="header_logged_in" gaLabel="Header Display Name"
      class="d-inline-block navmenu-icon-spacing align-content-center icon-link navbar-profile-link" [routerLink]="">
        <img alt="Profile Picture" class="d-inline-block align-text-bottom" src="assets/images/profileicon.svg" />
        <span class="navmenu-icon-text">Hi, {{displayName}}</span>
      </a>
      <div id="user_menu_section">
        <div id="user_menu">
          <div gaEvent="header_view_profile" gaCategory="header_logged_in" gaLabel="Header View Profile" class="user_menu_link user_menu_first" (click)="viewProfile()">View Profile</div>
          <div gaEvent="header_log_out" gaCategory="header_logged_in" gaLabel="Header Log Out" class="user_menu_link user_menu_last" (click)="onSignOut()">Log out</div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!--title for page-->
<div class="container-fluid mt-3 mb-1" *ngIf="mainPageHeader && mainPageHeader !== ''">
  <div class="container-fluid">
    <div class="header-title">
      <h2>{{mainPageHeader}}</h2>
    </div>
  </div>
</div>
