import { NgModule } from "@angular/core";
import { NgSelectModule } from "@ng-select/ng-select";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AlertComponent } from "./components/alert/alert.component";
import { ComponentsStateButtonModule } from "./state-button/components.state-button.module";
import { DataNotEnteredPipe } from "./pipes/data-not-entered.pipe";
import { FeetAndInchesPipe } from "./pipes/feet-and-inches.pipe";
import { DicomDateTransformPipe } from "./pipes/dicom-date-transform.pipe";
import { TimeAgoPipe } from "./pipes/time-ago.pipe";
import { WeeksDaysTransformPipe } from "./pipes/weeks-days-transform.pipe";
import { ReadableStudyStatusPipe } from "./pipes/readable-study-status.pipe";
import { BbiHeaderComponent } from "./components/bbi-header/bbi-header.component";
import { BbiFooterComponent } from "./components/bbi-footer/bbi-footer.component";
import { BbiAdminHeaderComponent } from "./components/bbi-admin-header/bbi-admin-header.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { CalcDisplayPipe } from "./pipes/calc-display.pipe";
import { AppendPipe } from "./pipes/append.pipe";
import { DecimalPipe } from "./pipes/decimal.pipe";
import { PhoneFormatDirective } from "./directives/phone-format.directive";
import { PatientIdValidatorDirective } from "./directives/custom-validators/patient-id-validator.directive";
import { IntakeNumberValidatorDirective } from "./directives/custom-validators/number-validator.directive";
import { HumanizePipe } from "./pipes/humanize.pipe";
import { GAnalyticsDirective } from "./directives/g-analytics.directive";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { YesNoPipe } from "./pipes/yes-no.pipe";
import { DropdownComponent } from "./components/dropdown/dropdown.component";
import { TextareaGrowDirective } from "./directives/textarea-grow.directive";
import { DateDisplayPipe } from "./pipes/date-display.pipe";
import { CPTCodeDisplayLabelsPipe } from "./pipes/cpt-display-labels.pipe";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { CPTCodeSelectorComponent } from "./components/cpt-code-selector/cpt-code-selector.component";
import { CptDropdownComponent } from "./components/cpt-dropdown/cpt-dropdown.component";
import { PatientNotesComponent } from "./components/patient-notes/patient-notes.component";
import { SafePipe } from "./pipes/safe.pipe";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    // FontAwesomeModule,
  ],
  // declarations: [ControlMessagesComponent, SpinnerComponent],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsStateButtonModule,
    SpinnerComponent,
    AlertComponent,
    DataNotEnteredPipe,
    AppendPipe,
    DecimalPipe,
    FeetAndInchesPipe,
    HumanizePipe,
    SafePipe,
    YesNoPipe,
    DateDisplayPipe,
    DicomDateTransformPipe,
    WeeksDaysTransformPipe,
    TimeAgoPipe,
    ReadableStudyStatusPipe,
    CalcDisplayPipe,
    CPTCodeDisplayLabelsPipe,
    BbiHeaderComponent,
    BbiAdminHeaderComponent,
    BbiFooterComponent,
    DropdownComponent,
    PhoneFormatDirective,
    PatientIdValidatorDirective,
    IntakeNumberValidatorDirective,
    GAnalyticsDirective,
    NgbModule,
    NgSelectModule,
    NgbDropdownModule,
    TextareaGrowDirective,
    CPTCodeSelectorComponent,
    CptDropdownComponent,
    PatientNotesComponent,
  ],
  declarations: [
    SpinnerComponent,
    AlertComponent,
    DataNotEnteredPipe,
    AppendPipe,
    DecimalPipe,
    FeetAndInchesPipe,
    HumanizePipe,
    SafePipe,
    YesNoPipe,
    DateDisplayPipe,
    DicomDateTransformPipe,
    WeeksDaysTransformPipe,
    TimeAgoPipe,
    CalcDisplayPipe,
    ReadableStudyStatusPipe,
    CPTCodeDisplayLabelsPipe,
    BbiHeaderComponent,
    BbiAdminHeaderComponent,
    BbiFooterComponent,
    DropdownComponent,
    PhoneFormatDirective,
    PatientIdValidatorDirective,
    IntakeNumberValidatorDirective,
    GAnalyticsDirective,
    TextareaGrowDirective,
    CPTCodeSelectorComponent,
    CptDropdownComponent,
    PatientNotesComponent,
  ],
  providers: [],
})
export class GlobalModule {}
