// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stage: "dev",
  root: "/app",
  bbApiUrl: "https://api-dev.telescan.tech",
  // These vars are empty strings because we use the gateway for routing
  studyPort: "",
  institutionPort: "",
  patientPort: "",
  diagnosticPort: "",
  calculationPort: "",
  userPort: "",
  authPort: "",
  gaMeasurementId: "G-DFKMHFCHTW",
  inputDateFormat: "MM/DD/YYYY", // should move into an organizations settings
};
