import { PipeTransform, Pipe } from "@angular/core";
import { CptService } from "../services/cpt.service";
import { AnnotationFilterListType } from "src/app/modules/patient-order/report/models/report-view.model";

const DEFAULT_LABEL = "Configuration";

@Pipe({ name: "cptCodeDisplayLabels" })
export class CPTCodeDisplayLabelsPipe implements PipeTransform {
  constructor(private _cptService: CptService) {}
  transform(
    reportConfig: AnnotationFilterListType[] | string,
    showCodes?: boolean
  ): string {
    let displayLabels = "";
    let hasAnatSurv = false;

    const cptCodes = this._cptService.getCptCodes(reportConfig);
    cptCodes.forEach((code) => {
      if (code.checked) {
        // If this is a 76805 set hasAnatSurv flag to true
        if (code.id === 2) hasAnatSurv = true;

        // If this is a 76805 exam skip displaying 76816 because it is a subset of a 76805 but should not display like that
        if (hasAnatSurv && code.id === 5) {
          return;
        }

        // If this is NOT the first label add the seperator
        displayLabels += displayLabels.length > 0 ? ", " : "";
        // Add the label
        displayLabels += code["label"];
        // Add CPT code if specified
        if (showCodes) displayLabels += ` (${code["cptCodes"]})`;
      }
    });
    // A default label should be used in places where we cannot have an empty label
    // If there are no labels and codes should be included, display default label
    if (displayLabels.length === 0)
      displayLabels = showCodes ? DEFAULT_LABEL : "--";
    return displayLabels;
  }
}
