import { Injectable } from "@angular/core";

import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { LocalStorageService } from "./local-storage.service";

const TENANT_KEY = "tenant";

@Injectable({ providedIn: "root" })
export class TenantService {
  constructor(
    private _http: HttpClient,
    private _localStorage: LocalStorageService
  ) {}

  public checkValidTenants(tenantCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          `
          ${environment.bbApiUrl}${environment.institutionPort}/institution/checkTenant/${tenantCode}
          `
        )
        .toPromise()
        .then((data: any) => {
          return resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  }

  setTenant(val: string): void {
    if (val) {
      this._localStorage.set(TENANT_KEY, val);
    }
  }

  getTenant(): string | null {
    return this._localStorage.get(TENANT_KEY);
  }

  removeTenant(): boolean {
    this._localStorage.remove(TENANT_KEY);
    return true;
  }
}
