import * as moment from "moment-timezone";
import { Injectable } from "@angular/core";
import { Author } from "../enums/author";
import {
  IRange,
  ILookUpTableRow,
  StudyNote,
  PatientOrderForm,
} from "../../models";
import { CalculationName } from "../enums/calculation";
import { CalculationService } from "./calculation-service";
import { unableToCalculateValue } from "../constants/sonographer-constants";
import { PatientOrderService } from "./patient-order.service";
import { CptService } from "./cpt.service";
import { AnnotationService } from "./annotation.service";
import { JWTTokenService } from "src/app/core/services/jwt.service";

export interface SonographerCalculationResult {
  percentile?: string;
  ga?: number;
  age: string;
  outOfRange: boolean;
}
export interface SonographerCalculationEfwResult {
  efw?: string;
  efwLbs?: string;
  efwGrams?: string;
  percentile?: string;
  outOfRange: boolean;
}
@Injectable({ providedIn: "root" })
export class SonographerCalc {
  constructor(
    private _calculationService: CalculationService,
    private _patientOrderService: PatientOrderService,
    private _annotationService: AnnotationService,
    private _cptService: CptService,
    private _jwtService: JWTTokenService
  ) {}

  private formatPercentile(percentile: number): string {
    if (!percentile) return;
    return `${percentile?.toFixed(1)}%`; // toFixed is rounding up. 97.25.toFixed(1) => 97.3
  }

  private isPercentileOutOfRange(
    percentile: number,
    lowerLimit = 3,
    upperLimit = 5
  ): boolean {
    return percentile < lowerLimit || percentile > upperLimit;
  }

  public formatAge(ageWeeks: number | string): string {
    if (typeof ageWeeks !== "number") ageWeeks = Number.parseFloat(ageWeeks);
    // Get the decimal portion of the ageWeeks
    const fractionalWeeks = ageWeeks - Math.floor(ageWeeks);

    // Set weeks to the integer portion of the ageWeeks
    let weeks = ageWeeks - fractionalWeeks;

    // Days need to be rounded (normal rounding rules, i.e. round up if greater than 5) to match GE Volusion Machine
    let days = Math.round(fractionalWeeks * 7);
    if (days === 7) {
      days = 0;
      weeks += 1;
    }
    return `${weeks}w ${days}d`;
  }

  public formatAgeFromDays(totalDays: number | string): string {
    if (typeof totalDays !== "number") totalDays = Number.parseFloat(totalDays);

    // Use standard rounding for days if it's a decimal
    totalDays = Math.round(totalDays);

    let weeks = Math.floor(totalDays / 7);
    let days = Math.round(totalDays % 7);

    if (days === 7) {
      days = 0;
      weeks += 1;
    }

    return `${weeks} week${weeks > 1 ? "s" : ""} ${days} day${
      days !== 1 ? "s" : ""
    }`;
  }

  public ageSince(mmddyyyyStart: string, mmddyyyyEnd: string): string {
    const m = moment(mmddyyyyStart);
    return this.formatAge(moment(mmddyyyyEnd).diff(m, "weeks", true));
  }

  /**
   *
   * @param lmp Last Menstrual Period, Expected format: yyyyMMDD
   * @param edd Estimated Due Date, Expected format: yyyyMMDD
   * @param examDate Exam Date, Expected format: yyyyMMDD
   * @param dueDateMethodology string: 'Last Menstrual Period' | 'Estimated Due Date' |
   * @returns string: 'early-pregnancy' | 'first-trimester' | 'second-trimester' | 'third-trimester'
   */
  public calculateDiagnosticPregnancyStage(
    lmp: string,
    edd: string,
    examDate: string,
    dueDateMethodology: string
  ): string {
    let calc;
    switch (dueDateMethodology) {
      case "Last Menstrual Period": {
        calc = this.gaDaysByLMP(lmp, examDate);
        break;
      }
      case "Estimated Due Date": {
        calc = this.gaDaysByEDD(examDate, edd);
        break;
      }
      default: {
        calc = this.gaDaysByLMP(lmp, examDate);
        break;
      }
    }
    return this.pregnancyStageByDays(calc);
  }

  public pregnancyStageByDays(days: number): string {
    if (days <= 76) {
      return "early-pregnancy";
    }
    if (days <= 97) {
      return "first-trimester";
    }
    if (days <= 195) {
      return "second-trimester";
    }
    return "third-trimester";
  }

  /**
   *
   * @param lmp Expected format: yyyyMMDD
   * @param examDate Expected format: yyyyMMDD
   * @returns Number: Difference in Days
   */
  public gaDaysByLMP(lmp: string | number, examDate: string | Date): number {
    if (typeof lmp === "number") {
      lmp = lmp.toString();
    }
    const lmpMoment = moment.utc(lmp);
    const daysDiff = moment.utc(examDate).diff(lmpMoment, "days");
    return Number(daysDiff);
  }

  public gaDaysByEDD(examDate: string | Date, edd: string | number): number {
    if (typeof edd === "number") {
      edd = edd.toString();
    }

    const eddMoment = moment.utc(edd);
    const examDateMoment = moment.utc(examDate);
    const exDiff = eddMoment.diff(examDateMoment, "days");
    const daysDiff = 280 - exDiff;
    return daysDiff;
  }

  private poundsAndOunces(grams: number): string {
    const poundsOunces = grams * 0.0022046;
    const pounds = Math.floor(poundsOunces);
    const ounces = (poundsOunces - Math.floor(poundsOunces)) * 16;
    if (typeof pounds !== "number" || typeof ounces !== "number") return "";
    return `${pounds} lbs ${ounces?.toFixed(2)} oz`;
  }

  /**
   * Prints the percentile and gestational age by gestational sac diameter
   * @Input mm expected
   * @param gsd - gestational sac diameter measurement in MM
   * @param lmpDate - last menstrual period should be in YYYYMMDD format
   * @param examDate - date of the exam in js Date format
   * @param eddDate - estimated due date by Baseline in YYYYMMDD format
   * @param ivfDate - ivf/conception date in YYYYMMDD format
   */
  public async gestationalSacDiameter(
    gsd: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number,
    ivfDate: number
  ): Promise<SonographerCalculationResult> {
    if (!gsd || gsd === unableToCalculateValue) {
      throw new Error(
        "Gestational Sac Diameter value cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.GestationalSacDiameter,
      Author.Hellman,
      1969,
      {
        gsd,
        lmpDate,
        examDate,
        eddDate,
        ivfDate,
      }
    );

    return {
      percentile: this.formatPercentile(result?.percentile),
      age: this.formatAge(result?.ga),
      outOfRange: this.isPercentileOutOfRange(result?.percentile?.toFixed(1)),
    } as SonographerCalculationResult;
  }

  /**
   * Prints the percentile and gestational age by crown rump length
   * @param crl - crown rump length, cm measurement expected
   * @param lmpDate - last menstrual period should be in YYYYMMDD format
   * @param examDate - date of the exam in js Date format
   * @param eddDate - estimated due date by Baseline in YYYYMMDD format
   * @param ivfDate - ivf/conception date in YYYYMMDD format
   */
  public async crownRumpLength(
    crl: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number,
    ivfDate: number
  ): Promise<SonographerCalculationResult> {
    if (!crl || crl === unableToCalculateValue) {
      throw new Error(
        "Crown Rump Length value cannot be proceeded for calculation."
      );
    }
    const result = await this._calculationService.getCalculation(
      CalculationName.CrownRumpLength,
      Author.Hadlock,
      1992,
      {
        crl,
        lmpDate,
        examDate,
        eddDate,
        ivfDate,
      }
    );

    return {
      percentile: this.formatPercentile(result?.percentile),
      age: this.formatAge(result?.ga),
      outOfRange: this.isPercentileOutOfRange(result?.percentile?.toFixed(1)),
      ga: result?.ga,
    } as SonographerCalculationResult;
  }

  /**
   * Prints the percentile and gestational age by biparietal diameter
   * @param bpd - biparietal diameter, cm measurement expected
   * @param lmpDate - last menstrual period should be in YYYYMMDD format
   * @param examDate - date of the exam in js Date format
   * @param eddDate - estimated due date by Baseline in YYYYMMDD format
   * @param ivfDate - ivf/conception date in YYYYMMDD format
   */
  public async biparietalDiameter(
    bpd: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number,
    ivfDate: number
  ): Promise<SonographerCalculationResult> {
    if (!bpd || bpd === unableToCalculateValue) {
      throw new Error(
        "Biparietal Diameter value cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.BiparietalDiameter,
      Author.Hadlock,
      1984,
      {
        bpd,
        lmpDate,
        examDate,
        eddDate,
        ivfDate,
      }
    );

    return {
      percentile: this.formatPercentile(result?.percentile),
      age: this.formatAge(result?.ga),
      outOfRange: this.isPercentileOutOfRange(result?.percentile?.toFixed(1)),
    } as SonographerCalculationResult;
  }

  /**
   * Prints the percentile and gestational age by head circumference
   * @param hc - head circumference, cm measurement expected
   * @param lmpDate - last menstrual period should be in YYYYMMDD format
   * @param examDate - date of the exam in js Date format
   * @param eddDate - estimated due date by Baseline in YYYYMMDD format
   * @param ivfDate - ivf/conception date in YYYYMMDD format
   */
  public async headCircumference(
    hc: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number,
    ivfDate: number
  ): Promise<SonographerCalculationResult> {
    if (!hc || hc === unableToCalculateValue) {
      throw new Error(
        "Head Circumference value cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.HeadCircumference,
      Author.Hadlock,
      1984,
      {
        hc,
        lmpDate,
        examDate,
        eddDate,
        ivfDate,
      }
    );

    return {
      percentile: this.formatPercentile(result?.percentile),
      age: this.formatAge(result?.ga),
      outOfRange: this.isPercentileOutOfRange(result?.percentile?.toFixed(1)),
    } as SonographerCalculationResult;
  }

  /**
   * Prints the percentile and gestational age by abdominal circumference
   * @param ac - abdominal circumference, cm measurement expected
   * @param lmpDate - last menstrual period should be in YYYYMMDD format
   * @param examDate - date of the exam in js Date format
   * @param eddDate - estimated due date by Baseline in YYYYMMDD format
   * @param ivfDate - ivf/conception date in YYYYMMDD format
   */
  public async abdominalCircumference(
    ac: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number,
    ivfDate: number
  ): Promise<SonographerCalculationResult> {
    if (!ac || ac === unableToCalculateValue) {
      throw new Error(
        "Abdominal Circumference value cannot be proceeded for calculation."
      );
    }
    const result = await this._calculationService.getCalculation(
      CalculationName.AbdominalCircumference,
      Author.Hadlock,
      1984,
      {
        ac,
        lmpDate,
        examDate,
        eddDate,
        ivfDate,
      }
    );

    return {
      percentile: this.formatPercentile(result?.percentile),
      age: this.formatAge(result?.ga),
      outOfRange: this.isPercentileOutOfRange(result?.percentile?.toFixed(1)),
    } as SonographerCalculationResult;
  }

  /*
   * Prints the percentile and gestational age by femur length
   * @param fl - femur length, cm measurement expected
   * @param lmpDate - last menstrual period should be in YYYYMMDD format
   * @param examDate - date of the exam in js Date format
   * @param eddDate - estimated due date by Baseline in YYYYMMDD format
   * @param ivfDate - ivf/conception date in YYYYMMDD format
   */
  public async femurLength(
    fl: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number,
    ivfDate: number
  ): Promise<SonographerCalculationResult> {
    if (!fl || fl === unableToCalculateValue) {
      throw new Error(
        "Femur Length value cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.FemurLength,
      Author.Hadlock,
      1984,
      {
        fl,
        lmpDate,
        examDate,
        eddDate,
        ivfDate,
      }
    );

    return {
      percentile: this.formatPercentile(result?.percentile),
      age: this.formatAge(result?.ga),
      outOfRange: this.isPercentileOutOfRange(result?.percentile?.toFixed(1)),
    } as SonographerCalculationResult;
  }

  /**
   * Prints the percentile and gestational age by humerus length
   * @param hl - biparietal diameter, cm measurement expected
   * @param lmpDate - last menstrual period should be in YYYYMMDD format
   * @param examDate - date of the exam in js Date format
   * @param eddDate - estimated due date by Baseline in YYYYMMDD format
   * @param ivfDate - ivf/conception date in YYYYMMDD format
   */
  public async humerusLength(
    hl: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number,
    ivfDate: number
  ): Promise<SonographerCalculationResult> {
    if (!hl || hl === unableToCalculateValue) {
      throw new Error(
        "Humerus Length value cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.HumerusLength,
      Author.Jeanty,
      1984,
      {
        hl,
        lmpDate,
        examDate,
        eddDate,
        ivfDate,
      }
    );

    return {
      percentile: this.formatPercentile(result?.percentile),
      age: this.formatAge(result?.ga),
      outOfRange: this.isPercentileOutOfRange(
        result?.percentile?.toFixed(1),
        5,
        95
      ),
    } as SonographerCalculationResult;
  }

  /**
   * Prints the percentile and gestational age by biparietal diameter
   * @param tcd - trans cerebellar diameter, mm measurement expected
   * @param lmpDate - last menstrual period should be in YYYYMMDD format
   * @param examDate - date of the exam in js Date format
   * @param eddDate - estimated due date by Baseline in YYYYMMDD format
   * @param ivfDate - ivf/conception date in YYYYMMDD format
   */
  public async cerebellarDiameter(
    tcd: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number,
    ivfDate: number
  ): Promise<SonographerCalculationResult> {
    if (!tcd || tcd === unableToCalculateValue) {
      throw new Error(
        "Trans Cerebellar Diameter value cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.TransCerebellarDiameter,
      Author.Hill,
      1990,
      {
        tcd,
        lmpDate,
        examDate,
        eddDate,
        ivfDate,
      }
    );

    return {
      percentile: this.formatPercentile(result?.percentile),
      age: this.formatAge(result?.ga),
      outOfRange: this.isPercentileOutOfRange(result?.percentile?.toFixed(1)),
    } as SonographerCalculationResult;
  }

  /**
   * @param cDate number: Either LMP or IVF Date in YYYYMMDD format
   * @param isIvf boolean: Was this an IVF pregnancy?
   * @returns Estimated Due Date in YYYYMMDD format
   */
  public calculateEdd(cDate: number, isIvf: boolean): string {
    if (cDate) {
      return moment(cDate.toString())
        .add(isIvf ? 266 : 280, "days")
        .format("YYYYMMDD");
    }
    return null;
  }

  public calculateGa(cDate: number, examDate: Date): number {
    if (!cDate || !examDate) return null;
    return moment(examDate).diff(cDate.toString(), "days");
  }

  public calculateGaByLMPorIVF(
    lmpDate: number,
    conceptionDate: number,
    isIvf: boolean,
    examDate: Date
  ): number {
    try {
      // If it is IVF, we need to go past the conception date and subtract 2 weeks from that date to better match what the LMP would have been at that time
      const cDate = isIvf
        ? Number.parseInt(
            moment(conceptionDate?.toString())
              .subtract(14, "days")
              .format("YYYYMMDD"),
            10
          )?.toString()
        : lmpDate?.toString();
      if (!cDate || !examDate) return null;
      return moment(examDate).diff(cDate, "days");
    } catch (error) {
      console.log("Error in calculateGaByLMPorIVF", error);
    }
  }

  public calculateGaByEdd(edd: number, isIvf: boolean, examDate: Date): number {
    if (edd === null) {
      return null;
    }
    const cDate = moment(edd.toString())
      .subtract(isIvf ? 266 : 280, "days")
      .format("YYYYMMDD");
    return this.calculateGa(Number(cDate), examDate);
  }

  public calculateGaByBaseline(edd: number, examDate: Date): number {
    if (edd === null) {
      return null;
    }
    const cDate = moment(edd.toString())
      .subtract(280, "days")
      .format("YYYYMMDD");
    return this.calculateGa(Number(cDate), examDate);
  }

  /**
   * @param ga number: Gestational age in weeks
   * @param examDate needs to be in Date format
   * @returns Estimated Due Date in YYYYMMDD format
   */
  public calculateEddByGa(ga: number | string, examDate: Date): string {
    if (!ga || !examDate) {
      return null;
    }
    if (typeof ga === "string") ga = Number.parseFloat(ga);
    // Ga needs to be converted to days and needs to be rounded (normal rounding rules, i.e. round up if greater than 5) to match GE Volusion Machine
    const gaInDays = Math.round(ga * 7);

    const diffAmount = 280;
    // Diff amount will be 280, since GA is calculated making sure the 280 is being used whether its IVF or not.
    return moment(examDate)
      .add((diffAmount - gaInDays).toString(), "days")
      .format("YYYYMMDD");
  }

  /**
   * @param ga number: Gestational age in weeks
   * @param examDate needs to be in Date format
   * @returns Estimated Due Date in YYYYMMDD format
   */
  public calculateEdcByGa(ga: number): string {
    // Ga needs to be converted to days and needs to be rounded (normal rounding rules, i.e. round up if greater than 5) to match GE Volusion Machine
    const gaInDays = Math.round(ga * 7);
    return moment().subtract(gaInDays, "days").format("MM/DD/YYYY");
  }

  /**
   * Prints the average ultrasound age by biometrics provided
   * @param headCircumference - cm measurement expected
   * @param femurLength - cm measurement expected
   * @param biparietalDiameter - cm measurement expected
   * @param abdominalCircumference - cm measurement expected
   */
  public async avgUltrasoundAgeInWeeks(
    studyNotes?: StudyNote[]
  ): Promise<number> {
    if (!studyNotes && this._jwtService.getToken())
      studyNotes =
        this._annotationService.studyNotes ||
        (await this._annotationService.getStudyNotes());
    const calcKeys = {
      headCircumference: "hc",
      femurLength: "fl",
      biparietalDiameter: "bpd",
      abdominalCircumference: "ac",
      gestationalSacDiameter: "gsd",
      crownRumpLength: "crl",
      humerusLength: "hl",
    };
    const params = {};

    if (!studyNotes) return -1;

    studyNotes.forEach((n) => {
      if (
        n.noteType === "MEASUREMENT" &&
        calcKeys[n.key] &&
        this._cptService.isIdVisible(n.key)
      ) {
        params[calcKeys[n.key]] = n.evaluationValue;
      }
    });
    const result = await this._calculationService.getCalculation(
      CalculationName.AverageUltrasoundAge,
      Author.NoAuthor,
      null,
      params
    );
    return result?.ga || null;
  }

  /**
   * Prints the estimated fetal weight in oz and grams
   * @param input - object that contains hc, ac, bpd and fl - cm measurement expected
   */
  public async estimatedFetalWeight(
    studyNotes: StudyNote[],
    patientOrder: PatientOrderForm
  ): Promise<SonographerCalculationEfwResult> {
    try {
      if (!patientOrder || !studyNotes) return;
      const lmpDate = patientOrder.ivfConception
        ? null
        : patientOrder.lastMenstrualPeriod;
      const examDate = moment(patientOrder.createdOn);
      const ivfDate = patientOrder.ivfConception ? patientOrder.ivfDate : null;
      const baselineDueDate = this.dateFromYYYYMMDD(
        patientOrder.estimatedDueDate
      );
      let eddDate;

      const method = this.getValidDueDateMethod(patientOrder);

      if (method === "Ultrasound") {
        // TODO: store the estimated due date based on todays ultrasound in DB to prevent the call below
        const { createdOn } = this._patientOrderService.get();
        const gaByUs = await this.avgUltrasoundAgeInWeeks(studyNotes);

        eddDate = this.dateFromYYYYMMDD(
          this.calculateEddByGa(gaByUs, createdOn)
        );
      }
      if (method === "Baseline") {
        eddDate = new Date(baselineDueDate);
      }
      if (method === "Last Menstrual Period") {
        eddDate = null;
      }
      // Check if there is a value for due date methodology, if not then set it to estimated due date by default
      if (!method) {
        eddDate =
          baselineDueDate && patientOrder.hadUltrasound
            ? baselineDueDate
            : null;
      }
      // If no EDD was set from previous default to using Today's US Avg
      if (
        !eddDate &&
        method !== "Last Menstrual Period" &&
        method !== "Conception Date"
      ) {
        const { createdOn } = this._patientOrderService.get();
        const gaByUs = await this.avgUltrasoundAgeInWeeks(studyNotes);

        eddDate = this.dateFromYYYYMMDD(
          this.calculateEddByGa(gaByUs, createdOn)
        );
      }

      const calcKeys = {
        headCircumference: "hc",
        femurLength: "fl",
        biparietalDiameter: "bpd",
        abdominalCircumference: "ac",
      };
      const params = {
        ac: undefined,
        fl: undefined,
        bpd: undefined,
        hc: undefined,
        lmpDate,
        examDate,
        eddDate,
        ivfDate,
      };

      studyNotes.forEach((n) => {
        if (n.noteType === "MEASUREMENT" && calcKeys[n.key]) {
          params[calcKeys[n.key]] = n.evaluationValue;
        }
      });

      if (
        !params.ac ||
        params.ac === unableToCalculateValue ||
        ((!params.fl || params.fl === unableToCalculateValue) &&
          (!params.bpd || params.bpd === unableToCalculateValue))
      ) {
        return null;
      }

      const result = await this._calculationService.getCalculation(
        CalculationName.EstimatedFetalWeight,
        Author.Hadlock,
        1985,
        params
      );

      const lbs = this.poundsAndOunces(result?.estimatedFetalWeight);
      const grams = result?.estimatedFetalWeight?.toFixed(2);

      const efwOutput = result?.estimatedFetalWeight
        ? `${lbs} (${grams} g)`
        : null;
      return {
        efw: efwOutput,
        efwLbs: lbs,
        efwGrams: grams,
        percentile: this.formatPercentile(result?.percentile),
        outOfRange: this.isPercentileOutOfRange(
          result?.percentile?.toFixed(1),
          10,
          90
        ),
      } as SonographerCalculationEfwResult;

      // return `${this.poundsAndOunces(
      //   result?.estimatedFetalWeight
      // )} (${result?.estimatedFetalWeight.toFixed(2)} g)`;
    } catch (error) {
      console.error("Error in estimatedFetalWeight", error);
      return null;
    }
  }

  /**
   * Prints the occipital frontal diameter
   * @param input - object that contains hc and bpd - cm measurement expected
   */
  public async occipitalFrontalDiameter(
    bpd: number | string,
    hc: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number
  ): Promise<number> {
    if (
      !hc ||
      hc === unableToCalculateValue ||
      !bpd ||
      bpd === unableToCalculateValue
    ) {
      throw new Error(
        "Occipital Frontal Diameter values cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.OccipitalFrontalDiameter,
      Author.Salomon,
      2011,
      {
        bpd,
        hc,
        lmpDate,
        examDate,
        eddDate,
      }
    );

    return Math.floor(result?.ofd * 100) / 100;
  }

  /**
   * Prints the cephalic index
   * @param input - object that contains bpd and ofd - cm measurement expected
   */
  public async cephalicIndex(
    bpd: number | string,
    ofd: number | string,
    lmpDate: number,
    examDate: Date,
    ivfDate: number
  ): Promise<{
    ratio: number;
    normalRange: IRange;
    outOfRange: boolean;
  }> {
    if (
      !ofd ||
      ofd === unableToCalculateValue ||
      !bpd ||
      bpd === unableToCalculateValue
    ) {
      throw new Error(
        "Cephalic Index values cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.CephalicIndex,
      Author.Hadlock,
      1981,
      {
        bpd,
        ofd,
        lmpDate,
        examDate,
        ivfDate,
      }
    );

    return {
      ratio: Math.round(result?.ratio * 100) / 100,
      normalRange: result?.normalRange,
      outOfRange:
        result?.ratio < result?.normalRange.min ||
        result?.ratio > result?.normalRange.max,
    };
  }

  /**
   * Calculates the HC / AC ratio
   * @param input - object that contains ac and hc - cm measurement expected
   */
  public async hcAcRatio(
    hc: number | string,
    ac: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number,
    ivfDate: number
  ): Promise<{
    ratio: number;
    range: ILookUpTableRow;
    outOfRange: boolean;
  }> {
    if (
      !hc ||
      hc === unableToCalculateValue ||
      !ac ||
      ac === unableToCalculateValue
    ) {
      throw new Error(
        "HC / AC Ratio values cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.HcAcRatio,
      Author.Campbell,
      1977,
      {
        hc,
        ac,
        examDate,
        lmpDate,
        eddDate,
        ivfDate,
      }
    );
    return {
      ratio: Math.round(result?.ratio * 100) / 100,
      range: result?.range,
      outOfRange: result?.range
        ? result?.ratio < result?.range.min || result?.ratio > result?.range.max
        : true,
    };
  }

  /**
   * Prints the FL/AC ratio
   * @param input - object that contains ac and fl - cm measurement expected
   */
  public async flAcRatio(
    fl: number | string,
    ac: number | string,
    lmpDate: number,
    examDate: Date,
    ivfDate: number
  ): Promise<{
    ratio: number;
    normalRange: IRange;
    outOfRange: boolean;
  }> {
    if (
      !fl ||
      fl === unableToCalculateValue ||
      !ac ||
      ac === unableToCalculateValue
    ) {
      throw new Error(
        "FL / AC Ratio values cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.FlAcRatio,
      Author.Hadlock,
      1983,
      {
        fl,
        ac,
        examDate,
        lmpDate,
        ivfDate,
      }
    );

    return {
      ratio: Math.round(result?.ratio * 100) / 100,
      normalRange: result?.normalRange,
      outOfRange:
        result?.ratio < result?.normalRange.min ||
        result?.ratio > result?.normalRange.max,
    };
  }

  /**
   * Prints the FL/BPD ratio
   * @param input - object that contains bpd and fl - cm measurement expected
   * @param lmpDate - last menstrual period should be in YYYYMMDD format
   * @param examDate - date of the exam in js Date format
   * @param eddDate - estimated due date by Baseline in YYYYMMDD format
   */
  public async flBpdRatio(
    fl: number | string,
    bpd: number | string,
    lmpDate: number,
    examDate: Date,
    eddDate: number,
    ivfDate: number
  ): Promise<{
    ratio: number;
    normalRange: IRange;
    gaInWeeks: number;
    outOfRange: boolean;
  }> {
    if (
      !fl ||
      fl === unableToCalculateValue ||
      !bpd ||
      bpd === unableToCalculateValue
    ) {
      throw new Error(
        "FL / BPD Ratio values cannot be proceeded for calculation."
      );
    }

    const result = await this._calculationService.getCalculation(
      CalculationName.FlBpdRatio,
      Author.Hohler,
      1981,
      {
        fl,
        bpd,
        examDate,
        lmpDate,
        eddDate,
        ivfDate,
      }
    );

    return {
      ratio: Math.round(result?.ratio * 100) / 100,
      normalRange: result?.normalRange,
      gaInWeeks: result?.gaInWeeks,
      outOfRange:
        result?.ratio < result?.normalRange.min ||
        result?.ratio > result?.normalRange.max,
    };
  }

  /**
   * This will handle all cases where a method is set but
   * doesn't have a valid date associated with that method.
   * There is one scenario which it doesn't account for but should never occur
   * Scenario: selectedMethod = Baseline, estimatedDueDate is null,
   *           ivfDate or lastMenstrualPeriod is not null. In this scenario
   *           it should arguably set to one of those methods but again that
   *           should never occur.
   */
  getValidDueDateMethod(
    patientOrder: PatientOrderForm
  ): "Last Menstrual Period" | "Ultrasound" | "Conception Date" | "Baseline" {
    let method = patientOrder.dueDateMethodology;
    if (method === "Conception Date" && !patientOrder.ivfDate) {
      method = "Last Menstrual Period";
    }
    if (
      method === "Last Menstrual Period" &&
      !patientOrder.lastMenstrualPeriod
    ) {
      method = "Baseline";
    }
    if (method === "Baseline" && !patientOrder.estimatedDueDate) {
      method = "Ultrasound";
    }

    return method;
  }

  dateFromYYYYMMDD(date: number | string): Date {
    if (!date) return;
    if (typeof date === "string") date = Number.parseInt(date);
    const year = Math.floor(date / 10000);
    const month = Math.floor((date % 10000) / 100) - 1;
    const day = date % 100;
    return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
  }
}
