const evaluationSelectors = [
  "Normal",
  "Abnormal",
  "Suboptimal",
  "Not Visualized",
];

const sexEvalSelectors = [
  { name: "Male", value: "Male" },
  { name: "Female", value: "Female" },
  { name: "Undetermined", value: "Undetermined Genitalia" },
];

const bppOptions = [
  { key: "0", value: "0" },
  { key: "2", value: "2" },
];

const PlacentaVisualization = [
  { key: "anterior", value: "Anterior" },
  { key: "posterior", value: "Posterior" },
  { key: "rightLateral", value: "Right Lateral" },
  { key: "leftLateral", value: "Left Lateral" },
  { key: "fundal", value: "Fundal" },
  { key: "marginal", value: "Marginal" },
  { key: "lowLying", value: "Low Lying" },
  { key: "complete", value: "Complete" },
];

const sonographerMassSelections = ["Present", "Not Present"];

const sonographerPregnancyTypeSelections = [
  "Singleton",
  "Mono/Mono",
  "Mono/Di",
  "Di/Di",
  "Triplets",
  "4+",
  "Not Visualized",
];

const sonographerNasalBoneSelections = [
  "Present",
  "Absent",
  "Hypoplastic",
  "Suboptimal",
];

const sonographerGestationalSacLocationSelections = [
  { key: "intrauterine", value: "Intrauterine" },
  { key: "irregular", value: "Irregular" },
  { key: "ectopic", value: "Ectopic" },
  { key: "notVisualized", value: "Not Visualized" },
];

const sonographerGestationalSacLocations = [
  "Intrauterine",
  "Irregular",
  "Ectopic",
  "Not Visualized",
];

const sonographerYolkSacEval = ["Normal", "Enlarged", "Echogenic"];

const sonographerYolkSacSelections = [
  "Normal",
  "Enlarged",
  "Echogenic",
  "Not Visualized",
];

const sonographerPlacentaLocationSelections = [
  "Anterior",
  "Posterior",
  "Left Lateral",
  "Right Lateral",
  "Fundal",
];

const sonographerFetusPresentationSelections = [
  "Cephalic",
  "Transverse Right",
  "Transverse Left",
  "Breech",
  "Footing Breech",
  "Variable",
  "No Fetus Present",
];

// TODO: Delete when yup is removed - the only place this appears.
const sonographer1stTriFetusPresentationSelections = [
  "Transverse",
  "Cephalic",
  "Breech",
  "Variable",
];

const sonographerFetalPositionSelections = [
  "Transverse",
  "Cephalic",
  "Breech",
  "Variable",
];

const sonographerFetalAnatomyAppearanceSelections = [
  "Normal",
  "Abnormal",
  "Suboptimal",
];

const sonographerFetalAnatomyViewLimitations = [
  "Multiple Gestation",
  "Constant Fetal Movement",
  "Early Gestational Age",
  "Late Gestational Age",
  "Maternal Body Habitus",
  "Fetal Position",
];

const sonographerDiagnosticsImpressions = [
  "Normal Fetal Anatomy",
  "Incomplete Fetal Anatomy",
  "Follow Up Anatomy",
];

const sonographerDiagnosticsThirdTriImpressions = [
  "Normal Growth",
  "Normal Growth with BPP",
];

const sonographerDiagnosticsImpressionsSelections = [
  "Normal",
  "Redate",
  "Extrauterine Mass",
  "No IUP No Masses",
  "Possible Missed AB",
];

const sonographerImpressionsSelections = [
  "Normal Fetal Anatomy",
  "Incomplete Fetal Anatomy",
  "Follow up Anatomy",
];

const maternalStructuresAppearanceDescriptors = ["Normal", "Abnormal"];

const maternalStructuresExtrauterineMassLocations = [
  "Right Adnexa",
  "Cervix",
  "Abdominal",
  "Ovarian",
  "Other",
];

const sonographerPlacentalGradeSelections = ["0", "1", "2", "3"];

// TODO: Delete when yup is removed - the only place this appears.
const firstTriPlacentaVisualizationSelections = [
  "Anterior",
  "Posterior",
  "Right Lateral",
  "Left Lateral",
  "Fundal",
];

const sonographerPlacentalCordInsertSelections = [
  "Central",
  "Marginal",
  "Velamentous",
  "Not Visualized",
];

const sonographerUmbilicalCordSelections = [
  "3 Vessel Cord",
  "Single Umbilical Artery",
  "Not Visualized",
];

const sonographerAfvObsSelections = [
  { key: "subjectively_low", value: "Subjectively Low" },
  { key: "subjectively_normal", value: "Subjectively Normal" },
  { key: "subjectively_high", value: "Subjectively High" },
  { key: "not_visualized", value: "Not Visualized" },
];

const unableToCalculateValue = "UNABLE TO CALCULATE";

const sonographerCardiacActivitySelections = ["Absent", "Present"];

const sonographerEmbryoSelections = ["Visualized", "Not Visualized"];

const bestOverallAssessmentSelections = [
  "LMP",
  "Today's",
  "Previous",
  "Date of",
  "IUI",
  "Embryo",
];

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prettier/prettier */
const sonographerImpressionsNotes = {
  "Normal":
    "Singleton living fetus with a gestational age {gestationAge} based on the last menstrual period.  Current growth parameters are consistent with the last menstrual period. Today’s ultrasound corresponds to {crownRumpGestationAge}.  Normal early fetal evaluation.  Normal appearance of the adnexa.",
  "Normal Growth": 
    "Singleton living fetus at gestational age of {gestationAge}.  Normal follow-up fetal anatomic survey.  Current fetal growth estimates {estimatedFetalWeight} and measures at the {biparietalPercentile} percentile for gestational age.  Normal amniotic fluid. Normal transabdominal cervical length without evidence of shortening or funneling. {fetusPresentation} presentation.",
  "Normal Growth with BPP": 
    "Singleton living fetus at gestational age of {gestationAge}. Current fetal growth estimates {estimatedFetalWeight} and measures at the {biparietalPercentile} percentile for gestational age.  Normal amniotic fluid. Normal transabdominal cervical length without evidence of shortening or funneling. {fetusPresentation} presentation. Biophysical profile score of {bioProfileTotal} out of 8.",
  "Redate":
    "Singleton living fetus with a gestational age {gestationAge} based on the last menstrual period.  Current growth parameters are NOT consistent with the last menstrual period. Today’s ultrasound corresponds to {redateAge}. CRL measurements suggest that the EDD be changed to {crownRumpGestationAge}.   Normal early fetal evaluation.  Normal appearance of the adnexa.",
  "Extrauterine Mass":
    "No evidence of an intrauterine pregnancy visualized. A complex mass measuring {extrauterineLength} cm length by {extrauterineWidth} cm width by {extrauterineHeight} cm height is seen in the {extrauterineLocation}. This is suspicious of an ectopic pregnancy.",
  "No IUP No Masses":
    "No evidence of an intrauterine pregnancy.  Sonographically normal adnexa.  No evidence to suggest ectopic pregnancy; however ectopic pregnancy cannot be excluded.",
  "Possible Missed AB":
    "Irregular appearing gestational sac seen within the uterine cavity measuring {gestationSacDiameterAge}-weeks in size.  A fetus with no cardiac activity is visualized. Fetus CRL corresponds to {crownRumpGestationAge}. Normal appearance of adnexa. Sonographically consistent with a missed abortion.",
  "Normal Fetal Anatomy":
    "Singleton living fetus at gestational age of {gestationAge}.  Normal initial fetal anatomic survey.  Current fetal growth estimates {estimatedFetalWeight} and measures at the {biparietalPercentile} percentile for gestational age. Normal amniotic fluid. Normal transabdominal cervical length without evidence of shortening or funneling. Normal appearance of the adnexa.",
  "Incomplete Fetal Anatomy":
    "Singleton living fetus at gestational age of {gestationAge}. Current fetal growth estimates {estimatedFetalWeight} and measures at the {biparietalPercentile} percentile for gestational age.  Normal amniotic fluid. Normal transabdominal cervical length without evidence of shortening or funneling.  Normal appearance of the adnexa.",
  "Follow Up Anatomy":
    "Singleton living fetus at gestational age of {gestationAge}. Current fetal growth estimates {estimatedFetalWeight} and measures at the {biparietalPercentile} percentile for gestational age.  Normal amniotic fluid. Normal transabdominal cervical length without evidence of shortening or funneling.  Normal appearance of the adnexa.",
};
/* eslint-enable @typescript-eslint/naming-convention */
/* eslint-enable prettier/prettier */
export {
  evaluationSelectors,
  sonographerYolkSacSelections,
  sonographerYolkSacEval,
  sonographerPregnancyTypeSelections,
  sonographerNasalBoneSelections,
  sonographerGestationalSacLocations,
  sonographerGestationalSacLocationSelections,
  sonographerFetalPositionSelections,
  sonographerPlacentaLocationSelections,
  sonographerFetalAnatomyAppearanceSelections,
  sonographerFetalAnatomyViewLimitations,
  sonographerDiagnosticsImpressions,
  maternalStructuresAppearanceDescriptors,
  maternalStructuresExtrauterineMassLocations,
  sonographerDiagnosticsImpressionsSelections,
  PlacentaVisualization,
  sonographerPlacentalGradeSelections,
  sonographerDiagnosticsThirdTriImpressions,
  bestOverallAssessmentSelections,
  sonographerImpressionsNotes,
  sonographerFetusPresentationSelections,
  sexEvalSelectors,
  bppOptions,
  sonographerImpressionsSelections,
  sonographerCardiacActivitySelections,
  sonographerEmbryoSelections,
  sonographerPlacentalCordInsertSelections,
  sonographerUmbilicalCordSelections,
  sonographerAfvObsSelections,
  firstTriPlacentaVisualizationSelections,
  sonographer1stTriFetusPresentationSelections,
  sonographerMassSelections,
  unableToCalculateValue,
};
