import { Directive, HostListener, Input } from "@angular/core";
import { GAService } from "src/app/core/services/gaservice";

@Directive({
  selector: "[gaEvent]",
})
export class GAnalyticsDirective {
  constructor(private _gaService: GAService) {}

  @Input() gaEvent = "";
  @Input() gaCategory = "";
  @Input() gaLabel = "";

  @HostListener("click") onMouseClick(): void {
    if (this.gaEvent) {
      this._gaService.trackEvent(this.gaEvent, this.gaCategory, this.gaLabel);
    }
  }
}
