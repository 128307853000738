/* eslint-disable @typescript-eslint/naming-convention */
export interface TemplateAnnotation {
  templateName: string;
  templateId: string;
  templateCat: string;
  annotations: any;
  measurements?: AnnotationMeasurement[];
  displayUnit?: string;
}
export interface AnnotationMeasurement {
  unit: string;
  value: number;
}
export interface StudyResponse {
  study_meta: StudyMeta;
  frames: FrameRecord[];
}

export interface StudyBySweepResponse extends StudyResponse {
  seriesId: string;
}

export interface FrameRecord extends StudyRecord {
  propertyId: string;
  frameId: number;
  seriesId: string;
  frameUrl: string;
  templateAnnotations?: TemplateAnnotation[];
}

export interface StudyRecord {
  institutionId: string;
  studyId: string;
  templateId: string;
  propertyId: string;
}

export interface StudyMeta extends StudyRecord {
  patientOrderId: string;
  frameCount: number;
  meta: DicomMeta;
}

export interface DicomMeta {
  seriesInfo: {
    [key: number]: {
      frameCount: number;
      sweepTag: string;
    };
  };
  Columns?: number;
  PhysicalDeltaX?: number;
  PhysicalDeltaY?: number;
  PhysicalUnitsXDirection?: number;
  PhysicalUnitsYDirection?: number;
  RegionLocationMaxX1?: number;
  RegionLocationMaxY1?: number;
  RegionLocationMinX0?: number;
  RegionLocationMinY0?: number;
  Rows?: number;
}

export interface StudyAnnotationsResponse {
  institutionId: string;
  studyId: string;
  templates: (
    | StudyTemplateMeasurementAnnotationResponse
    | StudyTemplateObservationAnnotationResponse
  )[];
}
export interface StudyTemplateMeasurementAnnotationResponse {
  templateId: string;
  displayUnit: string;
  templateCat: string;
  annotations: StudyAnnotationMeasurement[];
}
export interface StudyTemplateObservationAnnotationResponse {
  seriesIdsFrameIds: SeriesIdFrameIdPair[];
  templateId: string;
  templateCat: string;
  observation: string;
  evaluation: string;
}
export interface SeriesIdFrameIdPair {
  seriesId: string;
  frameId: string;
}

export interface StudyAnnotationMeasurement extends AnnotationMeasurement {
  frameId: string;
  seriesId: string;
}
/* eslint-enable @typescript-eslint/naming-convention */
