import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { UserService } from "src/app/core/services/user.service";
import { GAService } from "src/app/core/services/gaservice";
import { AuthService } from "../../../core/services/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: [
    "../../login/login/login.component.scss",
    "./forgot-password.component.scss",
  ],
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild("passwordForm") passwordForm: NgForm;
  buttonDisabled = false;
  buttonState = "";
  invalidEmail = false;
  messageSent = false;
  accountLocked = false;
  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _gaService: GAService,
    // private _notifications: NotificationsService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._gaService.pageView("Forgot Password");
  }

  onSubmit(): void {
    if (!this.passwordForm.valid || this.buttonDisabled) {
      return;
    }
    this.buttonDisabled = true;
    this.buttonState = "show-spinner";
    /*
    this.authService.sendPasswordEmail(this.passwordForm.value.email).subscribe((answer) => {
    }, (error) => {
      this.notifications.create('Error', error.message, NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
      this.buttonDisabled = false;
      this.buttonState = '';
    }, () => {
      this.notifications.create('Done', 'Password reset email is sent, you will be redirected to Reset Password page!', NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: true });
      this.buttonDisabled = false;
      this.buttonState = '';
      setTimeout(() => {
        this.router.navigate(['user/reset-password']);
      }, 6000);
    }); */
  }

  public forgotPassword(): void {
    try {
      this._gaService.trackEvent(
        "forgot_password_submit",
        "forgot_password",
        "Submit Forgot Password"
      );
      this.buttonDisabled = true;
      this.buttonState = "show-spinner";

      this._authService
        .sendPasswordResetEmail(this.passwordForm.value.email)
        .then((res) => {
          if (res) {
            this.messageSent = true;
          }
          return true;
        })
        .catch((error) => {
          const errorMessage = JSON.parse(error.error).message;
          if (errorMessage === "Invalid email") {
            this.invalidEmail = true;
          }
          if (errorMessage === "Account locked") {
            this.accountLocked = true;
          }
          this.buttonState = "";
          this.buttonDisabled = false;
          this.buttonState = "";
        });
    } catch (error) {
      console.error(error);
    }
  }

  public validate(): void {
    this.invalidEmail = false;
  }
}
