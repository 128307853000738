import { Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AlertService } from "src/app/global/services/alert.service";
import { AuthService } from "src/app/core/services/auth.service";
import { IdleService } from "src/app/core/services/idle.service";
import { JWTTokenService, ParsedJWT } from "src/app/core/services/jwt.service";
import { SpinnerService } from "src/app/global/services/spinner.service";
import { ErrorMessage } from "src/app/global/enums/error-message";
import { UserService } from "../../services/user.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

declare let pendo: any;

@Component({
  selector: "logged-in-layout",
  templateUrl: "./logged-in-layout.component.html",
})
export class LoggedInLayoutComponent implements OnInit, OnDestroy {
  private user: ParsedJWT;
  private role: string;
  isAdmin = false;

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    public router: Router,
    private _idleService: IdleService,
    private _authService: AuthService,
    private _jwtService: JWTTokenService,
    private _userService: UserService,
    private _alertService: AlertService,
    private _spinner: SpinnerService
  ) {
    this._idleService.idle$.subscribe(() => {
      this._authService.signOut();
      this.router.navigate(["/login"]);
      const options = {
        autoClose: false,
        keepAfterRouteChange: false,
      };
      const msg = `${ErrorMessage.no_activity}`;
      this._alertService.error(msg, options);
      // console.error(msg);
      this._spinner.hide();
    });
    this._idleService.wake$.subscribe(() => {});
  }

  async ngOnInit(): Promise<void> {
    this.user = this._jwtService.getParsedToken();
    this.role = this.user.role.toLowerCase();
    this.getUserInfo(this.user.userId, this.user.institutionId);

    this.isAdmin = this.role === "admin";
  }

  getUserInfo(userId, institutionId): void {
    this._userService
      .getUserInfo$(userId, institutionId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((res) => {
        const email = res.email;
        const institutionName = res.institutionName;
        const facilities = [];

        res.clinics.forEach((element) => {
          facilities.push(element.name);
        });

        const info = {
          visitor: {
            id: userId,
            email: email,
            role: this.role,
            facilities: facilities,
          },
          account: {
            id: institutionId,
            tenant: this.user.tenant,
            institutionName: institutionName,
          },
        };
        pendo.initialize(info);
      });
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
