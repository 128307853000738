import * as yup from "yup";
import {
  evaluationSelectors,
  sonographerMassSelections,
} from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographerEpV2MaternalStructuresSchema = yup
  .object()
  .shape({
    uterusObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Uterus",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors)
      .required("Uterus evaluation is required"),
    uterusObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    uterusLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Uterus Length(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .required("Uterus length is required")
      .test(
        "utl-test",
        "Uterus length should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    uterusHeight: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Uterus Height(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .required("Uterus height is required")
      .test(
        "utl-test",
        "Uterus height should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    uterusWidth: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Uterus Width(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .required("Uterus width is required")
      .test("utl-test", "Uterus width should be positive integer", (v: any) => {
        return Number(v) >= 0;
      }),
    extrauterineMassObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Extrauterine Mass",
        options: sonographerMassSelections.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(sonographerMassSelections)
      .required("Extrauterine Mass evaluation is required"),
    extrauterineMassObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    extrauterineMassLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Extrauterine Mass Length(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Extrauterine Mass length is required")
      .test(
        "eml-test",
        "Extrauterine Mass length should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    extrauterineMassHeight: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Extrauterine Mass Height(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Extrauterine Mass height is required")
      .test(
        "eml-test",
        "Extrauterine Mass height should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    extrauterineMassWidth: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Extrauterine Mass Width(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Extrauterine Mass width is required")
      .test(
        "eml-test",
        "Extrauterine Mass width should be positive integer",
        (v: any) => {
          return v >= 0;
        }
      ),
    rightOvaryObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Right Ovary",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors)
      .required("Right Ovary Evaluation is required"),
    rightOvaryObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    rightOvaryLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Right Ovary Length(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Right ovary length is required")
      .test(
        "rov-test",
        "Right ovary length should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    rightOvaryHeight: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Right Ovary Height(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Right ovary height is required")
      .test(
        "roh-test",
        "Right ovary height should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    rightOvaryWidth: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Right Ovary Width(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Right ovary width is required")
      .test(
        "row-test",
        "Right ovary width should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    rightAdnexalMassObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Right Adnexal Mass",
        options: sonographerMassSelections.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(sonographerMassSelections)
      .required("Right adnexal mass evaluation is required"),
    rightAdnexalMassObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    rightAdnexalMassLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Right Adnexal Mass Length(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Right adnexal mass length is required")
      .test(
        "rov-test",
        "Right adnexal mass length should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    rightAdnexalMassHeight: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Right Adnexal Mass Height(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Right adnexal mass height is required")
      .test(
        "roh-test",
        "Right adnexal mass height should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    rightAdnexalMassWidth: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Right Adnexal Mass Width(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Right adnexal mass width is required")
      .test(
        "row-test",
        "Right adnexal mass width should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    leftOvaryObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Left Ovary",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors)
      .required("Left Ovary evaluation is required"),
    leftOvaryObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    leftOvaryLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Left Ovary Length(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Left ovary length is required")
      .test(
        "lov-test",
        "Left ovary length should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    leftOvaryHeight: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Left Ovary Height(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Left ovary height is required")
      .test(
        "loh-test",
        "Left ovary height should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    leftOvaryWidth: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Left Ovary Width(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Left ovary width is required")
      .test(
        "low-test",
        "Left ovary width should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    leftAdnexalMassObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Left Adnexal Mass",
        options: sonographerMassSelections.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(sonographerMassSelections)
      .required("Left adnexal mass evaluation is required"),
    leftAdnexalMassObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    leftAdnexalMassLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Left Adnexal Mass Length(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Left adnexal mass length is required")
      .test(
        "rov-test",
        "Left adnexal mass length should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    leftAdnexalMassHeight: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Left Adnexal Mass Height(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Left adnexal mass height is required")
      .test(
        "roh-test",
        "Left adnexal mass height should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    leftAdnexalMassWidth: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Left Adnexal Mass Width(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Left adnexal mass width is required")
      .test(
        "row-test",
        "Left adnexal mass width should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
  })
  .required();

type SonographerEpV2MaternalStructuresType = yup.InferType<
  typeof sonographerEpV2MaternalStructuresSchema
>;

export {
  sonographerEpV2MaternalStructuresSchema,
  SonographerEpV2MaternalStructuresType,
};
