import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { PatientService } from "src/app/global/services/patient.service";
import { Patient } from "src/app/models/patient";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { JWTTokenService } from "src/app/core/services/jwt.service";
import { PatientOrderService } from "../../services/patient-order.service";
import { newPatientOrderForm, PatientOrderForm } from "../../../models";
import { UserService } from "src/app/core/services/user.service";
import { NgbDropdown } from "@ng-bootstrap/ng-bootstrap";
import { ViewportScroller } from "@angular/common";

@Component({
  selector: "patient-notes",
  templateUrl: "./patient-notes.component.html",
  styleUrls: ["./patient-notes.component.scss"],
})
export class PatientNotesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() patient: Patient;
  @ViewChild("patientNotesDropdown") patientNotesDropdown: ElementRef;
  @ViewChild(NgbDropdown, { static: true })
  public dropdown: NgbDropdown;

  public changeTimeout: ReturnType<typeof setTimeout>;
  public currentUserRole: string;
  public patientOrder: PatientOrderForm = newPatientOrderForm();
  public hasSonographerAssigned = false;
  public isOpen = true;
  public isModal = false;
  public canClaim = false;
  private currentUserId: string;

  private componentDestroyed$: Subject<boolean> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private _jwtService: JWTTokenService,
    private _patientService: PatientService,
    private _userService: UserService,
    private _patientOrderService: PatientOrderService,
    private scroller: ViewportScroller
  ) {
    this.route.data.subscribe((res) => {
      this.patient = res.studyInfo?.patient;
      this.patientOrder = res.studyInfo?.patientOrder;
      this.patient.notes = this.patient.notes || ""; // Set to empty string if undefined
      if (this.patientOrder) {
        this.setInitialButtonState();
      }
    });
    const token = this._jwtService.getParsedToken();
    this.currentUserRole = token.role;
  }

  ngOnInit(): void {
    // when the intake survey gets updated names we need the current patient name
    this._patientService
      .getCurrentPatient()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((currentPatient) => {
        this.patient = currentPatient;
        this.patient.notes = this.patient.notes || ""; // Set to empty string if undefined
      });

    this._patientOrderService
      .getClaimantObs()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((userId) => {
        if (userId === "") {
          return;
        }
        this.patientOrder.sonographerUserId = userId ? userId : null;
        this.patientOrder.assignedSonographerFullName =
          userId === this.currentUserId
            ? `${this._userService.currentUser.firstName} ${this._userService.currentUser.lastName}`
            : this.patientOrder.assignedSonographerFullName;
        this.hasSonographerAssigned = !!userId;
      });

    this.route.queryParams.subscribe((params) => {
      this.isModal = params["isModal"];
    });

    this.canClaim =
      (this.currentUserRole === "SONOGRAPHER" ||
        this.currentUserRole === "DOCTOR") &&
      this.patientOrder?.status !== "ORDER_COMPLETED";
  }

  ngAfterViewInit(): void {
    if (this.patient.notes !== "" && !this.isModal) {
      this.dropdown.open();
      this.scroller.scrollToPosition([0, 0]);
      this.setFocusOnNotes();
    }
  }

  setInitialButtonState(): void {
    const token = this._jwtService.getParsedToken();
    this.currentUserRole = token.role;
    this.currentUserId = token.userId.toString();

    if (
      this.currentUserRole !== "SONOGRAPHER" ||
      this.patientOrder?.status === "ORDER_COMPLETED"
    ) {
      this.hasSonographerAssigned = true;
    } else if (
      this.currentUserRole === "SONOGRAPHER" &&
      this.patientOrder.status !== "ORDER_COMPLETED" &&
      this.currentUserId === this.patientOrder?.sonographerUserId
    ) {
      this.hasSonographerAssigned = true;
    } else {
      this.hasSonographerAssigned = false;
    }
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
    if (this.isOpen) {
      this.setFocusOnNotes();
    }
  }

  updateNotes(notes): void {
    if (this.patient.notes !== notes) {
      this.patient.notes = notes;
      this._patientService.storePatient(this.patient);
    }
  }

  updateNotesAfterTimeout(notes): void {
    clearTimeout(this.changeTimeout);
    this.changeTimeout = setTimeout(() => {
      this.updateNotes(notes);
    }, 5000);
  }

  setFocusOnNotes(): void {
    setTimeout(() => {
      // Timeout allows for element to appear in DOM before setting focus
      this.patientNotesDropdown?.nativeElement.focus();
    }, 0);
  }

  claimOrder(claim: boolean): void {
    const currentUserId = claim ? this.currentUserId : null;
    this.hasSonographerAssigned = claim;
    this._patientOrderService.setClaimant(currentUserId);
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
