import { PipeTransform, Pipe } from "@angular/core";
// import { DataNotEnteredPipe } from "./data-not-entered.pipe";

@Pipe({ name: "decimal" })
export class DecimalPipe implements PipeTransform {
  transform(val: any, numOfPlaces: number): string {
    if (typeof val !== "number") return val;
    return val.toFixed(numOfPlaces);
  }
}
