import * as yup from "yup";

const patientInfoSchema = yup.object().shape({
  patientFirstName: yup.string().required("Patient first name is required"),
  patientLastName: yup.string().required("Patient last name is required"),
  patientDob: yup.string().required("Patient date of birth is required"),
  patientId: yup.string(),
  patientInsitutionId: yup.string(), // the id used by the instituation to id the patient
});

const generalInfoSchema = yup
  .object()
  .shape({
    patientInsitutionId: yup.string(), // the id used by the instituation to id the patient
    visitPurpose: yup.string().required("Visit purpose is required"),
    visitType: yup
      .string()
      .oneOf(["SCREENING", "FOLLOW_UP"])
      .required("Visit type is required")
      .default("SCREENING"),
    bpp: yup
      .boolean()
      .required("Please indicate if a Biophysical Profile should be conducted")
      .default(false),
    genderRevealDesired: yup.boolean().default(null),
  })
  .required();

const vitalsInfoSchema = yup
  .object()
  .shape({
    bmi: yup.number().typeError("BMI is required").required("BMI is required"),
    height: yup
      .number()
      .typeError("Height is required")
      .test(
        "height-test",
        "Height must be a greater than zero (inches)",
        (v) => {
          return typeof v === "number" && v > 0;
        }
      )
      .required("Height is required"),
    weight: yup
      .number()
      .typeError("Weight is required")
      .test(
        "weight-test",
        "Weight must be a greater than zero (pounds)",
        (v) => {
          return typeof v === "number" && v > 0;
        }
      )
      .required("Weight is required"),
    bpDiastolic: yup
      .number()
      .typeError("Diastolic blood pressure is required")
      .test(
        "bpDiastolic-test",
        "Diastolic blood pressure must be a greater than zero",
        (v) => {
          return typeof v === "number" && v > 0;
        }
      )
      .required("Diastolic blood pressure is required"),
    bpSystolic: yup
      .number()
      .typeError("Systolic blood pressure is required")
      .test(
        "bpSystolic-test",
        "Systolic blood pressure must be a greater than zero",
        (v) => {
          return typeof v === "number" && v > 0;
        }
      )
      .required("Systolic blood pressure is required"),
  })
  .required();

const historyInfoSchema = yup
  .object()
  .shape({
    createdDate: yup.string(),
    pregnancyStage: yup.string(),
    estimatedDueDate: yup.string().required("Estimated due date is required"),
    prePregnancyWeight: yup
      .number()
      .typeError("Pre-pregnancy weight is required")
      .test(
        "prePregnancyWeight-test",
        "Pre-pregnancy weight must be a greater than zero",
        (v) => {
          return typeof v === "number" && v > 0;
        }
      )
      .required("Pre-pregnancy weight is required"),
    prePregnancyBmi: yup.number(),
    dueDateMethodology: yup
      .string()
      .typeError("Due Date Methodology is required")
      .oneOf(["Last Menstrual Period", "Ultrasound", "IVF Conception Date"])
      .required("Due Date Methodology is required"),
    lastMenstrualPeriod: yup
      .string()
      .required("Enter the patient's last menstrual period date"),
    numCSections: yup
      .number()
      .typeError("This data is is required")
      .test(
        "numCSections-test",
        "This value must be a greater than zero",
        (v) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Number of C-Sections is required"),
    numGreaterThan37Deliveries: yup
      .number()
      .typeError("This data is is required")
      .test(
        "numGreaterThan37Deliveries-test",
        "This value must be a greater than zero",
        (v) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Number of deliveries greater than 37 weeks is required"),
    numLessThan37Deliveries: yup
      .number()
      .typeError("This data is is required")
      .test(
        "numLessThan37Deliveries-test",
        "This value must be a greater than zero",
        (v) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Number of deliveries less than 37 weeks is required"),
    numLiveBirths: yup
      .number()
      .typeError("This data is is required")
      .test(
        "numLiveBirths-test",
        "This value must be a greater than zero",
        (v) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Number live births is required"),
    numMiscarriagesAbortions: yup
      .number()
      .typeError("This data is is required")
      .test(
        "numMiscarriagesAbortions-test",
        "This value must be a greater than zero",
        (v) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Number of miscarriages/abortions is required"),
    ultrasoundMethod: yup
      .string()
      .oneOf(["TRANSVAGINAL", "TRANSABDOMINAL", "TRANSABDOMINAL_TRANSVAGINAL"]),
  })
  .required();

const mdInfoSchema = yup
  .object()
  .shape({
    referringPhysician: yup
      .string()
      .required("Referring physician is required"),
  })
  .required();

const locationInfoSchema = yup
  .object()
  .shape({
    clinicId: yup.string().required("Clinic Id is required"),
    ultrasoundMachineId: yup
      .string()
      .required("Ultrasound machine id is required"),
  })
  .required();

type PatientInfoType = yup.InferType<typeof patientInfoSchema>;
type GeneralInfoType = yup.InferType<typeof generalInfoSchema>;
type VitalsInfoType = yup.InferType<typeof vitalsInfoSchema>;
type HistoryInfoType = yup.InferType<typeof historyInfoSchema>;
type MdInfoType = yup.InferType<typeof mdInfoSchema>;
type LocationInfoType = yup.InferType<typeof locationInfoSchema>;

export {
  PatientInfoType,
  patientInfoSchema as PatientInfoSchema,
  GeneralInfoType,
  generalInfoSchema as GeneralInfoSchema,
  VitalsInfoType,
  vitalsInfoSchema as VitalsInfoSchema,
  HistoryInfoType,
  historyInfoSchema as HistoryInfoSchema,
  MdInfoType,
  mdInfoSchema as MdInfoSchema,
  LocationInfoType,
  locationInfoSchema as LocationInfoSchema,
};
