import { OnInit, Component, Input, OnDestroy } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { JWTTokenService } from "src/app/core/services/jwt.service";
import { AnnotationService } from "src/app/global/services/annotation.service";
import { PatientOrderService } from "src/app/global/services/patient-order.service";
import { OptionSelections } from "src/app/modules/patient-order/evaluation/models/form-models/option-array-model";

@Component({
  selector: "dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  providers: [],
})
export class DropdownComponent implements OnInit, OnDestroy {
  @Input() fieldId: string;
  @Input() selectOptions: OptionSelections[];

  private componentDestroyed$: Subject<boolean> = new Subject();

  form: FormGroup;
  model;
  isDisabled = false;

  constructor(
    private _annotationService: AnnotationService,
    private _patientOrderService: PatientOrderService,
    private _jwt: JWTTokenService
  ) {
    this.model = {
      evaluationValue: null,
    };
  }

  ngOnInit(): void {
    this._annotationService
      .getStudyNotesObservable()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((sn) => {
        const note = sn.find(
          (n) => n.noteType === "COMMENT" && n.key === this.fieldId
        );
        this.model.evaluationValue = note.evaluationValue;
      });
    this.isDisabled =
      this._patientOrderService.get()?.status === "ORDER_COMPLETED";
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }

  update(value: string): void {
    const userId = this._jwt.getParsedToken().userId || "failed-to-get-user-id";

    const note = {
      institutionId: this._patientOrderService.get().institutionId,
      studyId: this._patientOrderService.get().id,
      userId,
      key: this.fieldId,
      text: value,
    };
    this._annotationService.upsertComment(note);
  }
}
