import * as yup from "yup";

const sonographer3rdTriBioProfileSchema = yup
  .object()
  .shape({
    conductBioProfile: yup.boolean().default(true),
    grossBodyMvmt: yup
      .number()
      .typeError("This value must be a positive integer")
      .oneOf([0, 2])
      .test("gbm-test", "This value must 0 or 2", (v) => {
        return typeof v === "number" && (v === 0 || v === 2);
      })
      .required("Gross Body Movement is required"),
    fetalTone: yup
      .number()
      .typeError("This value must be a positive integer")
      .oneOf([0, 2])
      .test("ft-test", "This value must 0 or 2", (v) => {
        return typeof v === "number" && (v === 0 || v === 2);
      })
      .required("Fetal Tone is required"),
    fetalBreathing: yup
      .number()
      .typeError("This value must be a positive integer")
      .oneOf([0, 2])
      .test("fb-test", "This value must 0 or 2", (v) => {
        return typeof v === "number" && (v === 0 || v === 2);
      })
      .required("Fetal Breathing is required"),
    amnioticFluidVol: yup
      .number()
      .typeError("This value must be a positive integer")
      .oneOf([0, 2])
      .test("afv-test", "This value must 0 or 2", (v) => {
        return typeof v === "number" && (v === 0 || v === 2);
      })
      .required("Amniotic Fluid Volume is required"),
  })
  .required();

type Sonographer3rdTriBioProfileType = yup.InferType<
  typeof sonographer3rdTriBioProfileSchema
>;

export {
  sonographer3rdTriBioProfileSchema as Sonographer3rdTriBioProfileSchema,
  Sonographer3rdTriBioProfileType,
};
