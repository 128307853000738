import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

export interface UserProfile {
  institutionId: string;
  institutionName?: string;
  endorsements: string;
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  role: UserTypes;
  phone: string;
  clinics: any;
  preferences: UserPreferences;
  phonePreferred: boolean;
  emailPreferred: boolean;
}

export interface NewUserResponse {
  email: string;
  role: UserTypes;
  clinicNames: string[];
  institutionId: string;
  userId: string;
  clinics: { id: string; name: string }[];
}

export interface UserRegister {
  lastName: string;
  firstName: string;
  endorsements: string[];
  phone: string;
  password: string;
  security1: { id: string; value: string };
  security2: { id: string; value: string };
  security3: { id: string; value: string };
  userId: string; // Use both of these on the backend to enhance security
  authId: string; // Use both of these on the backend to enhance security
  phonePreferred: boolean;
  emailPreferred: boolean;
}
export interface MfaProfile {
  phonePreferred: boolean;
  emailPreferred: boolean;
  mfaEnabled: boolean;
  failedMfaCodeAttempts: number;
  mfaCode: string;
  ipAddress: string;
  mfaCodeExpiration: Date;
}

export enum USER_TYPES {
  ADMIN = "ADMIN",
  PATIENT = "PATIENT",
  DRIVER = "DRIVER",
  SONOGRAPHER = "SONOGRAPHER",
  DOCTOR = "DOCTOR",
}

export interface RegisterForm {
  userId: string;
  lastName: string;
  firstName: string;
  phone: string;
  password: string;
  questions: { securityQuestionId: string; response: string }[];
  phonePreferred: boolean;
  emailPreferred: boolean;
}

export type UserTypes = keyof typeof USER_TYPES;

export const ACCESS_TYPES = ["none", "limited", "full"] as const;

export type AccessTypes = (typeof ACCESS_TYPES)[number];

export function isOfTypeAccess(keyInput: string): keyInput is AccessTypes {
  return ["none", "limited", "full"].includes(keyInput);
}

export function isOfTypeUser(keyInput: string): keyInput is UserTypes {
  return ["ADMIN", "PATIENT", "DRIVER", "SONOGRAPHER", "DOCTOR"].includes(
    keyInput
  );
}

export interface UserPreferences {
  statuses: string[];
  clinics: string[]; // clinic IDs
  doctors: string[]; // user IDs of doctors
  order: "oldest" | "newest";
  dateStart: NgbDate;
  dateEnd: NgbDate;
}
