import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "dataNotEntered" })
export class DataNotEnteredPipe implements PipeTransform {
  public static notEnteredMarker = "--";
  public static noAnnotationEntered = "Not visualized";
  public static patientInfoNotEnteredMarker = "Not provided";
  public static noCalculationValue = "No values to calculate";

  transform(val: any, pageType?: string): any {
    if (
      val === undefined ||
      val === "" ||
      val === "null" ||
      val === null ||
      val.length === 0
    ) {
      if (!pageType) {
        return DataNotEnteredPipe.notEnteredMarker;
      }
      if (pageType === "reportPatient") {
        return DataNotEnteredPipe.patientInfoNotEnteredMarker;
      }
      if (pageType === "reportCalc") {
        return DataNotEnteredPipe.noCalculationValue;
      }
      if (pageType === "reportMsmt") {
        return DataNotEnteredPipe.noAnnotationEntered;
      }
    }
    return val;
  }
}
