import * as yup from "yup";
import {
  evaluationSelectors,
  sexEvalSelectors,
} from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographer3rdTriV2GenitaliaSchema = yup
  .object()
  .shape({
    sexEval: yup
      .string()
      .meta({
        control: "radio-list",
        label: "Genitalia",
        options: sexEvalSelectors.map((e) => {
          return {
            key: e.name,
            value: e.value,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(sexEvalSelectors),
    genitaliaObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    genitaliaObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
  })
  .required();

type Sonographer3rdTriV2GenitaliaType = yup.InferType<
  typeof sonographer3rdTriV2GenitaliaSchema
>;

export { sonographer3rdTriV2GenitaliaSchema, Sonographer3rdTriV2GenitaliaType };
