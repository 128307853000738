import * as yup from "yup";
import { sonographerDiagnosticsImpressionsSelections } from "../../global/constants/sonographer-constants";

const sonographerEpImpressionsSchema = yup
  .object()
  .shape({
    impression: yup
      .string()
      .oneOf(sonographerDiagnosticsImpressionsSelections)
      .required("Impression is required")
      .default(sonographerDiagnosticsImpressionsSelections[0]),
    impressionNotes: yup.string(),
    additionalNotes: yup.string(),
    ultraSoundDateWeeks: yup
      .number()
      .typeError("Value must be an integer")
      .nullable()
      .when("impression", {
        is: (value: string) => {
          return value === "Redate";
        },
        then: yup
          .number()
          .typeError("Value must be an integer")
          .test(
            "usw-test",
            "This value must be a positive integer or null",
            (v) => {
              return typeof v === "number" && v >= 0;
            }
          )
          .required("Ultra sound date (weeks) is required"),
        otherwise: yup.number().nullable(),
      }),
    ultraSoundDateDays: yup
      .number()
      .typeError("Value must be an integer")
      .nullable()
      .when("impression", {
        is: (value: string) => {
          return value === "Redate";
        },
        then: yup
          .number()
          .typeError("Value must be an integer")
          .test(
            "usd-test",
            "This value must be a positive integer or null",
            (v) => {
              return typeof v === "number" && v >= 0;
            }
          )
          .required("Ultra sound date (days) is required"),
        otherwise: yup.number().nullable(),
      }),
  })
  .required();

type SonographerEpImpressionsType = yup.InferType<
  typeof sonographerEpImpressionsSchema
>;

export {
  sonographerEpImpressionsSchema as SonographerEpImpressionsSchema,
  SonographerEpImpressionsType,
};
