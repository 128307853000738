import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "src/app/core/services/user.service";
import {
  AccessTypes,
  isOfTypeAccess,
  isOfTypeUser,
  NewUserResponse,
  RegisterForm,
  UserRegister,
  UserTypes,
} from "src/app/models";
import { GAService } from "src/app/core/services/gaservice";
import { AuthService } from "src/app/core/services/auth.service";

@Component({
  selector: "register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  @ViewChild("register") register: NgForm;
  submitDisabled = false;
  buttonState = "";
  locationOptions: { key: string; value: string; isSelected: boolean }[] = [];
  isAllLocationsSelected = false;
  roleSelected: UserTypes;
  accessSelected: AccessTypes | null;
  newUser: NewUserResponse;
  userToRegister: UserRegister;
  userCreated = false;
  securityQuestions = [];
  invalidPassword = false;
  selectedSecurityQuestions = [];
  userId: string;
  phonePreferred = false;
  emailPreferred = false;

  isAtLeastOneChecked(): boolean {
    return this.phonePreferred || this.emailPreferred;
  }

  maskEmail(email: string): string {
    const [actualemail, domain] = email.split("@");
    const maskedLocalPart =
      actualemail.slice(0, 2) +
      "x".repeat(actualemail.length - 3) +
      actualemail.slice(Math.max(0, actualemail.length - 1));
    return `${maskedLocalPart}@${domain}`;
  }

  maskPhone(phone: string): string {
    const [firstsix, lastfour] = phone.split("-");
    console.log(firstsix);
    return `xxx-xxx-${lastfour}`;
    console.log(firstsix);
  }

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _gaService: GAService
  ) {}

  async ngOnInit(): Promise<void> {
    this._gaService.pageView("Register");
    try {
      const authRes = await this._authService.getUserFromAuthToken(
        this._route.snapshot.paramMap.get("authId")
      );
      this._gaService.setUser(authRes.userId, authRes.role);
      this.userId = authRes.userId;
      this.newUser = authRes;
    } catch {
      this.userId = null;
    }
    this.securityQuestions = await this._userService.getSecurityQuestions();
  }

  onSubmit(): void {
    this._gaService.trackEvent(
      "submit_registration",
      "register",
      "Submit Registration"
    );
    if (!this.register.valid || this.submitDisabled) {
      return;
    }
    this.submitDisabled = true;
    this.buttonState = "show-spinner";

    const user: RegisterForm = {
      userId: this.newUser.userId,
      lastName: this.register.value.lastName,
      firstName: this.register.value.firstName,
      phone: this.register.value.phone,
      password: this.register.value.password,
      questions: [
        {
          securityQuestionId: this.register.value.questionOne,
          response: this.register.value.answerOne,
        },
        {
          securityQuestionId: this.register.value.questionTwo,
          response: this.register.value.answerTwo,
        },
        {
          securityQuestionId: this.register.value.questionThree,
          response: this.register.value.answerThree,
        },
      ],
      phonePreferred: this.register.value.phonePreferred,
      emailPreferred: this.register.value.emailPreferred,
    };

    this._userService
      .register(user)
      .then(() => {
        this.userCreated = true;
        return null;
      })
      .catch((error) => {
        console.error(error);
        // this.notifications.create('Error', error.message, NotificationType.Bare, { theClass: 'outline primary', timeOut: 6000, showProgressBar: false });
        this.submitDisabled = false;
        this.buttonState = "";
      });
  }

  isSelected(qId): boolean {
    return this.selectedSecurityQuestions.includes(qId);
  }

  onSecurityQuestionChange(): void {
    this.selectedSecurityQuestions = [
      this.register.form.controls.questionOne.value,
      this.register.form.controls.questionTwo.value,
      this.register.form.controls.questionThree.value,
    ];
  }

  phoneselected(event: any): void {
    this.phonePreferred = event.target.checked;
    this.register.value.phonePreferred = this.phonePreferred ? true : false;
  }

  emailselected(event: any): void {
    this.emailPreferred = event.target.checked;
    this.register.value.emailPreferred = this.emailPreferred ? true : false;
  }

  public locationChanged(value: any, event: any): void {
    if (value === "all") {
      if (event.target.checked) {
        this.locationOptions = this.locationOptions.map((l) => {
          l.isSelected = true;
          return l;
        });
        this.isAllLocationsSelected = true;
      } else {
        this.locationOptions = this.locationOptions.map((l) => {
          l.isSelected = false;
          return l;
        });
        this.isAllLocationsSelected = false;
      }
    } else if (event.target.checked) {
      this.locationOptions = this.locationOptions.map((l) => {
        if (l.key === value) l.isSelected = true;
        return l;
      });
    } else {
      this.locationOptions = this.locationOptions.map((l) => {
        if (l.key === value) l.isSelected = false;
        return l;
      });
      this.isAllLocationsSelected = false;
    }
    this.validate();
  }

  public updateRadio(
    key: "role" | "access",
    // value: AccessTypes | UserTypes
    value: string
  ): void {
    if (key === "access" && isOfTypeAccess(value)) {
      this.roleSelected = "ADMIN";
      this.accessSelected = value;
    }
    if (key === "role" && isOfTypeUser(value)) {
      this.roleSelected = value;
      this.accessSelected = null;
    }
    this.validate();
  }

  public validate(): void {
    // add validation for valid email and password strength here
    if (this.register.value.password.length < 7) {
      this.invalidPassword = true;
      this.submitDisabled = true;
    } else {
      const lowercase = /[a-z]/.test(this.register.value.password) ? 1 : 0;
      const uppercase = /[A-Z]/.test(this.register.value.password) ? 1 : 0;
      const number = /\d/.test(this.register.value.password) ? 1 : 0;
      const specialChars = /[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]/;
      const specialChar = specialChars.test(this.register.value.password)
        ? 1
        : 0;
      const totalCount = lowercase + uppercase + number + specialChar;

      if (totalCount >= 3) {
        this.invalidPassword = false;
        this.submitDisabled = false;
      } else {
        this.invalidPassword = true;
        this.submitDisabled = true;
      }
    }
  }
}
