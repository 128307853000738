// This is just the beginning of enum use
// ideally keys should be used for all

export enum FetusPresentation {
  noFetusPresent = "No Fetus Present",
  cephalic = "Cephalic",
  transverseRight = "Transverse Right",
  transverseLeft = "Transverse Left",
  breech = "Breech",
  footingBreech = "Footing Breech",
  variable = "Variable",
}

export enum AmnioticFluid {
  subjectively_low = "Subjectively Low",
  subjectively_normal = "Subjectively Normal",
  subjectively_high = "Subjectively High",
}

export enum PlacentaVisualization {
  anterior = "Anterior",
  posterior = "Posterior",
  rightLateral = "Right Lateral",
  leftLateral = "Left Lateral",
  fundal = "Fundal",
  marginal = "Marginal",
  lowLying = "Low Lying",
  complete = "Complete",
}

export enum EchoIndications {
  "m-a" = "Patient or immediate family history of cardiac defects",
  "m-b" = "Patient or immediate family history of chromosomal disorders",
  "m-c" = "Anxiety, depression, or seizure medication exposure (SSRI drugs, Lithium, Zoloft, etc.)",
  "m-d" = "Abnormal screening",
  "m-e" = "Diabetes mellitus (Type I or II)",
  "m-f" = "Gestational diabetes diagnosed prior to 24 weeks gestational age of current pregnancy",
  "m-g" = "Lupus",
  "m-h" = "+SSA/Ro Antibodies",
  "m-i" = "Unexplained polyhydramnios",
  "fa-a" = "Echogenic intracardiac focus (EIF)",
  "fa-b" = "Two vessel umbilical cord",
  "fa-c" = "Gastroschisis, omphalocele",
  "fa-d" = "Cleft lip/palate",
  "fa-e" = "Choroid plexus cysts",
  "fa-f" = "Cardiac arrhythmia",
  "fa-g" = "Thickened nuchal translucency/nuchal fold",
  "fa-h" = "Hydrops",
  "fa-i" = "Pyelectasis",
  "o-a" = "Pain",
  "o-b" = "Bleeding",
  "o-c" = "Cramping",
  "o-d" = "Pelvic Pressure",
}

export enum StudyStatusList {
  ORDER_STARTED = "Survey In Progress",
  SWEEP_REVIEW_READY = "Ready for Sweep Review",
  SWEEP_REVIEW_STARTED = "Sweep Review In Progress",
  EXAM_COMPLETED = "Ready for Diagnostics & Evaluation",
  DIAG_EVAL_STARTED = "Diagnostics & Evaluation In Progress",
  DOC_REVIEW_READY = "Ready for Provider Review",
  DOC_REVIEW_STARTED = "Provider Review In Progress",
  ORDER_COMPLETED = "Order Completed",
}

export enum RoleFilterList {
  DRIVER = "Operator",
  SONOGRAPHER = "Sonographer",
  DOCTOR = "Provider",
  PATIENT = "Patient",
  ADMIN = "Admin",
}

export enum UltrasoundMethod {
  TRANSABDOMINAL = "Transabdominal",
  TRANSVAGINAL = "Transvaginal",
  TRANSABDOMINAL_TRANSVAGINAL = "Transabdominal and Transvaginal",
}

export enum VisitType {
  SCREENING = "Screening",
  FOLLOW_UP = "Follow Up",
}

// TODO: need more info as to use
export enum SweepDirections {
  "UP_AND_DOWN",
  "LEFT_AND_RIGHT",
  "TOP_LEFT_TO_RIGHT_HIP",
  "LEFT_HIP_TO_TOP_RIGHT",
}

export enum DueDateMethodology {
  "Last Menstrual Period" = "Last Menstrual Period",
  "Ultrasound" = "Today's Ultrasound",
  "Conception Date" = "Conception Date",
  "Baseline" = "Baseline",
}

// TODO: need more info as to use and should have a key
export enum PrenatalScreening {
  "Negative" = "Negative",
  "Positive" = "Positive",
  "Not Conducted" = "Not Conducted",
}
