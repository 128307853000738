import * as yup from "yup";
import {
  evaluationSelectors,
  sonographer1stTriFetusPresentationSelections,
  firstTriPlacentaVisualizationSelections,
  sonographerPregnancyTypeSelections,
  sonographerGestationalSacLocations,
  sonographerYolkSacEval,
  sonographerCardiacActivitySelections,
} from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographer1stTriV2GeneralEvalSchema = yup
  .object()
  .shape({
    cervixObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Cervix",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    cervixObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    cervixLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Cervix Length(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .test(
        "row-test",
        "Cervix length should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    fetusPregnancy: yup
      .string()
      .meta({
        control: "radio-list",
        label: "Fetus Pregnancy",
        options: sonographerPregnancyTypeSelections.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      })
      .oneOf(sonographerPregnancyTypeSelections),
    cardiacActivity: yup
      .string()
      .meta({
        control: "radio-list",
        label: "Cardiac Activity",
        options: sonographerCardiacActivitySelections.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      })
      .oneOf(sonographerCardiacActivitySelections),
    gestationalSacVis: yup
      .boolean()
      .meta({
        control: "checkbox",
        label: "Gestational Sac",
        options: [
          {
            key: "Gestational Sac Visualized",
            value: true,
          },
        ],
      } as FieldMeta)
      .default(false)
      .nullable(),
    gestationalSacLocation: yup
      .string()
      .oneOf(sonographerGestationalSacLocations)
      .meta({
        control: "radio-list",
        options: sonographerGestationalSacLocations.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      })
      .oneOf(sonographerGestationalSacLocations),
    embryoVis: yup
      .boolean()
      .meta({
        control: "checkbox",
        label: "Embryo",
        options: [
          {
            key: "Embryo Visualized",
            value: true,
          },
        ],
      } as FieldMeta)
      .default(false)
      .nullable(),
    yolkSacVis: yup
      .boolean()
      .meta({
        control: "checkbox",
        label: "Yolk Sac",
        options: [
          {
            key: "Yolk Sac Visualized",
            value: true,
          },
        ],
      } as FieldMeta)
      .default(false)
      .nullable(),
    yolkSacObsEval: yup
      .string()
      .oneOf(sonographerYolkSacEval)
      .meta({
        control: "radio-list",
        options: sonographerYolkSacEval.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      })
      .oneOf(sonographerYolkSacEval),
    placentaVisualized: yup
      .boolean()
      .meta({
        control: "checkbox",
        label: "Placenta",
        options: [
          {
            key: "Placenta Visualized",
            value: true,
          },
        ],
      } as FieldMeta)
      .default(false)
      .nullable(),
    placentalVisualization: yup
      .array()
      .of(yup.mixed().oneOf(firstTriPlacentaVisualizationSelections))
      .meta({
        control: "checkbox-list",
        options: firstTriPlacentaVisualizationSelections.map((p) => {
          return {
            key: p,
            value: p,
          } as Option;
        }),
      } as FieldMeta)
      .default([]),
    fetusPresentation: yup
      .string()
      .meta({
        control: "radio-list",
        label: "Fetal Position",
        options: sonographer1stTriFetusPresentationSelections.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(sonographer1stTriFetusPresentationSelections),
  })
  .required();

type Sonographer1stTriV2GeneralEvalType = yup.InferType<
  typeof sonographer1stTriV2GeneralEvalSchema
>;

export {
  sonographer1stTriV2GeneralEvalSchema,
  Sonographer1stTriV2GeneralEvalType,
};
