import * as yup from "yup";
import { evaluationSelectors } from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographer3rdTriV2GeneralEvalSchema = yup
  .object()
  .shape({
    cervixObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Cervix",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    cervixObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    cervixLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Cervix Length(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Cervix length is required")
      .test(
        "row-test",
        "Cervix length should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    placentaToCervix: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Distance from Placenta to Cervix(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Placenta To Cervix is required")
      .test(
        "row-test",
        "Placenta To Cervix should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    amnioticFluidIndexS1Q1: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "AFI1(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test("afiS1Q1-test", "This value must be a positive integer", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Amniotic Fluid Index is required"),
    amnioticFluidIndexS1Q2: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "AFI2(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test("afiS1Q2-test", "This value must be a positive integer", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Amniotic Fluid Index is required"),
    amnioticFluidIndexS1Q3: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "AFI3(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test("afiS1Q3-test", "This value must be a positive integer", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Amniotic Fluid Index is required"),
    amnioticFluidIndexS1Q4: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "AFI4(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test("afiS1Q4-test", "This value must be a positive integer", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Amniotic Fluid Index is required"),
    afiTotalCalc: yup.number().meta({
      control: "afi-calc",
      label: "AFI Total:",
      dicomUnit: "cm",
    } as FieldMeta),
    maxVerticalPocketLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "MVP (cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test("mvp-test", "This value must be a positive integer", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Max Vertical Pocket Length is required"),
    bpp: yup
      .string()
      .meta({
        control: "radio-list",
        label: "Biophysical Profile",
        options: [
          "Don't conduct Biophysical Profile",
          "Conduct Biophysical Profile",
        ].map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf([
        "Don't conduct Biophysical Profile",
        "Conduct Biophysical Profile",
      ]),
    grossBodyMvmt: yup
      .number()
      .typeError("This value must be a positive integer")
      .oneOf([0, 2])
      .test("gbm-test", "This value must 0 or 2", (v) => {
        return typeof v === "number" && (v === 0 || v === 2);
      })
      .meta({
        control: "dropdown",
        label: "Gross Body Movement",
        options: [0, 2].map((e) => {
          return {
            key: String(e),
            value: e,
          } as Option;
        }),
      })
      .required("Gross Body Movement is required"),
    fetalTone: yup
      .number()
      .typeError("This value must be a positive integer")
      .oneOf([0, 2])
      .test("ft-test", "This value must 0 or 2", (v) => {
        return typeof v === "number" && (v === 0 || v === 2);
      })
      .meta({
        control: "dropdown",
        label: "Fetal Tone",
        options: [0, 2].map((e) => {
          return {
            key: String(e),
            value: e,
          } as Option;
        }),
      })
      .required("Fetal Tone is required"),
    fetalBreathing: yup
      .number()
      .typeError("This value must be a positive integer")
      .oneOf([0, 2])
      .test("fb-test", "This value must 0 or 2", (v) => {
        return typeof v === "number" && (v === 0 || v === 2);
      })
      .meta({
        control: "dropdown",
        label: "Fetal Breathing",
        options: [0, 2].map((e) => {
          return {
            key: String(e),
            value: e,
          } as Option;
        }),
      })
      .required("Fetal Breathing is required"),
    amnioticFluidVol: yup
      .number()
      .typeError("This value must be a positive integer")
      .oneOf([0, 2])
      .test("afv-test", "This value must 0 or 2", (v) => {
        return typeof v === "number" && (v === 0 || v === 2);
      })
      .meta({
        control: "dropdown",
        label: "Amniotic Fluid Volume",
        options: [0, 2].map((e) => {
          return {
            key: String(e),
            value: e,
          } as Option;
        }),
      })
      .required("Amniotic Fluid Volume is required"),
  })
  .required();

type Sonographer3rdTriV2GeneralEvalType = yup.InferType<
  typeof sonographer3rdTriV2GeneralEvalSchema
>;

export {
  sonographer3rdTriV2GeneralEvalSchema,
  Sonographer3rdTriV2GeneralEvalType,
};
