import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import moment from "moment";
import { TenantService } from "src/app/core/services/tenant.service";
import { environment } from "../../../environments/environment";
import { CalculationName } from "../enums/calculation";
import { Author } from "../enums/author";
import { AlertService } from "./alert.service";

@Injectable({ providedIn: "root" })
export class CalculationService {
  constructor(
    private _http: HttpClient,
    private _alertService: AlertService,
    private _ts: TenantService
  ) {}

  public msgOptions = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  getCalculation(
    calculationName: CalculationName,
    author: Author,
    year: number | null,
    params: any
  ): Promise<any> {
    Object.keys(params).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        if (key === "examDate") {
          params[key] = moment(params[key])
            .utc()
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0);
        }
        // Format lmp or edd to be correct dateobject, and verify its not null
        if (
          params[key] != null &&
          (key === "lmpDate" || key === "ivfDate" || key === "eddDate") &&
          typeof params[key] === "number"
        ) {
          const year = params[key].toString().slice(0, 4);
          const month = params[key].toString().slice(4, 6);
          const day = params[key].toString().slice(6);
          params[key] = new Date(Date.UTC(year, month - 1, day, 0, 0, 0, 0));
        }
      }
    });
    // The calculation name, author and year will ALWAYS be filled in
    const body = {
      calculation: calculationName,
      author,
      year,
      ...params,
    };
    const url = `${environment.bbApiUrl}${environment.calculationPort}/calculation`;
    return this._http
      .post(url, body)
      .toPromise()
      .catch((error) => {
        this._alertService.error(
          `Error ${
            error.statusCode ? error.statusCode : ""
          }: An error was encountered, please refresh the page and try again.`,
          this.msgOptions
        );
      });
  }

  //TODO: This can be deleted once the IntakeReport starts being used everywhere
  calculateBmi(height: number, weight: number): number {
    if (height && weight) {
      return (weight / height ** 2) * 703;
    }
    return null;
  }

  //TODO: This can be deleted once the IntakeReport starts being used everywhere
  calculateGravida(
    numLessThan37Deliveries: number,
    numGreaterThan37Deliveries: number,
    numMiscarriagesAbortions: number
  ): number {
    const gravida =
      1 + // current pregnancy
      (numLessThan37Deliveries || 0) +
      (numGreaterThan37Deliveries || 0) +
      (numMiscarriagesAbortions || 0);
    return gravida;
  }
}
