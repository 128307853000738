/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { PipeTransform, Pipe } from "@angular/core";
import moment from "moment";

@Pipe({ name: "timeAgo" })
export class TimeAgoPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform(value: any, args?: any): any {
    const timeZoneAdjustedNewDate = moment().utcOffset(0, true).toDate();
    const tzAdjustedValue = moment(value).toDate();
    if (value) {
      const seconds = Math.floor(
        moment(timeZoneAdjustedNewDate).diff(tzAdjustedValue, "milliseconds") /
          1000
      );
      if (seconds < 29)
        // less than 30 seconds ago will show as 'Just now'
        return "Just now";
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          return counter === 1 ? `${counter} ${i} ago` : `${counter} ${i}s ago`;
      }
    }
    return value;
  }
}

/* eslint-enable guard-for-in */
/* eslint-enable no-restricted-syntax */
