import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "yesNo",
})
export class YesNoPipe implements PipeTransform {
  transform(value: boolean | string): any {
    if (value == undefined || value === "") {
      return "Not Provided";
    }
    return value ? "Yes" : "No";
  }
}
