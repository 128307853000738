<div ngbDropdown class="d-inline-block">
  <button type="button" class="btn header-button" ngbDropdownToggle [disabled]="isModal" (click)="toggled()">
    <div>{{cptText}}</div><span class="dropdown-caret"></span>
  </button>
  <div id="cpt-codes" class="px-3 py-2" ngbDropdownMenu aria-labelledby="cpt codes">
    <div class="row">
      <div class="col-12">
      <cpt-code-selector></cpt-code-selector>
      </div>
    </div>
  </div>
</div>
