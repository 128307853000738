import { Injectable } from "@angular/core";
import { EMPTY, Observable, throwError, timer } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { ErrorMessage } from "src/app/global/enums/error-message";
import { AlertService } from "src/app/global/services/alert.service";

@Injectable({ providedIn: "root" })
export class RxjsService {
  public msgOptions = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  private retryConfig = {
    maxRetryAttempts: 5,
    delay: 1000,
  };

  private includedStatusCodes = [403, 500, 501, 502, 503, 504];

  constructor(private _alertService: AlertService) {}

  public handleErrors(error: HttpErrorResponse): Observable<Response> {
    const httpStatus = error?.status.toString(10);
    const httpError = error?.error;

    if (httpError instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      const msg = `Error ${httpStatus}: ${ErrorMessage.client}`;
      this._alertService.error(msg, this.msgOptions);
      console.error(msg);
    } else {
      // The backend returned an unsuccessful response code
      const msg = `Error ${httpStatus}: ${ErrorMessage.server}`;
      this._alertService.error(msg, this.msgOptions);
      console.error(msg);
    }
    return EMPTY;
  }

  /**
   * retryWithEscalatingDelay Retries requests with an http status  code of 5XX and 403
   * each retry will escalate the delay (1 second, 2 seconds, 3 seconds, 5 seconds, 8 seconds)
   *
   * Initial code was based: https://www.learnrxjs.io/learn-rxjs/operators/error_handling/retrywhen
   *
   * TODO:  This will need to be re-written as retryWhen is deprecated in future releases;
   *   version 9 can use: retry({ delay: () => notify$ }).
   *
   * @param attempts
   */
  RetryWithEscalatingDelay(attempts: Observable<any>): any {
    const config = this.retryConfig;
    return attempts.pipe(
      mergeMap((error, i) => {
        const codes = (this.includedStatusCodes = [
          403, 500, 501, 502, 503, 504,
        ]);
        const retryAttempt = i + 1;

        // using an increasing delay (1 second, 2 seconds, 3 seconds, 5 seconds, 8 seconds)
        const currentDelay =
          retryAttempt < 4
            ? retryAttempt * config.delay
            : retryAttempt === config.maxRetryAttempts
            ? config.delay * 8
            : (retryAttempt + 1) * config.delay;

        // if maximum number of retries have been met throw error
        if (retryAttempt > config.maxRetryAttempts) {
          return throwError(error);
        }
        //  keep for testing
        // else {
        //   console.log(`Attempt ${retryAttempt}: retrying in ${currentDelay}ms`);
        // }
        if (error?.status && codes.find((e) => e === error.status)) {
          // The backend returned an unsuccessful response code that was NOT 5xx or 403 so throw error
          return throwError(error);
        }
        return timer(currentDelay);
      })
    );
  }
}
