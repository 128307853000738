/**
 * Template sets anatomy appearing in Diagnostics.
 * When ever this is changed/updated the ids a corresponding change needs
 * to happen in the corresponding file that is used for the gallery view:
 * src/app/modules/patient-order/evaluation/models/evaluation-constants.ts
 */
export const thirdTrimesterTemplate = [
  {
    sectionName: "General Evaluation",
    templates: [
      {
        name: "Gestations",
        id: "gestationsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Presentation",
        id: "fetusPresentation",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Cervix",
        id: "cervixObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "CL",
        id: "cervixLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Placenta",
        id: "placentaObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Placental Cord Insertion",
        id: "placentalCordInsertionObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Umbilical Cord",
        id: "umbCordObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Amniotic Fluid",
        id: "afvObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "PLAC to CVX",
        id: "placentaToCervix",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "MVP",
        id: "maxVerticalPocketLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "AFI 1",
        id: "amnioticFluidIndexS1Q1",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "AFI 2",
        id: "amnioticFluidIndexS1Q2",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "AFI 3",
        id: "amnioticFluidIndexS1Q3",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "AFI 4",
        id: "amnioticFluidIndexS1Q4",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
    ],
  },
  {
    sectionName: "Maternal Structures",
    templates: [
      {
        name: "Uterus",
        id: "uterusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Extrauterine Mass",
        id: "extrauterineMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "EU MASS L",
        id: "extrauterineMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "EU MASS H",
        id: "extrauterineMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "EU MASS W",
        id: "extrauterineMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Right Ovary",
        id: "rightOvaryObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "RT OV L",
        id: "rightOvaryLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RT OV H",
        id: "rightOvaryHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RT OV W",
        id: "rightOvaryWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Right Adnexal Mass",
        id: "rightAdnexalMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "RA MASS L",
        id: "rightAdnexalMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RA MASS H",
        id: "rightAdnexalMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RA MASS W",
        id: "rightAdnexalMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Left Ovary",
        id: "leftOvaryObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "LT OV L",
        id: "leftOvaryLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LT OV H",
        id: "leftOvaryHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LT OV W",
        id: "leftOvaryWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Left Adnexal Mass",
        id: "leftAdnexalMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "LA MASS L",
        id: "leftAdnexalMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LA MASS H",
        id: "leftAdnexalMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LA MASS W",
        id: "leftAdnexalMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
    ],
  },
  {
    sectionName: "Biometry",
    templates: [
      {
        tool: "CustomLength",
        name: "BPD",
        id: "biparietalDiameter",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomEllipticalRoi",
        name: "HC",
        id: "headCircumference",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomEllipticalRoi",
        name: "AC",
        id: "abdominalCircumference",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "FL",
        id: "femurLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "TIB",
        id: "tibiaLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "FIB",
        id: "fibulaLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "HL",
        id: "humerusLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "ULNA",
        id: "ulnaLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RAD",
        id: "radiusLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
    ],
  },
  {
    sectionName: "Brain",
    templates: [
      {
        name: "Posterior Fossa",
        id: "posteriorFossaObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "Trans Cereb Diam",
        id: "cerebellarDiameter",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "CM",
        id: "cisternaMagna",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LV",
        id: "lateralVentricle",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        name: "RT Choroid Plexus",
        id: "rightChoroidPlexusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Choroid Plexus",
        id: "leftChoroidPlexusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Midline Falx",
        id: "midlineFalxObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Cavum Septi Pellucidi",
        id: "cavumSeptiPellucidiObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Cranium",
        id: "craniumObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Neck/Skin",
        id: "neckSkinObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Face",
    templates: [
      {
        name: "Profile",
        id: "profileObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Nasal Bone",
        id: "nasalBoneObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Nose/Lips",
        id: "noseLipsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Palate",
        id: "palateObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Orbits",
        id: "orbitsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Cardiac",
    templates: [
      {
        name: "Cardiac Activity",
        id: "cardiacActivity",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Fetal Heart Rate",
        id: "fetalHeartRate",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "4 Chamber Heart",
        id: "heart4Obs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LVOT",
        id: "lvotObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "RVOT",
        id: "rvotObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "3VV / 3VTV",
        id: "heart3Obs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Thymus",
        id: "thymusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Situs",
        id: "situsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Aortic Arch",
        id: "aorticObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Superior & Inferior Venae Cavae",
        id: "siVcObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Ductal Arch",
        id: "ductalArchObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Diaphragm",
        id: "diaphragmObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Abdomen",
    templates: [
      {
        name: "Stomach",
        id: "stomachObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "RT Kidney",
        id: "rightKidneyObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Kidney",
        id: "leftKidneyObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Bladder",
        id: "bladderObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Abdominal Cord Insert",
        id: "abdCordObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Genitalia",
        id: "genitaliaObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Spine",
    templates: [
      {
        name: "C Spine",
        id: "cSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "T Spine",
        id: "tSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "L Spine",
        id: "lSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "S Spine",
        id: "sSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Extremities",
    templates: [
      {
        name: "RT Leg",
        id: "rightLegObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "RT Foot",
        id: "rightFootObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "RT Arm",
        id: "rightArmObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "RT Hand",
        id: "rightHandObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Leg",
        id: "leftLegObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Foot",
        id: "leftFootObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Arm",
        id: "leftArmObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Hand",
        id: "leftHandObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Biophysical Profile",
    templates: [
      {
        name: "Gross Body Movement",
        id: "grossBodyMovementObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Fetal Tone",
        id: "fetalToneObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Fetal Breathing",
        id: "fetalBreathingObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Amniotic Fluid Volume",
        id: "amnioticFluidVolumeObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
];
