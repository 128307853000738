import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "dicomDateTransform" })
export class DicomDateTransformPipe implements PipeTransform {
  transform(val: string | number): string {
    if (!val) {
      return null;
    }

    // Date number is in a YYYYMMDD format, we need to convert it to mmddyyyy for calculations
    const oldDateString = val?.toString() || "";
    const year = oldDateString.slice(0, 4);
    const month = oldDateString.slice(4, 6);
    const day = oldDateString.slice(6, 8);
    const newDateString = `${month}/${day}/${year}`;
    return newDateString;
  }
}
