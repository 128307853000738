<div class="login-page">
  <div class="container">
    <div class="login-centered">
      <div class="col-12 mt-5 p-0 g-0">
        <div class="row p-0 g-0">
          <div class="card auth-card row my-auto p-0">
            <div class="momBabyImage col-lg-4"></div>
            <div class="form-side col-8 col-md-6 col-sm-4">
              <a class="c-pointer">
                <span class="logo-single"></span>
              </a>
              <h6 class="mb-4">Reset Password</h6>

              <form #resetForm="ngForm" novalidate (ngSubmit)="onSubmit()">
                <div class="mb-3" *ngIf="!canReset">
                  <label class="form-group has-float-label">Security Question</label>
                  <p *ngIf="!loadingSecurityQuestion">{{securityQuestion.question}}</p>
                  <p *ngIf="loadingSecurityQuestion">Retreiving security question...</p>
                  <input class="form-control" type="text" placeholder="" required ngModel #response="ngModel" name="response" />
                  <div *ngIf="!response.valid && resetForm.submitted" class="invalid-tooltip">An answer is required!</div>
                  <div *ngIf="isWrongAnswer" class="invalid-tooltip">This answer is incorrect. Please try again.</div>
                </div>
                <div class="mb-3" *ngIf="canReset">
                  <label class="form-group has-float-label">New Password</label>
                  <div *ngIf="invalidPassword && newPassword.touched" class="invalid_password_message">The password you provided does not meet requirements.</div>
                  <input class="form-control" type="password" required ngModel #newPassword="ngModel" name="newPassword" />
                  <!-- <div *ngIf="!newPassword.valid && resetForm.submitted" class="invalid-tooltip">Password is required!</div> -->
                  <div class="password_details">Passwords must be  at least 7 characters in  length and contain three of the following: upper case, lower case, numbers, and special characters.</div>
                </div>
                <div *ngIf="isSuccessfulReset">Your password has been reset. You will be redirected to the login screen in 5 seconds.</div>
                <div class="d-flex justify-content-between align-items-center">
                  <state-button [btnClass]="'btn btn-primary btn-lg btn-shadow'" [currentState]="buttonState"
                    [isDisabled]="buttonDisabled">
                    SUBMIT
                  </state-button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <bbi-footer></bbi-footer>
        </div>
      </div>
    </div>
  </div>
</div>
