import { Injectable } from "@angular/core";
import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
import moment from "moment";
//import dayjs from "dayjs";

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly inputDateFormat = environment.inputDateFormat;

  parse(value: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }
    const date = value.split("/");
    return {
      month: Number.parseInt(date[0], 10),
      day: Number.parseInt(date[1], 10),
      year: Number.parseInt(date[2], 10),
    };
  }

  /**
   * NgbDateStruct is {year: 2023, month: 5, day: 2}
   * TODO: remove moment
   * @param date
   */
  format(date: NgbDateStruct | null): string {
    if (date === null) {
      return "";
    }
    const sDate = `${date.year}/${date.month}/${date.day}`;
    return moment(sDate).format(this.inputDateFormat);
    // return dayjs(sDate, "YYYY/M/D").format(this.inputFormat);
  }
}
