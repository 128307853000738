import * as yup from "yup";
import { bestOverallAssessmentSelections } from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographerEpV2FetalBioSchema = yup.object().shape({
  estimatedFetalAge: yup.string().meta({
    control: "fa-calc",
    label: "Estimated Fetal Age:",
  } as FieldMeta),
  crownRumpLength: yup
    .number()
    .meta({
      control: "dicom-selector",
      label: "Crown Rump Length (Hadlock, 1992)(mm)",
      dicomUnit: "mm",
    } as FieldMeta)
    .typeError("This value must be a positive integer")
    .test(
      "crownRump-test",
      "This value must be a positive integer or null",
      (v: any) => {
        return typeof v === "number" && v >= 0;
      }
    )
    .required("Crown Rump Length is required"),
  crownRumpLengthCalcGestAge: yup.string().meta({
    control: "crl-calc",
    label: "Estimated Gestational Age:",
  } as FieldMeta),
  crownRumpLengthCalcPerc: yup.string().meta({
    control: "crl-calc",
    label: "Percentile:",
  } as FieldMeta),
  gestationalSacDiameter: yup
    .number()
    .meta({
      control: "dicom-selector",
      label: "Gestational Sac Diameter (Hellman, 1969)(mm)",
      dicomUnit: "mm",
    } as FieldMeta)
    .typeError("This value must be a positive integer")
    .test(
      "gsd-test",
      "This value must be a positive integer or null",
      (v: any) => {
        return typeof v === "number" && v >= 0;
      }
    )
    .required("Gestational Sac Diameter is required"),
  gestationalSacDiameterCalcGestAge: yup.string().meta({
    control: "gsd-calc",
    label: "Estimated Gestational Age:",
  } as FieldMeta),
  gestationalSacDiameterCalcPerc: yup.string().meta({
    control: "gsd-calc",
    label: "Percentile:",
  } as FieldMeta),
  bestOverallAssessment: yup
    .string()
    .meta({
      control: "radio-list",
      label: "Best Overall Assessment",
      options: bestOverallAssessmentSelections.map((e) => {
        return {
          key: e,
          value: e,
        } as Option;
      }),
    } as FieldMeta)
    .oneOf(bestOverallAssessmentSelections)
    .required("Best overall assessment is required"),
  bestGestationAgeWeeks: yup
    .number()
    .meta({
      control: "input-number",
      label: "Best Gestation Age Weeks",
    } as FieldMeta)
    .typeError("This value must be a positive integer")
    .test(
      "bgaw-test",
      "This value must be a positive integer or null",
      (v: any) => {
        return typeof v === "number" && v >= 0;
      }
    )
    .required("Gestation age weeks is required"),
  bestGestationAgeDays: yup
    .number()
    .meta({
      control: "input-number",
      label: "Best Gestation Age Days",
    } as FieldMeta)
    .typeError("This value must be a positive integer")
    .test(
      "bgad-test",
      "This value must be a positive integer or null",
      (v: any) => {
        return typeof v === "number" && v >= 0;
      }
    )
    .required("Gestation age days is required"),
});

type SonographerEpV2FetalBioType = yup.InferType<
  typeof sonographerEpV2FetalBioSchema
>;

export { sonographerEpV2FetalBioSchema, SonographerEpV2FetalBioType };
