//TODO: use enums instead located at: telescan/src/app/models/enums.ts
// remove when change over is complete
const echoIndicationsMap = [
  ["m-a", "Patient or immediate family history of cardiac defects"],
  ["m-b", "Patient or immediate family history of chromosomal disorders"],
  [
    "m-c",
    "Anxiety, depression, or seizure medication exposure (SSRI drugs, Lithium, Zoloft, etc.)",
  ],
  ["m-d", "Abnormal screening"],
  ["m-e", "Diabetes mellitus (Type I or II)"],
  [
    "m-f",
    "Gestational diabetes diagnosed prior to 24 weeks gestational age of current pregnancy",
  ],
  ["m-g", "Lupus"],
  ["m-h", "+SSA/Ro Antibodies"],
  ["m-i", "Unexplained polyhydramnios"],
  ["fa-a", "Echogenic intracardiac focus (EIF)"],
  ["fa-b", "Two vessel umbilical cord"],
  ["fa-c", "Gastroschisis, omphalocele"],
  ["fa-d", "Cleft lip/palate"],
  ["fa-e", "Choroid plexus cysts"],
  ["fa-f", "Cardiac arrhythmia"],
  ["fa-g", "Thickened nuchal translucency/nuchal fold"],
  ["fa-h", "Hydrops"],
  ["fa-i", "Pyelectasis"],
  ["o-a", "Pain"],
  ["o-b", "Bleeding"],
  ["o-c", "Cramping"],
  ["o-d", "Pelvic Pressure"],
] as const;

const studyStatusList = [
  { id: "ORDER_STARTED", name: "Survey In Progress" },
  { id: "SWEEP_REVIEW_READY", name: "Ready for Sweep Review" },
  { id: "SWEEP_REVIEW_STARTED", name: "Sweep Review In Progress" },
  { id: "EXAM_COMPLETED", name: "Ready for Diagnostics & Evaluation" },
  {
    id: "DIAG_EVAL_STARTED",
    name: "Diagnostics & Evaluation In Progress",
  },
  { id: "DOC_REVIEW_READY", name: "Ready for Provider Review" },
  { id: "DOC_REVIEW_STARTED", name: "Provider Review In Progress" },
  { id: "ORDER_COMPLETED", name: "Order Completed" },
];

const roleFilterList = [
  { id: "DRIVER", name: "Operator" },
  { id: "SONOGRAPHER", name: "Sonographer" },
  { id: "DOCTOR", name: "Provider" },
  { id: "PATIENT", name: "Patient" },
  { id: "ADMIN", name: "Admin" },
];

const sweepDirections = [
  "UP_AND_DOWN",
  "LEFT_AND_RIGHT",
  "TOP_LEFT_TO_RIGHT_HIP",
  "LEFT_HIP_TO_TOP_RIGHT",
  "ADDITIONAL",
] as const;

const jpegQualityThreshold = 75; // Lowest acceptable JPEG image QXX rating

const SWEEP_DIRECTION_MAP = {
  LEFT_AND_RIGHT: 1,
  UP_AND_DOWN: 2,
  LEFT_HIP_TO_TOP_RIGHT: 3,
  TOP_LEFT_TO_RIGHT_HIP: 4,
  ADDITIONAL: 5,
};

const bppKeys = [
  "grossBodyMovementObs_value",
  "fetalToneObs_value",
  "fetalBreathingObs_value",
  "amnioticFluidVolumeObs_value",
];

const mmUnitAnatomy = [
  "nuchalTranslucency",
  "yolkSacLength",
  "gestationalSacDiameter",
  "cisternaMagna",
  "nuchalFold",
  "lateralVentricle",
  "crownRumpLength",
  "cerebellarDiameter",
];

const pregnancyStages = [
  "early-pregnancy",
  "first-trimester",
  "second-trimester",
  "third-trimester",
] as const;

export {
  echoIndicationsMap,
  studyStatusList,
  roleFilterList,
  sweepDirections,
  SWEEP_DIRECTION_MAP,
  bppKeys,
  mmUnitAnatomy,
  pregnancyStages,
  jpegQualityThreshold,
};
