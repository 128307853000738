import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AlertService } from "src/app/global/services/alert.service";
import { environment } from "src/environments/environment";
import {
  RegisterForm,
  UserPreferences,
  UserProfile,
} from "src/app/models/user-profile";
import { ProviderInfo } from "../../modules/patient-order/report/models/types.model";
import { JWTTokenService } from "./jwt.service";
import { TenantService } from "./tenant.service";
import { LocalStorageService } from "./local-storage.service";
import { catchError, map, shareReplay } from "rxjs/operators";
import { forkJoin, Observable } from "rxjs";
import { RxjsService } from "../../global/services/rxjs-service";
import { InstitutionService } from "../../global/services/institution.service";

const USER_PREFERENCES = "user_preferences";
const DISMISSED_STUDY_ID_MODALS = "DISMISSED_STUDY_ID_MODALS";

type SecQuesRes = {
  id: string;
  question: string;
};

@Injectable({ providedIn: "root" })
export class UserService {
  private baseUrl: string;
  currentUser: UserProfile | null = null;
  public msgOptions = {
    autoClose: false,
    keepAfterRouteChange: false,
  };

  constructor(
    private _http: HttpClient,
    private _jwt: JWTTokenService,
    private _institutionService: InstitutionService,
    private _rxjsService: RxjsService,
    private _alertService: AlertService,
    private _ts: TenantService,
    private _localStorage: LocalStorageService
  ) {
    this.baseUrl = `${environment.bbApiUrl}${environment.userPort}`;
  }

  public async refreshSetup(): Promise<any> {
    return await this.getMyProfile(this._jwt.getParsedToken().userId);
  }

  public async getMyProfile(userId: string): Promise<UserProfile> {
    try {
      const url = `${environment.bbApiUrl}${
        environment.userPort
      }/${this._ts.getTenant()}/user/${userId}`;
      const res: UserProfile = (await this._http
        .get(url)
        .toPromise()) as UserProfile;
      const currentPrefs = this.getUserPreferences();
      const loadedPrefs = res.preferences;

      // Strip out the dateStart and dateEnd from loadedPrefs since those don't get stored in the db
      delete loadedPrefs.dateStart;
      delete loadedPrefs.dateEnd;
      if (res.preferences)
        this.setUserPreferences({ ...currentPrefs, ...loadedPrefs });
      this.currentUser = res;
      return res;
    } catch (error) {
      console.log("Error in getMyProfile", error);
      throw error;
    }
  }

  /**
   * Get the Provider (Doctors) information
   * @param userId
   */
  public getProviderInfo(userId: string): Observable<ProviderInfo> {
    const url = `${this.baseUrl}/${this._ts.getTenant()}/user/${userId}`;
    return this._http.get(url).pipe(
      map((profile: UserProfile) => {
        return {
          firstName: profile.firstName,
          lastName: profile.lastName,
          endorsements: profile.endorsements,
        };
      }),
      catchError((err) => {
        console.log("Error in getProviderInfo", err);
        throw err;
      })
    );
  }

  /**
   * Called when an admin creates a new user.
   * This endpoint also triggers an email to be sent to email address provided
   *
   * @param user {email, role, ePHIAccess, locations}
   * @param institutionId string
   * @returns {id, institutionId, firstName, lastName, email, endorsements, clinics}
   */
  public createUser(user, institutionId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const body = {
        institutionId,
        firstName: "",
        lastName: "",
        email: user.email,
        username: user.email,
        role: user.role,
        clinics: user.locations,
        permissions: user.ePHIAccess,
      };

      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.userPort
          }/${this._ts.getTenant()}/user`,
          body,
          {
            responseType: "text",
          }
        )
        .toPromise()
        .then((data) => {
          return resolve(data);
        })
        .catch((error) => {
          const alertMsg =
            error.status === 409
              ? "This email address is being used by another TeleScan user. An email may only be associated with one user account in an Institution."
              : `Error ${
                  error.status ? error.status : ""
                }: An error was encountered, please refresh the page and try again.`;
          this._alertService.error(alertMsg, this.msgOptions);
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject();
        });
    });
  }

  /**
   * Called when the user has completed their registration.
   *
   * @param user RegisterForm
   * @returns boolean to determine if the registration was successful
   */
  public register(user: RegisterForm): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const body = {
        userId: user.userId,
        fields: [
          { name: "firstName", value: user.firstName },
          { name: "lastName", value: user.lastName },
          { name: "phone", value: user.phone },
          { name: "password", value: user.password },
          { name: "questions", value: user.questions },
          { name: "phonePreferred", value: user.phonePreferred },
          { name: "emailPreferred", value: user.emailPreferred },
        ],
      };

      this._http
        .patch(
          `${environment.bbApiUrl}${
            environment.userPort
          }/${this._ts.getTenant()}/user`,
          body
        )
        .toPromise()
        .then((data: boolean) => {
          return this._http
            .get(
              `${environment.bbApiUrl}${
                environment.authPort
              }/${this._ts.getTenant()}/auth/clear/registertoken/${user.userId}`
            )
            .toPromise()
            .then(() => {
              return resolve(data);
            })
            .catch((error) => {
              this._alertService.error(
                `Error ${
                  error.statusCode ? error.statusCode : ""
                }: An error was encountered, please refresh the page and try again.`,
                this.msgOptions
              );
              reject();
            });
        })
        // eslint-disable-next-line unicorn/catch-error-name
        .catch((err) => {
          this._alertService.error(
            `Error ${
              err.statusCode ? err.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          // console.log("Error", error);
          // TODO: Add this to logging service
          reject();
        });
      this._http
        .patch(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth`,
          body
        )
        .toPromise()
        .then((data: boolean) => {
          return resolve(data);
        })
        .catch(() => {
          reject();
        });
    });
  }

  public update(
    userId: string,
    fields: { name: string; value: any }[]
  ): Promise<UserProfile> {
    return new Promise((resolve, reject) => {
      const body = {
        userId,
        fields,
      };

      for (const field of fields) {
        if (field.name === "preferences") {
          this.setUserPreferences(field.value);
        }
      }

      this._http
        .patch(
          `${environment.bbApiUrl}${
            environment.userPort
          }/${this._ts.getTenant()}/user`,
          body
        )
        .toPromise()
        .then((data: UserProfile) => {
          return resolve(data);
        })
        .catch(() => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject();
        });
    });
  }

  public async getUsersByInstitutionId(
    institutionId: string
  ): Promise<UserProfile[]> {
    return new Promise((resolve) => {
      const url = `${environment.bbApiUrl}${
        environment.userPort
      }/${this._ts.getTenant()}/user/institution/${institutionId}`;
      this._http
        .get(url)
        .toPromise()
        .then((res: UserProfile[]) => {
          resolve(res);
          return null;
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          // TODO: Add this to logging service
          // console.log("Error getting users in getUsersByInstitutionId", error);
        });
    });
  }

  public async getUsersByRole(
    role: "DRIVER" | "SONOGRAPHER" | "DOCTOR" | "ADMIN",
    institutionId: string
  ): Promise<UserProfile[]> {
    try {
      const url = `${environment.bbApiUrl}${
        environment.userPort
      }/${this._ts.getTenant()}/user/institution/${institutionId}/role/${role}`;
      const res: UserProfile[] = (await this._http
        .get(url)
        .toPromise()) as UserProfile[];

      if (res) {
        res.sort((a, b) => {
          const al = a.lastName.toLowerCase();
          const bl = b.lastName.toLowerCase();
          if (al < bl) {
            return -1;
          }
          if (al > bl) {
            return 1;
          }
          return 0;
        });
      }
      return res;
    } catch (error) {
      console.log("Error in getUsersByRole", error);
      this._alertService.error(
        `Error ${
          error.statusCode ? error.statusCode : ""
        }: An error was encountered getting the list of reading providers, please refresh the page and try again.`,
        this.msgOptions
      );
    }
  }

  public getSecurityQuestions(): Promise<SecQuesRes[]> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          `
          ${environment.bbApiUrl}${
            environment.userPort
          }/${this._ts.getTenant()}/user/security/questions
        `
        )
        .toPromise()
        .then((questions: SecQuesRes[]) => {
          // Per T1-2639 2 questions need to be removed from user options
          // They need to stay in the database though for existing users who selected them
          const filteredQuestions = questions.filter(
            (q) => q.id !== "1" && q.id !== "2"
          );
          return resolve(filteredQuestions);
        })
        .catch(() => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject();
        });
    });
  }

  public getSecurityQuestionForUser(userId: string): Promise<SecQuesRes> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          `${environment.bbApiUrl}${
            environment.userPort
          }/${this._ts.getTenant()}/user/security/random/${userId}`
        )
        .toPromise()
        .then((question: SecQuesRes) => {
          return resolve(question);
        })
        .catch(() => {
          // console.log("Error", error);
          reject();
        });
    });
  }

  public submitQuestionAnswer(
    token: string,
    questionId: string,
    answer: string,
    newPassword: string
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      const body = {
        token,
        questionId,
        answer,
        newPassword,
      };

      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.userPort
          }/${this._ts.getTenant()}/user/security/answer`,
          body,
          {
            responseType: "text",
          }
        )
        .toPromise()
        .then((result) => {
          return resolve(result);
        })
        .catch(() => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject();
        });
    });
  }

  public setUserPreferences(prefs: UserPreferences | string): void {
    prefs = typeof prefs === "string" ? prefs : JSON.stringify(prefs);
    this._localStorage.set(USER_PREFERENCES, prefs);
  }

  public getUserPreferences(): UserPreferences {
    try {
      const userPrefsJson = this._localStorage.get(USER_PREFERENCES);
      if (userPrefsJson && userPrefsJson !== "undefined") {
        return JSON.parse(userPrefsJson);
      }
    } catch (error) {
      console.log("Error in getUserPreferences", error);
    }
  }

  public dismissStudyQualityModal(
    studyId: string,
    dismissBoxChecked: boolean
  ): void {
    try {
      let dismissedStudyIds = this.getDismissStudyIdModals();
      const isInDismissed = dismissedStudyIds.includes(studyId);
      // Check if study is in dismissed ids array and checkbox is selected to dismiss quality modal
      if (isInDismissed && dismissBoxChecked) return;
      // Check if study is in dismissed ids array and checkbox is NOT selected to dismiss quality modal
      if (isInDismissed && !dismissBoxChecked) {
        // Remove study from dismissed ids array
        dismissedStudyIds = dismissedStudyIds.filter((e) => e !== studyId);
      } else {
        dismissedStudyIds.push(studyId);
      }
      this._localStorage.set(
        DISMISSED_STUDY_ID_MODALS,
        JSON.stringify(dismissedStudyIds)
      );
    } catch (error) {
      console.log("Error in dismissStudyQualityModal", error);
    }
  }

  public getDismissStudyIdModals(): string[] {
    try {
      const dismissedStudyIds = this._localStorage.get(
        DISMISSED_STUDY_ID_MODALS
      );
      if (dismissedStudyIds && dismissedStudyIds !== "undefined") {
        return JSON.parse(dismissedStudyIds);
      }
      return [];
    } catch (error) {
      console.log("Error in getDismissStudyIdModals", error);
    }
  }

  public async saveViewedSweep(sweepId: string): Promise<boolean> {
    try {
      const usersViewed = this._jwt.getParsedToken()?.userId;
      if (!usersViewed || !sweepId) return;

      await this._http
        .get(
          `${environment.bbApiUrl}${
            environment.studyPort
          }/${this._ts.getTenant()}/sweep/viewedSweep/${sweepId}/${usersViewed}`
        )
        .toPromise();
      return true;
    } catch (error) {
      console.log("Error in saveViewedSweep", error);
      return false;
    }
  }
  //=============== Observables =================

  getMyProfile$(userId: string): Observable<UserProfile> {
    const url = `${this.baseUrl}/${this._ts.getTenant()}/user/${userId}`;

    return this._http.get(url).pipe(
      shareReplay(1),
      catchError((err) => this._rxjsService.handleErrors(err)),
      map((data: UserProfile) => {
        return data;
      })
    );
  }

  getUserInfo$(userId: string, institutionId: string): Observable<UserProfile> {
    const profileInfo$ = this.getMyProfile$(userId);
    const institutionInfo$ =
      this._institutionService.getInstitutionNameById$(institutionId);

    return forkJoin([profileInfo$, institutionInfo$]).pipe(
      map(([profileInfo, institutionName]) => {
        profileInfo.institutionName = institutionName;
        return profileInfo;
      })
    );
  }
}
