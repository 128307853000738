import { PipeTransform, Pipe } from "@angular/core";

@Pipe({ name: "readableStudyStatus" })
export class ReadableStudyStatusPipe implements PipeTransform {
  transform(val: string, usrRole: string): string {
    //usrRole = usrRole.toLowerCase();
    let readableStatus;
    switch (val) {
      case "ORDER_STARTED": {
        readableStatus = "Survey In Progress";
        break;
      }
      case "SWEEP_REVIEW_READY": {
        readableStatus = "Ready for Sweep Review";
        break;
      }
      case "SWEEP_REVIEW_STARTED": {
        readableStatus = "Sweep Review In Progress";
        break;
      }
      case "EXAM_COMPLETED": {
        readableStatus = "Ready for Diagnostics & Evaluation";
        break;
      }
      case "DIAG_EVAL_STARTED": {
        readableStatus = "Diagnostics & Evaluation In Progress";
        break;
      }
      case "DOC_REVIEW_READY": {
        readableStatus = "Ready for Provider Review";
        break;
      }
      case "DOC_REVIEW_STARTED": {
        readableStatus = "Provider Review In Progress";
        break;
      }
      case "ORDER_COMPLETED": {
        readableStatus = "Order Completed";
        break;
      }
      default: {
        break;
      }
    }
    return readableStatus;
  }
}
