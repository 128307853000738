import * as yup from "yup";

const sonographer2ndTriFetalEvalSchema = yup
  .object()
  .shape({
    fetusPresentation: yup
      .string()
      .oneOf([
        "Cephalic",
        "Breech",
        "Transverse Right",
        "Variable",
        "Footing Breech",
        "No Fetus Present",
      ])
      .required("Fetus presentation is required"),
    placentaVisualized: yup
      .boolean()
      .default(false)
      .required("Placenta visualized is required"),
    placentalVisualization: yup
      .array()
      .default([])
      .when("placentaVisualized", {
        is: (value: boolean) => {
          return value === true;
        },
        then: yup.array().of(
          yup
            .mixed()
            .oneOf([
              "Anterior",
              "Posterior",
              "Right Lateral",
              "Left Lateral",
              "Fundal",
              "Low-Lying",
              "Marginal Previa",
              "Complete Previa",
            ])
            .required("Visualization selection is required")
            .test(
              "plV-test",
              "Please select at leat one visualization",
              (v) => {
                return v.length;
              }
            )
        ),
        otherwise: yup.array(),
      }),
    fetalHeartRate: yup
      .number()
      .typeError("This value must be a positive integer")
      .test(
        "fetalHeartRate-test",
        "This value must be a positive integer",
        (v) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Fetal heart rate is required"),
    amnioticFluidIndex: yup
      .number()
      .typeError("This value must be a positive integer")
      .test("afiAvg-test", "This value must be a positive integer", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Amniotic Fluid Index Average is required"),
    amnioticFluidIndexS1Q1: yup
      .number()
      .typeError("This value must be a positive integer")
      .test("afiS1Q1-test", "This value must be a positive integer", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Amniotic Fluid Index is required"),
    amnioticFluidIndexS1Q2: yup
      .number()
      .typeError("This value must be a positive integer")
      .test("afiS1Q2-test", "This value must be a positive integer", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Amniotic Fluid Index is required"),
    amnioticFluidIndexS1Q3: yup
      .number()
      .typeError("This value must be a positive integer")
      .test("afiS1Q3-test", "This value must be a positive integer", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Amniotic Fluid Index is required"),
    amnioticFluidIndexS1Q4: yup
      .number()
      .typeError("This value must be a positive integer")
      .test("afiS1Q4-test", "This value must be a positive integer", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Amniotic Fluid Index is required"),
    maxVerticalPocket: yup
      .number()
      .typeError("This value must be a positive integer")
      .test(
        "maxVerticalPocket-test",
        "This value must be a positive integer or null",
        (v) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Max Vertical Pocket is required"),
  })
  .required();

type Sonographer2ndTriFetalEvalType = yup.InferType<
  typeof sonographer2ndTriFetalEvalSchema
>;

export {
  sonographer2ndTriFetalEvalSchema as Sonographer2ndTriFetalEvalSchema,
  Sonographer2ndTriFetalEvalType,
};
