import * as yup from "yup";
import { sonographerDiagnosticsImpressionsSelections } from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographerEpV2ImpressionsDiagnosisSchema = yup
  .object()
  .shape({
    impressionEval: yup
      .string()
      .meta({
        control: "radio-list",
        label: "Impressions",
        options: sonographerDiagnosticsImpressionsSelections.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(sonographerDiagnosticsImpressionsSelections)
      .required("Impression is required"),
    impressionObs: yup.string().meta({
      control: "textarea",
    } as FieldMeta),
    impressionNotes: yup.string().meta({
      control: "textarea",
      label: "Impression Notes",
    } as FieldMeta),
    additionalNotes: yup.string().meta({
      control: "textarea",
      label: "Additional Notes",
    } as FieldMeta),
    officeVisit: yup.string().meta({
      control: "textarea",
      label: "Consultation",
    } as FieldMeta),
    followUp: yup.string().meta({
      control: "textarea",
      label: "Follow Up",
    } as FieldMeta),
  })
  .required();

type SonographerEpV2ImpressionsDiagnosisType = yup.InferType<
  typeof sonographerEpV2ImpressionsDiagnosisSchema
>;

export {
  sonographerEpV2ImpressionsDiagnosisSchema,
  SonographerEpV2ImpressionsDiagnosisType,
};
