export interface FullAnatomyTemplateType {
  sectionName: string;
  templates: AnatomyTemplate[];
}
export interface AnatomyTemplate {
  name: string;
  id: string;
  tool: string;
  mouseButton: number;
  templateCat: string;
  displayUnit?: string;
  reportLabel?: string;
  radioOptions?: { key: string; value: string }[];
}

export const DefaultRadioOptions = [
  { key: "Normal", value: "Normal" },
  { key: "Abnormal", value: "Abnormal" },
  { key: "Suboptimal", value: "Suboptimal" },
  { key: "Not Visualized", value: "Not Visualized" },
];

// TODO: Wherever possible, import radioOptions from template for anatomy into evaluation
//    sections, rather than mapping from sonographer constants - Andrew 1/23/24
export const fullAnatomyTemplate: FullAnatomyTemplateType[] = [
  {
    sectionName: "General Evaluation",
    templates: [
      {
        name: "Gestations",
        id: "gestationsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "Singleton", value: "Singleton" },
          { key: "Mono/Mono", value: "Mono/Mono" },
          { key: "Mono/Di", value: "Mono/Di" },
          { key: "Di/Di", value: "Di/Di" },
          { key: "Triplets", value: "Triplets" },
          { key: "4+", value: "4+" },
          { key: "Not Visualized", value: "Not Visualized" },
        ],
      },
      {
        name: "Embryo",
        id: "embryoVis",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "Visualized", value: "Visualized" },
          { key: "Not Visualized", value: "Not Visualized" },
        ],
      },
      {
        name: "Presentation",
        id: "fetusPresentation",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "Cephalic", value: "Cephalic" },
          { key: "Transverse Right", value: "Transverse Right" },
          { key: "Transverse Left", value: "Transverse Left" },
          { key: "Breech", value: "Breech" },
          { key: "Footing Breech", value: "Footing Breech" },
          { key: "Variable", value: "Variable" },
          { key: "No Fetus Present", value: "No Fetus Present" },
        ],
      },
      {
        name: "Cervix",
        id: "cervixObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        tool: "CustomLength",
        name: "CL",
        id: "cervixLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
        reportLabel: "Cervix Length",
      },
      {
        name: "Placenta",
        id: "placentaObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Placental Cord Insertion",
        id: "placentalCordInsertionObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "Central", value: "Central" },
          { key: "Marginal", value: "Marginal" },
          { key: "Velamentous", value: "Velamentous" },
          { key: "Not Visualized", value: "Not Visualized" },
        ],
      },
      {
        name: "Umbilical Cord",
        id: "umbCordObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "3 Vessel Cord", value: "3 Vessel Cord" },
          { key: "Single Umbilical Artery", value: "Single Umbilical Artery" },
          { key: "Not Visualized", value: "Not Visualized" },
        ],
      },
      {
        tool: "CustomLength",
        name: "PLAC to CVX",
        id: "placentaToCervix",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "MVP",
        id: "maxVerticalPocketLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "AFI 1",
        id: "amnioticFluidIndexS1Q1",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "AFI 2",
        id: "amnioticFluidIndexS1Q2",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "AFI 3",
        id: "amnioticFluidIndexS1Q3",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "AFI 4",
        id: "amnioticFluidIndexS1Q4",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Yolk Sac",
        id: "yolkSacObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        tool: "CustomLength",
        name: "YS",
        id: "yolkSacLength",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        name: "Amniotic Fluid",
        id: "afvObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "subjectively_low", value: "Subjectively Low" },
          { key: "subjectively_normal", value: "Subjectively Normal" },
          { key: "subjectively_high", value: "Subjectively High" },
          { key: "not_visualized", value: "Not Visualized" },
        ],
      },
    ],
  },
  {
    sectionName: "Maternal Structures",
    templates: [
      {
        name: "Uterus",
        id: "uterusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        tool: "CustomLength",
        id: "uterusLength",
        name: "UL",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "UH",
        id: "uterusHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "UW",
        id: "uterusWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Extrauterine Mass",
        id: "extrauterineMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "Present", value: "Present" },
          { key: "Not Present", value: "Not Present" },
        ],
      },
      {
        tool: "CustomLength",
        name: "EU MASS L",
        id: "extrauterineMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "EU MASS H",
        id: "extrauterineMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "EU MASS W",
        id: "extrauterineMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Right Ovary",
        id: "rightOvaryObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        tool: "CustomLength",
        name: "RT OV L",
        id: "rightOvaryLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RT OV H",
        id: "rightOvaryHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RT OV W",
        id: "rightOvaryWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Right Adnexal Mass",
        id: "rightAdnexalMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "Present", value: "Present" },
          { key: "Not Present", value: "Not Present" },
        ],
      },
      {
        tool: "CustomLength",
        name: "RA MASS L",
        id: "rightAdnexalMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RA MASS H",
        id: "rightAdnexalMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RA MASS W",
        id: "rightAdnexalMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Left Ovary",
        id: "leftOvaryObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        tool: "CustomLength",
        id: "leftOvaryLength",
        name: "LT OV L",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LT OV H",
        id: "leftOvaryHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LT OV W",
        id: "leftOvaryWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Left Adnexal Mass",
        id: "leftAdnexalMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "Present", value: "Present" },
          { key: "Not Present", value: "Not Present" },
        ],
      },
      {
        tool: "CustomLength",
        name: "LA MASS L",
        id: "leftAdnexalMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LA MASS H",
        id: "leftAdnexalMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LA MASS W",
        id: "leftAdnexalMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
    ],
  },
  {
    sectionName: "Biometry",
    templates: [
      {
        tool: "CustomLength",
        name: "CRL",
        id: "crownRumpLength",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        name: "Gestational Sac",
        id: "gestationalSacLocationObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "intrauterine", value: "Intrauterine" },
          { key: "irregular", value: "Irregular" },
          { key: "ectopic", value: "Ectopic" },
          { key: "notVisualized", value: "Not Visualized" },
        ],
      },
      {
        tool: "CustomLength",
        name: "GS",
        id: "gestationalSacDiameter",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "BPD",
        id: "biparietalDiameter",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomEllipticalRoi",
        name: "HC",
        id: "headCircumference",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomEllipticalRoi",
        name: "AC",
        id: "abdominalCircumference",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "FL",
        id: "femurLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "TIB",
        id: "tibiaLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "FIB",
        id: "fibulaLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "HL",
        id: "humerusLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "ULNA",
        id: "ulnaLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RAD",
        id: "radiusLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
    ],
  },
  {
    sectionName: "Brain",
    templates: [
      {
        name: "Posterior Fossa",
        id: "posteriorFossaObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        tool: "CustomLength",
        name: "Trans Cereb Diam",
        id: "cerebellarDiameter",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
        reportLabel: "Trans Cerebellar Diameter",
      },
      {
        tool: "CustomLength",
        name: "CM",
        id: "cisternaMagna",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
        reportLabel: "Cisterna Magna",
      },
      {
        tool: "CustomLength",
        name: "NT",
        id: "nuchalTranslucency",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
        reportLabel: "Nuchal Translucency",
      },
      {
        tool: "CustomLength",
        name: "NF",
        id: "nuchalFold",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
        reportLabel: "Nuchal Fold",
      },
      {
        tool: "CustomLength",
        name: "LV",
        id: "lateralVentricle",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
        reportLabel: "Lateral Ventricle",
      },
      {
        name: "RT Choroid Plexus",
        id: "rightChoroidPlexusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
        reportLabel: "Right Choroid Plexus",
      },
      {
        name: "LT Choroid Plexus",
        id: "leftChoroidPlexusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
        reportLabel: "Left Choroid Plexus",
      },
      {
        name: "Midline Falx",
        id: "midlineFalxObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Cavum Septi Pellucidi",
        id: "cavumSeptiPellucidiObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Cranium",
        id: "craniumObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Neck/Skin",
        id: "neckSkinObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
    ],
  },
  {
    sectionName: "Face",
    templates: [
      {
        name: "Profile",
        id: "profileObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Nasal Bone",
        id: "nasalBoneObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "Present", value: "Present" },
          { key: "Absent", value: "Absent" },
          { key: "Hypoplastic", value: "Hypoplastic" },
          { key: "Suboptimal", value: "Suboptimal" },
        ],
      },
      {
        name: "Nose/Lips",
        id: "noseLipsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Palate",
        id: "palateObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Orbits",
        id: "orbitsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
    ],
  },
  {
    sectionName: "Cardiac",
    templates: [
      {
        name: "Fetal Heart Rate",
        id: "fetalHeartRate",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "4 Chamber Heart",
        id: "heart4Obs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "LVOT",
        id: "lvotObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "RVOT",
        id: "rvotObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Thymus",
        id: "thymusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "3VV / 3VTV",
        id: "heart3Obs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Situs",
        id: "situsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Aortic Arch",
        id: "aorticObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Superior & Inferior Venae Cavae",
        id: "siVcObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Ductal Arch",
        id: "ductalArchObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Diaphragm",
        id: "diaphragmObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Cardiac Activity",
        id: "cardiacActivity",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "Absent", value: "Absent" },
          { key: "Present", value: "Present" },
        ],
      },
    ],
  },
  {
    sectionName: "Abdomen",
    templates: [
      {
        name: "Stomach",
        id: "stomachObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "RT Kidney",
        id: "rightKidneyObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "LT Kidney",
        id: "leftKidneyObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Bladder",
        id: "bladderObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Abdominal Cord Insert",
        id: "abdCordObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "Genitalia",
        id: "genitaliaObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
    ],
  },
  {
    sectionName: "Spine",
    templates: [
      {
        name: "C Spine",
        id: "cSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "T Spine",
        id: "tSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "L Spine",
        id: "lSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "S Spine",
        id: "sSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
    ],
  },
  {
    sectionName: "Extremities",
    templates: [
      {
        name: "RT Leg",
        id: "rightLegObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "RT Foot",
        id: "rightFootObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "RT Arm",
        id: "rightArmObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "RT Hand",
        id: "rightHandObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "LT Leg",
        id: "leftLegObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "LT Foot",
        id: "leftFootObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "LT Arm",
        id: "leftArmObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
      {
        name: "LT Hand",
        id: "leftHandObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: DefaultRadioOptions,
      },
    ],
  },
  {
    sectionName: "Biophysical Profile",
    templates: [
      {
        name: "Gross Body Movement",
        id: "grossBodyMovementObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "0", value: "0" },
          { key: "2", value: "2" },
        ],
      },
      {
        name: "Fetal Tone",
        id: "fetalToneObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "0", value: "0" },
          { key: "2", value: "2" },
        ],
      },
      {
        name: "Fetal Breathing",
        id: "fetalBreathingObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "0", value: "0" },
          { key: "2", value: "2" },
        ],
      },
      {
        name: "Amniotic Fluid Volume",
        id: "amnioticFluidVolumeObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
        radioOptions: [
          { key: "0", value: "0" },
          { key: "2", value: "2" },
        ],
      },
    ],
  },
];
