/**
 * An enum of available calculations.
 */
export enum CalculationName {
  SampleCalculation = "SampleCalculation",
  GestationalSacDiameter = "GestationalSacDiameter",
  BiparietalDiameter = "BiparietalDiameter",
  HeadCircumference = "HeadCircumference",
  AbdominalCircumference = "AbdominalCircumference",
  FemurLength = "FemurLength",
  CephalicIndex = "CephalicIndex",
  FlAcRatio = "FlAcRatio",
  FlBpdRatio = "FlBpdRatio",
  EstimatedFetalWeight = "EstimatedFetalWeight",
  HcAcRatio = "HcAcRatio",
  OccipitalFrontalDiameter = "OccipitalFrontalDiameter",
  TransCerebellarDiameter = "TransCerebellarDiameter",
  HumerusLength = "HumerusLength",
  CrownRumpLength = "CrownRumpLength",
  AverageUltrasoundAge = "AverageUltrasoundAge",
}
