import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, catchError, shareReplay } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { Clinic, ExamRoom } from "../../models";
import { environment } from "src/environments/environment";
import { AlertService } from "./alert.service";
import { RxjsService } from "./rxjs-service";

@Injectable({ providedIn: "root" })
export class InstitutionService {
  private baseUrl: string;

  constructor(
    private _http: HttpClient,
    private _rxjsService: RxjsService,
    private _alertService: AlertService
  ) {
    this.baseUrl = `${environment.bbApiUrl}${environment.institutionPort}`;
  }

  public msgOptions = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  public async getMachines(
    institutionId: string,
    clinicId: string
  ): Promise<ExamRoom[]> {
    switch (clinicId) {
      case "1": {
        return [
          {
            id: "1",
            name: "Lab",
            machineId: "88221c14-146c-4858-b61d-5b1632e8c6cf",
            machineType: "HARDWARE",
            clinicId: "1",
          },
        ];
      }
      default: {
        return [
          {
            id: "1",
            name: "Another Room",
            machineId: "88221c14-146c-4858-b61d-5b1632e8c6cf",
            machineType: "HARDWARE",
            clinicId: "1",
          },
        ];
      }
    }
  }

  getClinics(institutionId: string): Observable<Clinic[]> {
    const url = `${environment.bbApiUrl}${environment.institutionPort}/clinic/institution/${institutionId}`;
    return this._http.get(url).pipe(
      map((res: Clinic[]) => {
        return res;
      }),
      catchError((errorRes) => {
        this._alertService.error(
          `Error ${
            errorRes.statusCode ? errorRes.statusCode : ""
          }: An error was encountered, please refresh the page and try again.`,
          this.msgOptions
        );
        return throwError(errorRes);
      })
    );
  }

  getExamRooms(clinicId: string): Promise<ExamRoom[]> {
    const url = `${environment.bbApiUrl}${environment.institutionPort}/exam-room/clinic/${clinicId}`;
    return new Promise((resolve) => {
      this._http
        .get(url)
        .toPromise()
        .then((res: ExamRoom[]) => {
          return resolve(res);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          return throwError(error);
        });
    });
  }

  getExamRoomById(examRoomId: string): Promise<ExamRoom> {
    if (!examRoomId) return;
    const url = `${environment.bbApiUrl}${environment.institutionPort}/exam-room/${examRoomId}`;
    return new Promise((resolve) => {
      this._http
        .get(url)
        .toPromise()
        .then((res: ExamRoom) => {
          return resolve(res);
        })
        .catch((error) => {
          return throwError(error);
        });
    });
  }

  getInstitutionNameById(institutionId: string): Promise<string> {
    const url = `${environment.bbApiUrl}${environment.institutionPort}/institution/name/${institutionId}`;
    return new Promise((resolve, reject) => {
      this._http
        .get(url)
        .toPromise()
        .then((res: { name: string }) => {
          return resolve(res.name);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          reject(error);
        });
    });
  }

  //=============== Observables =================

  getInstitutionNameById$(institutionId: string): Observable<string> {
    const url = `${this.baseUrl}/institution/name/${institutionId}`;

    return this._http.get(url).pipe(
      shareReplay(1),
      catchError((err) => this._rxjsService.handleErrors(err)),
      map((data: any) => {
        return data.name;
      })
    );
  }
}
