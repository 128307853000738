import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";
import { BootstrapService } from "src/app/core/services/bootstrap.service";

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _bootstrapService: BootstrapService
  ) {}

  canActivate(): boolean {
    return this._authService.getSession().role === "ADMIN";
  }
}
