/* eslint-disable unicorn/prefer-spread */
import { Injectable } from "@angular/core";
import { UserTypes } from "src/app/models";
import { LocalStorageService } from "./local-storage.service";

export interface ParsedJWT {
  message: string;
  userId: string;
  institutionId: string;
  role?: UserTypes;
  iat: number;
  mfaRequired?: boolean;
  phonePreferred?: boolean;
  emailPreferred?: boolean;
  phoneNumber?: string;
  username?: string;
  ipaddress?: string;
  mfaCode?: string;
  tenant?: string;
}

const JWT_KEY = "jwt";

@Injectable({
  providedIn: "root",
})
export class JWTTokenService {
  jwtToken: string;
  decodedToken: ParsedJWT;

  constructor(private _localStorage: LocalStorageService) {}

  setToken(token: string): void {
    if (token) {
      this.jwtToken = token;
      this._localStorage.set(JWT_KEY, token);
    }
  }

  getToken(): string | null {
    return this._localStorage.get(JWT_KEY);
  }

  removeToken(): boolean {
    this._localStorage.remove(JWT_KEY);
    return true;
  }

  // TODO: Improve the architecture of this class. Josh 12/31/21
  getParsedToken(): null | ParsedJWT {
    return this.parseJwt(this._localStorage.get(JWT_KEY));
  }

  isTokenExpired(): boolean {
    return false;
  }

  parseJwt(token): ParsedJWT {
    if (!token) return;
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
}
/* eslint-enable unicorn/prefer-spread */
