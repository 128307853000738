import { Component, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "src/app/core/services/user.service";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["../../login/login/login.component.scss"],
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild("resetForm") resetForm: NgForm;

  buttonDisabled = false;
  buttonState = "";
  securityQuestion = { id: null, question: "" };
  securityAnswer = "";
  loadingSecurityQuestion = true;
  isWrongAnswer = false;
  isSuccessfulReset = false;
  canReset = false;
  invalidPassword = false;

  constructor(
    private _userService: UserService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    const token = this._route.snapshot.paramMap.get("token");
    this.loadingSecurityQuestion = true;
    this.securityQuestion =
      await this._userService.getSecurityQuestionForUser(token);
    this.loadingSecurityQuestion = false;
  }

  async onSubmit(): Promise<void> {
    try {
      if (!this.resetForm.valid || this.buttonDisabled) {
        return;
      }
      if (
        this.resetForm.value.response &&
        this.resetForm.value.response !== ""
      ) {
        this.securityAnswer = this.resetForm.value.response;
      }

      this.buttonDisabled = true;
      this.isWrongAnswer = false;
      // this.buttonState = "show-spinner";
      if (this.canReset && !this.validate()) {
        this.buttonDisabled = false;
        return;
      }

      const res = await this._userService.submitQuestionAnswer(
        this._route.snapshot.paramMap.get("token"),
        this.securityQuestion.id,
        this.resetForm.value.response || this.securityAnswer,
        this.resetForm.value.newPassword
      );
      this.buttonDisabled = false;
      if (!res || res === "false") {
        this.isWrongAnswer = true;
      } else if (this.canReset) {
        this.isSuccessfulReset = true;
        setTimeout(() => {
          this._router.navigate(["login"]);
        }, 5000);
      } else {
        this.canReset = true;
      }
    } catch (error) {
      console.log("Error in onSubmit", error);
    }
  }

  public validate(): boolean {
    // add validation for valid email and password strength here
    if (this.resetForm.value.newPassword.length < 7) {
      this.invalidPassword = true;
      return false;
    }
    const lowercase = /[a-z]/.test(this.resetForm.value.newPassword) ? 1 : 0;
    const uppercase = /[A-Z]/.test(this.resetForm.value.newPassword) ? 1 : 0;
    const number = /\d/.test(this.resetForm.value.newPassword) ? 1 : 0;
    const specialChars = /[!"#$%&'()*+,./:;<=>?@[\\\]^_`{|}~-]/;
    const specialChar = specialChars.test(this.resetForm.value.newPassword)
      ? 1
      : 0;
    const totalCount = lowercase + uppercase + number + specialChar;

    if (totalCount >= 3) {
      this.invalidPassword = false;
      return true;
    }
    this.invalidPassword = true;
    return false;
  }
}
