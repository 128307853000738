import * as yup from "yup";
import {
  sonographerFetalAnatomyViewLimitations,
  sonographerImpressionsSelections,
} from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographer1stTriV2ImpressionsDiagnosisSchema = yup
  .object()
  .shape({
    impressionEval: yup
      .string()
      .meta({
        control: "radio-list",
        options: sonographerImpressionsSelections.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(sonographerImpressionsSelections)
      .required("Impression is required"),
    impressionObs: yup.string().meta({
      control: "textarea",
    } as FieldMeta),
    impressionNotes: yup.string().meta({
      control: "textarea",
      label: "Impressions Notes",
    } as FieldMeta),
    additionalNotes: yup.string().meta({
      control: "textarea",
      label: "Additional Notes",
    } as FieldMeta),
    viewLimitations: yup
      .array()
      .of(yup.mixed().oneOf(sonographerFetalAnatomyViewLimitations))
      .meta({
        control: "checkbox-list",
        label: "View Limitations",
        options: sonographerFetalAnatomyViewLimitations.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .default([]),
    officeVisit: yup.string().meta({
      control: "textarea",
      label: "Consultation",
    } as FieldMeta),
    followUp: yup.string().meta({
      control: "textarea",
      label: "Follow Up",
    } as FieldMeta),
  })
  .required();

type Sonographer1stTriV2ImpressionsDiagnosisType = yup.InferType<
  typeof sonographer1stTriV2ImpressionsDiagnosisSchema
>;

export {
  sonographer1stTriV2ImpressionsDiagnosisSchema,
  Sonographer1stTriV2ImpressionsDiagnosisType,
};
