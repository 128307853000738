import { Injectable } from "@angular/core";
import { fromEvent, Subject } from "rxjs";
import { JWTTokenService } from "./jwt.service";

@Injectable({
  providedIn: "root",
})
export class IdleService {
  public idle$: Subject<boolean> = new Subject();
  public wake$: Subject<boolean> = new Subject();

  isIdle = false;
  public idleAfterSeconds =
    this._jwtService.getParsedToken()?.role?.toLowerCase() === "driver"
      ? 3600
      : 1200;

  public countDown;

  constructor(private _jwtService: JWTTokenService) {
    // Setup events
    fromEvent(document, "mousemove").subscribe(() => this.onInteraction());
    fromEvent(document, "touchstart").subscribe(() => this.onInteraction());
    fromEvent(document, "keydown").subscribe(() => this.onInteraction());
    fromEvent(document, "scroll").subscribe(() => this.onInteraction());
    fromEvent(window, "scroll").subscribe(() => this.onInteraction());
  }

  onInteraction(): void {
    // Is idle and interacting, emit Wake
    if (this.isIdle) {
      this.isIdle = false;
      this.wake$.next(true);
    }

    // User interaction, reset start-idle-timer
    clearTimeout(this.countDown);
    this.countDown = setTimeout(() => {
      // Countdown done without interaction - emit Idle
      this.isIdle = true;
      this.idle$.next(true);
    }, this.idleAfterSeconds * 1000);
  }
}
