import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { JWTTokenService } from "src/app/core/services/jwt.service";
import { AuthService } from "src/app/core/services/auth.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private _jwtService: JWTTokenService,
    private _authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add auth header with jwt if valid
    const token = this._jwtService.getToken();
    const isValidToken = this._authService.hasValidToken();
    const isS3Request = request.url.startsWith("https://dicom-etl-processed"); // Signed url requests to S3 should not have an auth header
    if (isValidToken && !isS3Request) {
      request = request.clone({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }

    return next.handle(request);
  }
}
