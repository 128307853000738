import { Directive, ElementRef, HostListener, OnInit } from "@angular/core";

@Directive({
  selector: "[phoneFormat]",
})
export class PhoneFormatDirective implements OnInit {
  constructor(private _elementRef: ElementRef) {}

  ngOnInit(): void {
    this._elementRef.nativeElement.value = this.transform(
      this._elementRef.nativeElement.value
    );
  }

  transform(value: string): string {
    if (!value) return "";
    const trim = value.replace(/\D/g, "").slice(0, 10); // Remove non-digits and trim
    const matched = trim.match(/(\d{0,3})(\d{0,3})(\d{0,4})/); // Match chunks of phone number
    return !matched[2]
      ? matched[1]
      : `(${matched[1]}) ${matched[2]}${matched[3] ? `-${matched[3]}` : ""}`; // Format
  }

  @HostListener("keyup", ["$event.target.value"])
  change(): void {
    this._elementRef.nativeElement.value = this.transform(
      this._elementRef.nativeElement.value
    );
  }
}
