import { PipeTransform, Pipe } from "@angular/core";
import { DataNotEnteredPipe } from "./data-not-entered.pipe";

@Pipe({ name: "feetAndInches" })
export class FeetAndInchesPipe implements PipeTransform {
  transform(totalInches: number): string {
    if (
      !totalInches ||
      totalInches.toString() === DataNotEnteredPipe.notEnteredMarker
    )
      return DataNotEnteredPipe.notEnteredMarker;
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;
    return `${feet} feet, ${inches} inches`;
  }
}
