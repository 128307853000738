import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import { environment } from "src/environments/environment";

import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GAService {
  analytics: any;

  constructor() {
    this.analytics = Analytics({
      plugins: [
        googleAnalytics({
          measurementIds: [environment.gaMeasurementId],
        }),
      ],
    });
  }

  trackEvent(event, category, label): void {
    if (event) {
      this.analytics.track(event, {
        ga_label: label,
        ga_category: category,
        userId: this.analytics.user("userId"),
        user_role: this.analytics.user("traits.userRole"),
      });
    }
  }

  pageView(title): void {
    if (title) {
      this.analytics.page({
        title,
      });
    }
  }

  setUser(userId, userRole): void {
    if (userId && userRole) {
      this.analytics.identify(userId, {
        userRole,
      });
    }
  }

  resetUser(): void {
    this.analytics.reset();
  }
}
