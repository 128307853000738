// REPORT VIEW MODELS
import { Patient } from "src/app/models";
import * as moment from "moment-timezone"; // old date library
// import dayjs from "dayjs"; // new date library
// import AdvancedFormat from "dayjs/plugin/advancedFormat";
import {
  EchoIndications,
  UltrasoundMethod,
  VisitType,
} from "src/app/global/enums/enums";

export interface AllSectionsReportModel {
  stomachObs_radio: string; // stages: 1st, 2nd, 3rd
  stomachObs_comment: string; // stages: 1st, 2nd, 3rd
  kidneysObs_radio: string; // stages: 2nd, 3rd
  kidneysObs_comment: string; // stages: 2nd, 3rd
  bladderObs_radio: string; // stages: 1st, 2nd, 3rd
  bladderObs_comment: string; // stages: 1st, 2nd, 3rd
  abdCordObs_radio: string; // stages: 1st, 2nd, 3rd
  abdCordObs_comment: string; // stages: 1st, 2nd, 3rd
  umbCordObs_radio: string; // stages: 2nd, 3rd
  umbCordObs_comment: string; // stages: 2nd, 3rd
  fetalHeartRate: string; // stages: 1st, 2nd, 3rd
  heart4Obs_radio: string; // stages: 1st, 2nd, 3rd
  heart4Obs_comment: string; // stages: 1st, 2nd, 3rd
  lvotObs_radio: string; // stages: 1st, 2nd, 3rd
  lvotObs_comment: string; // stages: 1st, 2nd, 3rd
  rvotObs_radio: string; // stages: 1st, 2nd, 3rd
  rvotObs_comment: string; // stages: 1st, 2nd, 3rd
  heart3Obs_radio: string; // stages: 1st, 2nd, 3rd
  heart3Obs_comment: string; // stages: 1st, 2nd, 3rd
  situsObs_radio: string; // stages: 1st, 2nd, 3rd
  situsObs_comment: string; // stages: 1st, 2nd, 3rd
  aorticObs_radio: string; // stages: 2nd, 3rd
  aorticObs_comment: string; // stages: 2nd, 3rd
  siVcObs_radio: string; // stages: 2nd, 3rd
  siVcObs_comment: string; // stages: 2nd, 3rd
  ductalArchObs_radio: string; // stages: 2nd, 3rd
  ductalArchObs_comment: string; // stages: 2nd, 3rd
  diaphragmObs_radio: string; // stages: 2nd, 3rd
  diaphragmObs_comment: string; // stages: 2nd, 3rd
  impressionEval: string; // stages: ep, 1st, 2nd, 3rd
  impressionObs_comment: string; // stages: ep, 1st, 2nd, 3rd
  impressionsNotes: string; // stages: ep, 1st, 2nd, 3rd
  fetalHeartRate_radio: string; // stages: ep, 1st, 2nd, 3rd
  fetalHeartRate_comment: string; // stages: ep, 1st, 2nd, 3rd
  additionalNotes: string; // stages: ep, 1st, 2nd, 3rd
  viewLimitations: string; // stages: 1st, 2nd, 3rd  ENUM of viewlimitations var?
  officeVisit: string; // stages: ep, 1st, 2nd, 3rd
  followUp: string; // stages: ep, 1st, 2nd, 3rd
  estimatedFetalAge: string; // STAGES: ep, 1st, 2nd, 3rd
  estimatedFetalWeight: string; // stages: 1st, 2nd, 3rd
  estimatedFetalWeightCalcPerc: string; // stages: 1st, 2nd, 3rd
  biparietalDiameter: string; // stages: 1st, 2nd, 3rd
  biparietalDiameterCalcPerc: string; // stages: 1st, 2nd, 3rd
  biparietalDiameterCalcGestAge: string; // stages: 1st, 2nd, 3rd
  headCircumference: string; // stages: 1st, 2nd, 3rd
  headCircumferenceCalcPerc: string; // stages 1st, 2nd, 3rd
  headCircumferenceCalcGestAge: string; // stages: 1st, 2nd, 3rd
  occipitalFrontDiameterCalc: string; // stages: 1st, 2nd, 3rd
  cephalicIndexCalc: string; // stages: 1st, 2nd, 3rd
  cephalicIndexRange: string; // stages: 1st, 2nd, 3rd
  abdominalCircumference: string; // stages: 1st, 2nd, 3rd
  abdominalCircumferenceCalcPerc: string; // stages: 1st, 2nd, 3rd
  abdominalCircumferenceCalcGestAge: string; // stages: 1st, 2nd, 3rd
  hcAcRatioCalc: string; // stages: 1st, 2nd, 3rd
  hcAcRatioRange: string; // stages: 1st, 2nd, 3rd
  femurLength: string; // stages: 1st, 2nd, 3rd
  femurLengthCalcPerc: string; // stages: 1st, 2nd, 3rd
  femurLengthCalcGestAge: string; // stages: 1st, 2nd, 3rd
  flBpdRatioCalc: string; // stages: 1st, 2nd, 3rd
  flBpdRatioRange: string; // stages: 1st, 2nd, 3rd
  flAcRatioCalc: string; // stages: 1st, 2nd, 3rd
  flAcRatioRange: string; // stages: 1st, 2nd, 3rd
  humerusLength: string; // stages: 1st, 2nd, 3rd
  humerusLengthCalcPerc: string; // stages: 1st, 2nd, 3rd
  humerusLengthCalcGestAge: string; // stages: 1st, 2nd, 3rd
  crownRumpLength: string; // stages: ep, 1st
  crownRumpLengthCalcGestAge: string; // stages: ep, 1st
  crownRumpLengthCalcPerc: string; // stages: ep, 1st
  gestationalSacDiameter: string; // stages: ep, 1st
  gestationalSacDiameterCalcGestAge: string; // stages: ep, 1st
  gestationalSacDiameterCalcPerc: string; // stages: ep, 1st
  bestOverallAssessment: string; // stages: ep
  bestGestationAgeWeeks: string; // stages: ep
  bestGestationAgeDays: string; // stages: ep
  cervixObs_radio: string; // stages: ep, 1st, 2nd, 3rd
  cervixObs_comment: string; // stages: ep, 1st, 2nd, 3rd
  cervixLength: string; // stages: ep, 1st, 2nd, 3rd
  fetusPregnancy: string; // stages: ep, 1st
  cardiacActivity: string; // stages: ep, 1st
  cardiacActivity_radio: string; // stages: ep, 1st
  cardiacActivity_comment: string; // stages: ep, 1st
  gestationalSacLocationObs_radio: string;
  gestationalSacLocationObs_comment: string;
  // gestationalSacLocation: string; // stages: ep, 1st
  embryoVis: string; // stages: ep, 1st
  yolkSacObs_radio: string; // stages: 1st
  yolkSacObs_comment: string; // stages 1st
  yolkSacLength: string;
  yolkSacVis: string; // stages: ep, 1st
  yolkSacEval: string; // stages: ep, 1st
  placentaObs_radio: string;
  placentaObs_comment: string;
  placentaVisualized: string; // stages: 1st, 2nd
  placentalVisualization: string; // stages 1st, 2nd
  fetusPresentation: string; // stages: 1st, 2nd
  placentaToCervix: string; // stages: 2nd, 3rd
  placentalCordInsertionObs_radio: string;
  placentalCordInsertionObs_comment: string;
  placentalGrade: string;
  afvObs: string; // stages: 2nd, 3rd
  maxVerticalPocketLength: string; // stages: 2nd, 3rd
  bpp: string; // stages: 3rd
  grossBodyMvmt: string; // stages: 3rd
  fetalPosition: string; // stages: 1st
  fetalTone: string; // stages: 3rd
  fetalBreathing: string; // stages: 3rd
  amnioticFluidVol: string; // stages: 3rd
  amnioticFluidIndexS1Q1: string; // stages: 3rd
  amnioticFluidIndexS1Q2: string; // stages: 3rd
  amnioticFluidIndexS1Q3: string; // stages: 3rd
  amnioticFluidIndexS1Q4: string; // stages: 3rd
  sexEval_radio: string; // stages: 1st, 2nd, 3rd
  genitaliaObs_radio: string; // stages: 1st, 2nd, 3rd
  genitaliaObs_comment: string; // stages: 1st, 2nd, 3rd
  nuchalTranslucency: string; // stages: 1st
  cavumSeptiPellucidiObs_radio: string; // stages: 2nd, 3rd
  cavumSeptiPellucidiObs_comment: string; // stages: 2nd, 3rd
  craniumObs_radio: string; // stages: 1st, 2nd, 3rd normal, abnormal, suboptimal
  craniumObs_comment: string; // stages: 1st, 2nd, 3rd
  cerebellarDiameter: string; // stages: 2nd, 3rd
  cerebellarDiameterCalcPerc: string; // stages: 2nd, 3rd
  cerebellarDiameterCalcGestAge: string; // stages: 2nd, 3rd
  cisternaMagna: string; // stages: 2nd
  nuchalFold: string; // stages: 2nd
  lateralVentricle: string; // stages: 2nd, 3rd
  choroidPlexusObs_radio: string; // stages: 2nd, 3rd
  choroidPlexusObs_comment: string; // stages: 2nd, 3rd
  midlineFalxObs_radio: string; // stages: 2nd, 3rd
  midlineFalxObs_comment: string; // stages: 2nd, 3rd
  neckSkinObs_radio: string; // stages: 2nd, 3rd
  neckSkinObs_comment: string; // stages: 2nd, 3rd
  profileObs_radio: string; // stages: 2nd, 3rd
  profileObs_comment: string; // stages: 2nd, 3rd
  nasalBoneObs_radio: string; // stages: 1st, 2nd, 3rd
  nasalBoneObs_comment: string; // stages: 1st, 2nd, 3rd
  noseLipsObs_radio: string; // stages: 2nd, 3rd
  noseLipsObs_comment: string; // stages: 2nd, 3rd
  palateObs_radio: string; // stages: 2nd, 3rd
  palateObs_comment: string; // stages: 2nd, 3rd
  orbitsObs_radio: string; // stages: 2nd, 3rd
  orbitsObs_comment: string; // stages: 2nd, 3rd
  uterusObs_radio: string; // stages: ep, 1st, 2nd
  uterusObs_comment: string; // stages: ep, 1st, 2nd
  uterusLength: string; // stages: ep, 1st, 2nd
  uterusHeight: string; // stages: ep, 1st, 2nd
  uterusWidth: string; // stages: ep, 1st, 2nd
  extrauterineMassObs_radio: string; // stages: ep, 1st, 2nd, 3rd
  extrauterineMassObs_comment: string; // stages: ep, 1st, 2nd, 3rd
  extrauterineMassLength: string; // stages: ep, 1st, 2nd, 3rd
  extrauterineMassHeight: string; // stages: ep, 1st, 2nd, 3rd
  extrauterineMassWidth: string; // stages: ep, 1st, 2nd, 3rd
  rightOvaryObs_radio: string; // stages: ep, 1st, 2nd, 3rd
  rightOvaryObs_comment: string; // stages: ep, 1st, 2nd, 3rd
  rightOvaryLength: string; // stages: ep, 1st, 2nd, 3rd
  rightOvaryHeight: string; // stages: ep, 1st, 2nd, 3rd
  rightOvaryWidth: string; // stages: ep, 1st, 2nd, 3rd
  rightAdnexalMassObs_radio: string; // stages: ep, 1st, 2nd, 3rd
  rightAdnexalMassObs_comment: string; // stages: ep, 1st, 2nd, 3rd
  rightAdnexalMassLength: string; // stages: ep, 1st, 2nd, 3rd
  rightAdnexalMassHeight: string; // stages: ep, 1st, 2nd, 3rd
  rightAdnexalMassWidth: string; // stages: ep, 1st, 2nd, 3rd
  leftOvaryObs_radio: string; // stages: ep, 1st, 2nd, 3rd
  leftOvaryObs_comment: string; // stages: ep, 1st, 2nd, 3rd
  leftOvaryLength: string; // stages: ep, 1st, 2nd, 3rd
  leftOvaryHeight: string; // stages: ep, 1st, 2nd, 3rd
  leftOvaryWidth: string; // stages: ep, 1st, 2nd, 3rd
  leftAdnexalMassObs_radio: string; // stages: ep, 1st, 2nd, 3rd
  leftAdnexalMassObs_comment: string; // stages: ep, 1st, 2nd, 3rd
  leftAdnexalMassLength: string; // stages: ep, 1st, 2nd, 3rd
  leftAdnexalMassHeight: string; // stages: ep, 1st, 2nd, 3rd
  leftAdnexalMassWidth: string; // stages: ep, 1st, 2nd, 3rd
  spineObs_radio: string; // stages: 1st, 2nd, 3rd
  spineObs_comment: string; // stages: 1st, 2nd, 3rd
  legsObs_radio: string; // stages: 1st, 2nd, 3rd
  legsObs_comment: string; // stages: 1st, 2nd, 3rd
  armsObs_radio: string; // stages: 1st, 2nd, 3rd
  armsObs_comment: string; // stages: 1st, 2nd, 3rd
  handsObs_radio: string; // stages: 1st, 3rd
  handsObs_comment: string; // stages: 1st, 2nd, 3rd
  feetObs_radio: string; // stages: 1st, 2nd, 3rd
  feetObs_comment: string; // stages: 1st, 2nd, 3rd
  gestationsObs_radio: string; // stages: 1st, 2nd, 3rd
  gestationsObs_comment: string; // stages: 1st, 2nd, 3rd
  physicianFullName: string; // final report
  finalizedOn: string; // final report
  grossBodyMovementObs_value: number; // stages: 2nd, 3rd
  amnioticFluidVolumeObs_value: number; // stages: 2nd, 3rd
  fetalBreathingObs_value: number; // stages: 2nd, 3rd
  fetalToneObs_value: number; // stages: 2nd, 3rd
}

export const AnnotationFilterList: AnnotationFilterListType[] = [
  {
    label: "General",
    checked: false,
    options: [
      {
        name: "Gestations",
        id: "gestations",
        checked: false,
      },
      {
        name: "Placenta",
        id: "placenta",
        checked: false,
      },
      {
        name: "Embryo",
        id: "embryo",
        checked: false,
      },
      // {
      //   name: "Fetal Position",
      //   id: "fetal_position",
      //   checked: false,
      // },
      {
        name: "Presentation",
        id: "fetus_presentation",
        checked: false,
      },
      {
        name: "Cervix",
        id: "cervix",
        checked: false,
      },
      {
        name: "Placental Cord Insertion",
        id: "placental_cord_insertion",
        checked: false,
      },
      {
        name: "Umbilical Cord",
        id: "umbilical_cord",
        checked: false,
      },
      {
        name: "PLACToCVX",
        id: "plac_to_cvx",
        checked: false,
      },
      {
        name: "MVP",
        id: "mvp",
        checked: false,
      },
      {
        name: "Amniotic Fluid",
        id: "amniotic_fluid",
        checked: false,
      },
      {
        name: "Yolk Sac",
        id: "yolk_sac",
        checked: false,
      },
      {
        name: "AFI",
        id: "afi",
        checked: false,
      },
    ],
  },
  {
    label: "Maternal",
    checked: false,
    options: [
      {
        name: "Uterus",
        id: "uterus",
        checked: false,
      },
      {
        name: "LT/RT Ovary",
        id: "ovary",
        checked: false,
      },
      {
        name: "EXT UT Mass",
        id: "ext_ut_mass",
        checked: false,
      },
      {
        name: "LT/RT AD Mass",
        id: "ad_masses",
        checked: false,
      },
    ],
  },
  {
    label: "Biometry",
    checked: false,
    options: [
      {
        name: "BPD",
        id: "bpd",
        checked: false,
      },
      {
        name: "Crown Rump Length",
        id: "crown_rump_length",
        checked: false,
      },
      {
        name: "Gestational Sac",
        id: "gestational_sac",
        checked: false,
      },
      {
        name: "HC",
        id: "hc",
        checked: false,
      },
      {
        name: "AC",
        id: "ac",
        checked: false,
      },
      {
        name: "FL",
        id: "fl",
        checked: false,
      },
      {
        name: "TL",
        id: "tl",
        checked: false,
      },
      {
        name: "FiL",
        id: "FiL",
        checked: false,
      },
      {
        name: "HL",
        id: "hl",
        checked: false,
      },
      {
        name: "UL",
        id: "ul",
        checked: false,
      },
      {
        name: "RL",
        id: "rl",
        checked: false,
      },
    ],
  },
  {
    label: "Brain",
    checked: false,
    options: [
      {
        name: "Posterior Fossa",
        id: "posterior_fossa",
        checked: false,
      },
      {
        name: "Trans Cereb Diam",
        id: "trans_cereb_diam",
        checked: false,
      },
      {
        name: "Cisterna Magna",
        id: "cisterna_magna",
        checked: false,
      },
      {
        name: "Nuchal Fold",
        id: "nuchal_fold",
        checked: false,
      },
      {
        name: "Nuchal Translucency",
        id: "nuchal_translucency",
        checked: false,
      },
      {
        name: "Lateral Ventricle",
        id: "lateral_ventricle",
        checked: false,
      },
      {
        name: "RT/LT Choroid Plexus",
        id: "choroid_plexus",
        checked: false,
      },
      {
        name: "Midline Falx",
        id: "midline_falx",
        checked: false,
      },
      {
        name: "Cavum Septi Pellucidi",
        id: "cavum_septi_pellucidi",
        checked: false,
      },
      {
        name: "Cranium",
        id: "cranium",
        checked: false,
      },
      {
        name: "Neck/Skin",
        id: "neck-skin",
        checked: false,
      },
    ],
  },
  {
    label: "Face",
    checked: false,
    options: [
      {
        name: "Profile",
        id: "profile",
        checked: false,
      },
      {
        name: "Nasal Bone",
        id: "nasal_bone",
        checked: false,
      },
      {
        name: "Nose/Lips",
        id: "nose-lips",
        checked: false,
      },
      {
        name: "Palate",
        id: "palate",
        checked: false,
      },
      {
        name: "Orbits",
        id: "orbits",
        checked: false,
      },
    ],
  },
  {
    label: "Cardiac",
    checked: false,
    options: [
      {
        name: "Cardiac Activity",
        id: "cardiac_activity",
        checked: false,
      },
      {
        name: "Fetal Heart Rate",
        id: "fetal_heart_rate",
        checked: false,
      },
      {
        name: "4 Chamber Heart",
        id: "4_chamber_heart",
        checked: false,
      },
      {
        name: "LVOT",
        id: "LVOT",
        checked: false,
      },
      {
        name: "RVOT",
        id: "RVOT",
        checked: false,
      },
      {
        name: "3VV/3VTV",
        id: "3VV-3VTV",
        checked: false,
      },
      {
        name: "Situs",
        id: "situs",
        checked: false,
      },
      {
        name: "Aortic Arch",
        id: "aortic_arch",
        checked: false,
      },
      {
        name: "SUP & INF Venae Cavae",
        id: "sup_&_inf_venae_cavae",
        checked: false,
      },
      {
        name: "Ductal Arch",
        id: "ductal_arch",
        checked: false,
      },
      {
        name: "Diaphragm",
        id: "diaphragm",
        checked: false,
      },
      {
        name: "Thymus",
        id: "thymus",
        checked: false,
      },
    ],
  },
  {
    label: "Abdomen",
    checked: false,
    options: [
      {
        name: "Stomach",
        id: "stomach",
        checked: false,
      },
      {
        name: "LT/RT Kidney",
        id: "kidney",
        checked: false,
      },
      {
        name: "Bladder",
        id: "bladder",
        checked: false,
      },
      {
        name: "Abdominal Cord Insert",
        id: "abdominal_cord_insert",
        checked: false,
      },
      {
        name: "Genitalia",
        id: "genitalia",
        checked: false,
      },
    ],
  },
  {
    label: "Spine",
    checked: false,
    options: [
      {
        name: "C/T/L/S Spine",
        id: "spine",
        checked: false,
      },
    ],
  },
  {
    label: "Extremities",
    checked: false,
    options: [
      {
        name: "LT/RT Leg",
        id: "leg",
        checked: false,
      },
      {
        name: "LT/RT Foot",
        id: "foot",
        checked: false,
      },
      {
        name: "LT/RT Arm",
        id: "arm",
        checked: false,
      },
      {
        name: "LT/RT Hand",
        id: "hand",
        checked: false,
      },
    ],
  },
  {
    label: "BPP",
    checked: false,
    options: [
      {
        name: "Gross Body Movement",
        id: "gross_body_movement",
        checked: false,
      },
      {
        name: "Fetal Tone",
        id: "fetal_tone",
        checked: false,
      },
      {
        name: "Fetal Breathing",
        id: "fetal_breathing",
        checked: false,
      },
      {
        name: "Amniotic Fluid Volume",
        id: "amniotic_fluid_volume",
        checked: false,
      },
    ],
  },
];

export interface AnnotationFilterListType {
  label: string;
  checked: boolean;
  options: {
    name: string;
    id: string;
    checked: boolean;
  }[];
}

export const newReportModel = {
  stomachObs_radio: null, // stages: 1st, 2nd, 3rd
  stomachObs_comment: null, // stages: 1st, 2nd, 3rd
  kidneysObs_radio: null, // stages: 2nd, 3rd
  kidneysObs_comment: null, // stages: 2nd, 3rd
  bladderObs_radio: null, // stages: 1st, 2nd, 3rd
  bladderObs_comment: null, // stages: 1st, 2nd, 3rd
  abdCordObs_radio: null, // stages: 1st, 2nd, 3rd
  abdCordObs_comment: null, // stages: 1st, 2nd, 3rd
  umbCordObs_radio: null, // stages: 2nd, 3rd
  umbCordObs_comment: null, // stages: 2nd, 3rd
  fetalHeartRate: null, // stages: 1st, 2nd, 3rd
  heart4Obs_radio: null, // stages: 1st, 2nd, 3rd
  heart4Obs_comment: null, // stages: 1st, 2nd, 3rd
  lvotObs_radio: null, // stages: 1st, 2nd, 3rd
  lvotObs_comment: null, // stages: 1st, 2nd, 3rd
  rvotObs_radio: null, // stages: 1st, 2nd, 3rd
  rvotObs_comment: null, // stages: 1st, 2nd, 3rd
  heart3Obs_radio: null, // stages: 1st, 2nd, 3rd
  heart3Obs_comment: null, // stages: 1st, 2nd, 3rd
  situsObs_radio: null, // stages: 1st, 2nd, 3rd
  situsObs_comment: null, // stages: 1st, 2nd, 3rd
  aorticObs_radio: null, // stages: 2nd, 3rd
  aorticObs_comment: null, // stages: 2nd, 3rd
  siVcObs_radio: null, // stages: 2nd, 3rd
  siVcObs_comment: null, // stages: 2nd, 3rd
  ductalArchObs_radio: null, // stages: 2nd, 3rd
  ductalArchObs_comment: null, // stages: 2nd, 3rd
  diaphragmObs_radio: null, // stages: 2nd, 3rd
  diaphragmObs_comment: null, // stages: 2nd, 3rd
  impressionEval: null, // stages: ep, 1st, 2nd, 3rd
  impressionObs_comment: null, // stages: ep, 1st, 2nd, 3rd
  impressionsNotes: null, // stages: ep, 1st, 2nd, 3rd
  fetalHeartRate_radio: null, // stages: ep, 1st, 2nd, 3rd
  fetalHeartRate_comment: null, // stages: ep, 1st, 2nd, 3rd
  additionalNotes: null, // stages: ep, 1st, 2nd, 3rd
  viewLimitations: null, // stages: 1st, 2nd, 3rd  ENUM of viewlimitations var?
  officeVisit: null, // stages: ep, 1st, 2nd, 3rd
  followUp: null, // stages: ep, 1st, 2nd, 3rd
  estimatedFetalAge: null, // STAGES: ep, 1st, 2nd, 3rd
  estimatedFetalWeight: null, // stages: 1st, 2nd, 3rd
  estimatedFetalWeightCalcPerc: null, // stages: 1st, 2nd, 3rd
  biparietalDiameter: null, // stages: 1st, 2nd, 3rd
  biparietalDiameterCalcPerc: null, // stages: 1st, 2nd, 3rd
  biparietalDiameterCalcGestAge: null, // stages: 1st, 2nd, 3rd
  headCircumference: null, // stages: 1st, 2nd, 3rd
  headCircumferenceCalcPerc: null, // stages 1st, 2nd, 3rd
  headCircumferenceCalcGestAge: null, // stages: 1st, 2nd, 3rd
  occipitalFrontDiameterCalc: null, // stages: 1st, 2nd, 3rd
  cephalicIndexCalc: null, // stages: 1st, 2nd, 3rd
  cephalicIndexRange: null, // stages: 1st, 2nd, 3rd
  abdominalCircumference: null, // stages: 1st, 2nd, 3rd
  abdominalCircumferenceCalcPerc: null, // stages: 1st, 2nd, 3rd
  abdominalCircumferenceCalcGestAge: null, // stages: 1st, 2nd, 3rd
  hcAcRatioCalc: null, // stages: 1st, 2nd, 3rd
  hcAcRatioRange: null, // stages: 1st, 2nd, 3rd
  femurLength: null, // stages: 1st, 2nd, 3rd
  femurLengthCalcPerc: null, // stages: 1st, 2nd, 3rd
  femurLengthCalcGestAge: null, // stages: 1st, 2nd, 3rd
  flBpdRatioCalc: null, // stages: 1st, 2nd, 3rd
  flBpdRatioRange: null, // stages: 1st, 2nd, 3rd
  flAcRatioCalc: null, // stages: 1st, 2nd, 3rd
  flAcRatioRange: null, // stages: 1st, 2nd, 3rd
  humerusLength: null, // stages: 1st, 2nd, 3rd
  humerusLengthCalcPerc: null, // stages: 1st, 2nd, 3rd
  humerusLengthCalcGestAge: null, // stages: 1st, 2nd, 3rd
  crownRumpLength: null, // stages: ep, 1st
  crownRumpLengthCalcGestAge: null, // stages: ep, 1st
  crownRumpLengthCalcPerc: null, // stages: ep, 1st
  gestationalSacDiameter: null, // stages: ep, 1st
  gestationalSacDiameterCalcGestAge: null, // stages: ep, 1st
  gestationalSacDiameterCalcPerc: null, // stages: ep, 1st
  bestOverallAssessment: null, // stages: ep
  bestGestationAgeWeeks: null, // stages: ep
  bestGestationAgeDays: null, // stages: ep
  cervixObs_radio: null, // stages: ep, 1st, 2nd, 3rd
  cervixObs_comment: null, // stages: ep, 1st, 2nd, 3rd
  cervixLength: null, // stages: ep, 1st, 2nd, 3rd
  fetusPregnancy: null, // stages: ep, 1st
  cardiacActivity: null, // stages: ep, 1st
  cardiacActivity_radio: null, // stages: ep, 1st
  cardiacActivity_comment: null, // stages: ep, 1st
  gestationalSacLocationObs_radio: null, // stages: ep, 1st,
  gestationalSacLocationObs_comment: null,
  embryoVis: null, // stages: ep, 1st
  yolkSacLength: null,
  yolkSacObs_radio: null,
  yolkSacObs_comment: null,
  yolkSacVis: null, // stages: ep, 1st
  yolkSacEval: null, // stages: ep, 1st
  placentaObs_radio: null,
  placentaObs_comment: null,
  placentaVisualized: null, // stages: 1st, 2nd
  placentalVisualization: null, // stages 1st, 2nd
  fetusPresentation: null, // stages: 1st, 2nd
  placentaToCervix: null, // stages: 2nd, 3rd
  placentalCordInsertionObs_radio: null,
  placentalCordInsertionObs_comment: null,
  placentalGrade: null,
  afvObs: null, // stages: 2nd, 3rd
  maxVerticalPocketLength: null, // stages: 2nd, 3rd
  bpp: null, // stages: 3rd
  grossBodyMvmt: null, // stages: 3rd
  fetalPosition: null, // stages: 1st
  fetalTone: null, // stages: 3rd
  fetalBreathing: null, // stages: 3rd
  amnioticFluidVol: null, // stages: 3rd
  amnioticFluidIndexS1Q1: null, // stages: 3rd
  amnioticFluidIndexS1Q2: null, // stages: 3rd
  amnioticFluidIndexS1Q3: null, // stages: 3rd
  amnioticFluidIndexS1Q4: null, // stages: 3rd
  sexEval_radio: null, // stages: 1st, 2nd, 3rd
  genitaliaObs_radio: null, // stages: 1st, 2nd, 3rd
  genitaliaObs_comment: null, // stages: 1st, 2nd, 3rd
  nuchalTranslucency: null, // stages: 1st
  cavumSeptiPellucidiObs_radio: null, // stages: 2nd, 3rd
  cavumSeptiPellucidiObs_comment: null, // stages: 2nd, 3rd
  craniumObs_radio: null, // stages: 1st, 2nd, 3rd normal, abnormal, suboptimal
  craniumObs_comment: null, // stages: 1st, 2nd, 3rd
  cerebellarDiameter: null, // stages: 2nd, 3rd
  cerebellarDiameterCalcPerc: null, // stages: 2nd, 3rd
  cerebellarDiameterCalcGestAge: null, // stages: 2nd, 3rd
  cisternaMagna: null, // stages: 2nd
  nuchalFold: null, // stages: 2nd
  lateralVentricle: null, // stages: 2nd, 3rd
  choroidPlexusObs_radio: null, // stages: 2nd, 3rd
  choroidPlexusObs_comment: null, // stages: 2nd, 3rd
  midlineFalxObs_radio: null, // stages: 2nd, 3rd
  midlineFalxObs_comment: null, // stages: 2nd, 3rd
  neckSkinObs_radio: null, // stages: 2nd, 3rd
  neckSkinObs_comment: null, // stages: 2nd, 3rd
  profileObs_radio: null, // stages: 2nd, 3rd
  profileObs_comment: null, // stages: 2nd, 3rd
  nasalBoneObs_radio: null, // stages: 1st, 2nd, 3rd
  nasalBoneObs_comment: null, // stages: 1st, 2nd, 3rd
  noseLipsObs_radio: null, // stages: 2nd, 3rd
  noseLipsObs_comment: null, // stages: 2nd, 3rd
  palateObs_radio: null, // stages: 2nd, 3rd
  palateObs_comment: null, // stages: 2nd, 3rd
  orbitsObs_radio: null, // stages: 2nd, 3rd
  orbitsObs_comment: null, // stages: 2nd, 3rd
  uterusObs_radio: null, // stages: ep, 1st, 2nd
  uterusObs_comment: null, // stages: ep, 1st, 2nd
  uterusLength: null, // stages: ep, 1st, 2nd
  uterusHeight: null, // stages: ep, 1st, 2nd
  uterusWidth: null, // stages: ep, 1st, 2nd
  extrauterineMassObs_radio: null, // stages: ep, 1st, 2nd, 3rd
  extrauterineMassObs_comment: null, // stages: ep, 1st, 2nd, 3rd
  extrauterineMassLength: null, // stages: ep, 1st, 2nd, 3rd
  extrauterineMassHeight: null, // stages: ep, 1st, 2nd, 3rd
  extrauterineMassWidth: null, // stages: ep, 1st, 2nd, 3rd
  rightOvaryObs_radio: null, // stages: ep, 1st, 2nd, 3rd
  rightOvaryObs_comment: null, // stages: ep, 1st, 2nd, 3rd
  rightOvaryLength: null, // stages: ep, 1st, 2nd, 3rd
  rightOvaryHeight: null, // stages: ep, 1st, 2nd, 3rd
  rightOvaryWidth: null, // stages: ep, 1st, 2nd, 3rd
  rightAdnexalMassObs_radio: null, // stages: ep, 1st, 2nd, 3rd
  rightAdnexalMassObs_comment: null, // stages: ep, 1st, 2nd, 3rd
  rightAdnexalMassLength: null, // stages: ep, 1st, 2nd, 3rd
  rightAdnexalMassHeight: null, // stages: ep, 1st, 2nd, 3rd
  rightAdnexalMassWidth: null, // stages: ep, 1st, 2nd, 3rd
  leftOvaryObs_radio: null, // stages: ep, 1st, 2nd, 3rd
  leftOvaryObs_comment: null, // stages: ep, 1st, 2nd, 3rd
  leftOvaryLength: null, // stages: ep, 1st, 2nd, 3rd
  leftOvaryHeight: null, // stages: ep, 1st, 2nd, 3rd
  leftOvaryWidth: null, // stages: ep, 1st, 2nd, 3rd
  leftAdnexalMassObs_radio: null, // stages: ep, 1st, 2nd, 3rd
  leftAdnexalMassObs_comment: null, // stages: ep, 1st, 2nd, 3rd
  leftAdnexalMassLength: null, // stages: ep, 1st, 2nd, 3rd
  leftAdnexalMassHeight: null, // stages: ep, 1st, 2nd, 3rd
  leftAdnexalMassWidth: null, // stages: ep, 1st, 2nd, 3rd
  spineObs_radio: null, // stages: 1st, 2nd, 3rd
  spineObs_comment: null, // stages: 1st, 2nd, 3rd
  legsObs_radio: null, // stages: 1st, 2nd, 3rd
  legsObs_comment: null, // stages: 1st, 2nd, 3rd
  armsObs_radio: null, // stages: 1st, 2nd, 3rd
  armsObs_comment: null, // stages: 1st, 2nd, 3rd
  handsObs_radio: null, // stages: 1st, 3rd
  handsObs_comment: null, // stages: 1st, 2nd, 3rd
  feetObs_radio: null, // stages: 1st, 2nd, 3rd
  feetObs_comment: null, // stages: 1st, 2nd, 3rd
  gestationsObs_radio: null, // stages: 1st, 2nd, 3rd
  gestationsObs_comment: null, // stages: 1st, 2nd, 3rd
  physicianFullName: null, // final report
  finalizedOn: null, // final report
  grossBodyMovementObs_value: null, // stages: 2nd, 3rd
  amnioticFluidVolumeObs_value: null, // stages: 2nd, 3rd
  fetalBreathingObs_value: null, // stages: 2nd, 3rd
  fetalToneObs_value: null, // stages: 2nd, 3rd
};

// This maps the ID of the checkbox item in Configuration to the Anatomy ID in the fullAnatomyTemplate const
// Used in the Report tab
export const configToIdsMap = {
  gestations: ["gestationsObs", "gestationsObs_radio", "gestationsObs_comment"],
  placenta: [
    "placentaObs",
    "placentaObs_radio",
    "placentaObs_comment",
    "placentalGrade",
    "placentalVisualization",
  ],
  embryo: ["embryoVis", "embryoVis_radio", "embryoVis_comment"],
  // fetal_position: [
  //   "fetalPosition",
  //   "fetalPosition_radio",
  //   "fetalPosition_comment",
  // ],
  fetus_presentation: [
    "fetusPresentation",
    "fetusPresentation_radio",
    "fetusPresentation_comment",
  ],
  cervix: ["cervixLength", "cervixObs", "cervixObs_comment", "cervixObs_radio"],
  placental_cord_insertion: [
    "placentalCordInsertionObs",
    "placentalCordInsertionObs_comment",
    "placentalCordInsertionObs_radio",
  ],
  umbilical_cord: ["umbCordObs", "umbCordObs_comment", "umbCordObs_radio"],
  plac_to_cvx: ["placentaToCervix"],
  mvp: ["maxVerticalPocketLength"],
  amniotic_fluid: ["afvObs", "afvObs_comment", "afvObs_radio"],
  yolk_sac: [
    "yolkSacLength",
    "yolkSacObs",
    "yolkSacObs_comment",
    "yolkSacObs_radio",
  ],
  afi: [
    "amnioticFluidIndexS1Q1",
    "amnioticFluidIndexS1Q2",
    "amnioticFluidIndexS1Q3",
    "amnioticFluidIndexS1Q4",
  ],
  uterus: [
    "uterusObs",
    "uterusObs_comment",
    "uterusObs_radio",
    "uterusLength",
    "uterusHeight",
    "uterusWidth",
  ],
  ovary: [
    "rightOvaryObs",
    "rightOvaryObs_radio",
    "rightOvaryObs_comment",
    "rightOvaryLength",
    "rightOvaryHeight",
    "rightOvaryWidth",
    "leftOvaryObs",
    "leftOvaryObs_radio",
    "leftOvaryObs_comment",
    "leftOvaryLength",
    "leftOvaryHeight",
    "leftOvaryWidth",
  ],
  ext_ut_mass: [
    "extrauterineMassObs",
    "extrauterineMassObs_radio",
    "extrauterineMassObs_comment",
    "extrauterineMassLength",
    "extrauterineMassHeight",
    "extrauterineMassWidth",
  ],
  ad_masses: [
    "rightAdnexalMassObs",
    "rightAdnexalMassObs_radio",
    "rightAdnexalMassObs_comment",
    "rightAdnexalMassLength",
    "rightAdnexalMassHeight",
    "rightAdnexalMassWidth",
    "leftAdnexalMassObs",
    "leftAdnexalMassObs_radio",
    "leftAdnexalMassObs_comment",
    "leftAdnexalMassLength",
    "leftAdnexalMassHeight",
    "leftAdnexalMassWidth",
  ],
  bpd: ["biparietalDiameter"],
  crown_rump_length: ["crownRumpLength"],
  gestational_sac: [
    "gestationalSacLocationObs",
    "gestationalSacLocationObs_radio",
    "gestationalSacLocationObs_comment",
    "gestationalSacDiameter",
  ],
  hc: ["headCircumference"],
  ac: ["abdominalCircumference"],
  fl: ["femurLength"],
  tl: ["tibiaLength"],
  FiL: ["fibulaLength"],
  hl: ["humerusLength"],
  ul: ["ulnaLength"],
  rl: ["radiusLength"],
  posterior_fossa: [
    "posteriorFossaObs",
    "posteriorFossaObs_comment",
    "posteriorFossaObs_radio",
  ],
  trans_cereb_diam: [
    "cerebellarDiameter",
    "cerebellarDiameterCalcGestAge",
    "cerebellarDiameterCalcPerc",
  ],
  cisterna_magna: ["cisternaMagna"],
  nuchal_fold: ["nuchalFold"],
  nuchal_translucency: ["nuchalTranslucency"],
  lateral_ventricle: ["lateralVentricle"],
  choroid_plexus: [
    "rightChoroidPlexusObs",
    "rightChoroidPlexusObs_comment",
    "rightChoroidPlexusObs_radio",
    "leftChoroidPlexusObs",
    "leftChoroidPlexusObs_comment",
    "leftChoroidPlexusObs_radio",
    "choroidPlexusObs", // backwards compatible w/ studies prior to v1.10
    "choroidPlexusObs_radio", // backwards compatible w/ studies prior to v1.10
    "choroidPlexusObs_comment", // backwards compatible w/ studies prior to v1.10
  ],
  midline_falx: [
    "midlineFalxObs",
    "midlineFalxObs_comment",
    "midlineFalxObs_radio",
  ],
  cavum_septi_pellucidi: [
    "cavumSeptiPellucidiObs",
    "cavumSeptiPellucidiObs_comment",
    "cavumSeptiPellucidiObs_radio",
  ],
  cranium: ["craniumObs", "craniumObs_comment", "craniumObs_radio"],
  "neck-skin": ["neckSkinObs", "neckSkinObs_comment", "neckSkinObs_radio"],
  profile: ["profileObs", "profileObs_comment", "profileObs_radio"],
  nasal_bone: ["nasalBoneObs", "nasalBoneObs_comment", "nasalBoneObs_radio"],
  "nose-lips": ["noseLipsObs", "noseLipsObs_comment", "noseLipsObs_radio"],
  palate: ["palateObs", "palateObs_comment", "palateObs_radio"],
  orbits: ["orbitsObs", "orbitsObs_comment", "orbitsObs_radio"],
  cardiac_activity: [
    "cardiacActivity",
    "cardiacActivity_comment",
    "cardiacActivity_radio",
  ],
  fetal_heart_rate: [
    "fetalHeartRate",
    "fetalHeartRate_comment",
    "fetalHeartRate_radio",
  ],
  "4_chamber_heart": ["heart4Obs", "heart4Obs_comment", "heart4Obs_radio"],
  LVOT: ["lvotObs", "lvotObs_comment", "lvotObs_radio"],
  RVOT: ["rvotObs", "rvotObs_comment", "rvotObs_radio"],
  "3VV-3VTV": ["heart3Obs", "heart3Obs_comment", "heart3Obs_radio"],
  situs: ["situsObs", "situsObs_comment", "situsObs_radio"],
  aortic_arch: ["aorticObs", "aorticObs_comment", "aorticObs_radio"],
  "sup_&_inf_venae_cavae": ["siVcObs", "siVcObs_comment", "siVcObs_radio"],
  ductal_arch: [
    "ductalArchObs",
    "ductalArchObs_comment",
    "ductalArchObs_radio",
  ],
  diaphragm: ["diaphragmObs", "diaphragmObs_comment", "diaphragmObs_radio"],
  thymus: ["thymusObs", "thymusObs_comment", "thymusObs_radio"],
  stomach: ["stomachObs", "stomachObs_comment", "stomachObs_radio"],
  kidney: [
    "rightKidneyObs",
    "rightKidneyObs_comment",
    "rightKidneyObs_radio",
    "leftKidneyObs",
    "leftKidneyObs_comment",
    "leftKidneyObs_radio",
    "kidneysObs", // backwards compatible w/ studies prior to v1.10
    "kidneysObs_radio", // backwards compatible w/ studies prior to v1.10
    "kidneysObs_comment", // backwards compatible w/ studies prior to v1.10
  ],
  bladder: ["bladderObs", "bladderObs_comment", "bladderObs_radio"],
  abdominal_cord_insert: [
    "abdCordObs",
    "abdCordObs_comment",
    "abdCordObs_radio",
  ],
  genitalia: [
    "genitaliaObs",
    "genitaliaObs_comment",
    "genitaliaObs_radio",
    "sexEval",
    "sexEval_radio",
  ],
  spine: [
    "cSpineObs",
    "cSpineObs_comment",
    "lSpineObs_radio",
    "lSpineObs",
    "lSpineObs_comment",
    "sSpineObs_radio",
    "sSpineObs",
    "sSpineObs_comment",
    "tSpineObs_radio",
    "tSpineObs",
    "tSpineObs_comment",
    "cSpineObs_radio",
    "spineObs", // backwards compatible w/ studies prior to v1.10
    "spineObs_radio", // backwards compatible w/ studies prior to v1.10
    "spineObs_comment", // backwards compatible w/ studies prior to v1.10
  ],
  leg: [
    "rightLegObs",
    "rightLegObs_comment",
    "rightLegObs_radio",
    "leftLegObs",
    "leftLegObs_comment",
    "leftLegObs_radio",
    "legsObs", // backwards compatible w/ studies prior to v1.10
    "legsObs_radio", // backwards compatible w/ studies prior to v1.10
    "legsObs_comment", // backwards compatible w/ studies prior to v1.10
  ],
  foot: [
    "rightFootObs",
    "rightFootObs_comment",
    "rightFootObs_radio",
    "leftFootObs",
    "leftFootObs_comment",
    "leftFootObs_radio",
    "feetObs", // backwards compatible w/ studies prior to v1.10
    "feetObs_radio", // backwards compatible w/ studies prior to v1.10
    "feetObs_comment", // backwards compatible w/ studies prior to v1.10
  ],
  arm: [
    "rightArmObs",
    "rightArmObs_comment",
    "rightArmObs_radio",
    "leftArmObs",
    "leftArmObs_comment",
    "leftArmObs_radio",
    "armsObs", // backwards compatible w/ studies prior to v1.10
    "armsObs_radio", // backwards compatible w/ studies prior to v1.10
    "armsObs_comment", // backwards compatible w/ studies prior to v1.10
  ],
  hand: [
    "rightHandObs",
    "rightHandObs_comment",
    "rightHandObs_radio",
    "leftHandObs",
    "leftHandObs_comment",
    "leftHandObs_radio",
    "handsObs", // backwards compatible w/ studies prior to v1.10
    "handsObs_radio", // backwards compatible w/ studies prior to v1.10
    "handsObs_comment", // backwards compatible w/ studies prior to v1.10
  ],
  gross_body_movement: [
    "grossBodyMovement",
    "grossBodyMovementObs",
    "grossBodyMovementObs_value",
  ],
  fetal_tone: ["fetalTone", "fetalToneObs", "fetalToneObs_value"],
  fetal_breathing: [
    "fetalBreathing",
    "fetalBreathingObs",
    "fetalBreathingObs_value",
  ],
  amniotic_fluid_volume: [
    "afv",
    "amnioticFluidVolumeObs",
    "amnioticFluidVolumeObs_value",
  ],
};

// AllSectionsReportModel properties that should have "Not Visualized"
// checkboxes checked if left null in evaluations
export const fetalMaternalReportCheckProps = [
  "craniumObs_radio", // stages: 1st, 2nd, 3rd
  "cavumSeptiPellucidiObs_radio", // stages: 2nd, 3rd
  "choroidPlexusObs_radio", // stages: 2nd, 3rd
  "midlineFalxObs_radio", // stages: 2nd, 3rd
  "palateObs_radio", // stages: 2nd, 3rd
  "orbitsObs_radio", // stages: 2nd, 3rd
  "neckSkinObs_radio", // stages: 2nd, 3rd
  "profileObs_radio", // stages: 2nd, 3rd
  "noseLipsObs_radio", // stages: 2nd, 3rd
  "situsObs_radio", // stages: 1st, 2nd, 3rd
  "aorticObs_radio", // stages: 2nd, 3rd
  "siVcObs_radio", // stages: 2nd, 3rd
  "heart4Obs_radio", // stages: 1st, 2nd, 3rd
  "heart3Obs_radio", // stages: 1st, 2nd, 3rd
  "rvotObs_radio", // stages: 1st, 2nd, 3rd
  "lvotObs_radio", // stages: 1st, 2nd, 3rd
  "ductalArchObs_radio", // stages: 2nd, 3rd
  "diaphragmObs_radio", // stages: 2nd, 3rd
  "stomachObs_radio", // stages: 1st, 2nd, 3rd
  "kidneysObs_radio", // stages: 2nd, 3rd
  "abdCordObs_radio", // stages: 1st, 2nd, 3rd
  "bladderObs_radio", // stages: 1st, 2nd, 3rd
  "umbCordObs_radio", // stages: 2nd, 3rd
  "spineObs_radio", // stages: 1st, 2nd, 3rd
  "legsObs_radio", // stages: 1st, 2nd, 3rd
  "armsObs_radio", // stages: 1st, 2nd, 3rd
  "handsObs_radio", // stages: 1st, 2nd, 3rd
  "feetObs_radio", // stages: 1st, 2nd, 3rd
  "genitaliaObs_radio", // stages: 1st, 2nd, 3rd
  "cervixObs_radio", // stages: ep, 1st, 2nd, 3rd
  "uterusObs_radio", // stages: ep, 1st, 2nd, 3rd
  "rightOvaryObs_radio", // stages: ep, 1st, 2nd, 3rd
  "leftOvaryObs_radio", // stages: ep, 1st, 2nd, 3rd
];

// ========================================

// This IntakeReport is a combination of a Model and a Service
// Ideally it should be in the backend with the front-end just receiving json
export class IntakeReport {
  assignedSonographerFullName?: string;
  bmi?: number;
  bpDiastolic?: number;
  bpSystolic?: number;
  bpp?: number; // stages: 3rd
  clinicId?: string;
  clinicName?: string;
  createdOn?: Date;
  driverFullName?: string;
  driverUserId?: string;
  dueDateMethodology?: string; // re-calculate as it is stored incorrectly enum UltrasoundMethod
  echoIndications?: string;
  estimatedDueDate?: number;
  examRoomId?: string;
  examRoomName?: string;
  facilityAddress1?: string; // all
  facilityAddress2?: string; // all
  facilityCity?: string; // all
  facilityName?: string; // all
  facilityState?: string; // all
  facilityZipCode?: string; // all
  fetalHeartRate?: string; // stages: 1st, 2nd, 3rd
  fetalMovement?: boolean;
  finalizedOn?: string; // final report
  genderRevealDesired?: boolean;
  hadUltrasound?: string;
  height?: string;
  id?: string;
  institutionId?: string;
  institutionName?: string;
  ivfConception?: boolean;
  ivfDate?: number;
  lastMenstrualPeriod?: number;
  lastStepCompleted?: string;
  leepProcedureConducted?: boolean;
  numCSections?: number;
  numGestations?: number;
  numGreaterThan37Deliveries?: number;
  numLessThan37Deliveries?: number;
  numLiveBirths?: number;
  numMiscarriagesAbortions?: number;
  patientUserId?: string;
  physicianFullName?: string; // all
  physicianUserId?: string; // all
  preNatalScreeningTestResult?: string;
  prePregnancyBmi?: number;
  prePregnancyWeight?: number;
  pregnancyStage?: string;
  printToken?: string;
  referringPhysician?: string;
  requestingPhysician?: string;
  sonographerUserId?: string;
  status?: string;
  totalFrameCount?: string;
  ultrasoundMethod?: string;
  updatedOn?: string;
  visitPurpose?: string;
  visitType?: string;
  weight?: number;
  officeVisit?: string; // stages: ep, 1st, 2nd, 3rd
  followUp?: string; // stages: ep, 1st, 2nd, 3rd
  patient?: Patient;

  // additional helper fields for report
  streetAddress?: string; // created in class
  cityStateAddress?: string; // created in class
  isDoctorLocked?: boolean; // created in class
  gravida?: number; // created in class
  obComplete?: string; // created in class
  bloodPressure: string; // created in class
  fetalEchoIndications: string[] = [];
  maternalEchoIndications: string[] = [];
  otherIndications: string[] = [];
  finalizedOnDate?: string;

  constructor(input: any) {
    // dayjs.extend(AdvancedFormat);
    const numHeight = input.height;

    Object.assign(this, input);

    // now manipulate data
    this.setAddress();
    this.setDoctorLocked();
    this.setObComplete();
    this.calculateBmi(numHeight);
    this.setBloodPressure();
    this.transformIndications();
    this.calculateGravida();

    return this;
  }

  // Area for more data mainpulation
  //==========================================

  // set the gravida number by calculating it
  private calculateGravida(): void {
    const gravida =
      1 + // current pregnancy
      (this.numLessThan37Deliveries || 0) +
      (this.numGreaterThan37Deliveries || 0) +
      (this.numMiscarriagesAbortions || 0);
    this.gravida = gravida;
  }

  // This needs to be done BEFORE  transformHeight
  private calculateBmi(height: number): void {
    if (this.height && this.weight) {
      this.bmi = (this.weight / height ** 2) * 703;
    }
    this.transformHeight(height);
  }

  private transformHeight(height: number): void {
    const totalInches = Number(height);
    let wordHeight = "Not Provided";

    if (height) {
      const feet = Math.floor(totalInches / 12);
      const inches = totalInches % 12;
      wordHeight = `${feet} feet, ${inches} inches`;
    }
    this.height = wordHeight;
  }

  public addPatient(patient: Patient): void {
    this.patient = patient;
  }

  public getMaternalAge(): boolean {
    if (!this.patient.dob) {
      return false;
    }
    //TODO remove moment and create pipe??
    const age = moment().diff(
      moment(this.patient?.dob?.toString()),
      "years",
      true
    );
    return age >= 35;
  }

  //TODO create enum
  public getPrimaMulti(): string {
    if (
      this.numGreaterThan37Deliveries === 0 &&
      this.numLessThan37Deliveries === 0 &&
      this.numMiscarriagesAbortions === 0 &&
      this.numLiveBirths === 0 &&
      this.numCSections === 0
    ) {
      return "Primagravida";
    }
    return "Multigravida";
  }

  getPatientUserId(): string {
    return this.patientUserId;
  }

  public getUltrasoundMethod(): string {
    return UltrasoundMethod[this.ultrasoundMethod];
  }

  public getVisitType(): string {
    return VisitType[this.visitType];
  }

  // TODO:  move to a UTIL date class or use a date library
  private weeksBetween(date1: Date, date2: Date): number {
    // Get 1 day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    const date1Ms = date1.getTime();
    const date2Ms = date2.getTime();

    // Calculate the difference in milliseconds
    const differenceMs = Math.max(date2Ms - date1Ms, date1Ms - date2Ms);

    // Convert back to days and return
    const daysBetween = Math.round(differenceMs / oneDay);
    return Math.floor(daysBetween / 7);
  }

  private setBloodPressure(): void {
    if (this.bpSystolic && this.bpDiastolic) {
      this.bloodPressure = `${this.bpSystolic} / ${this.bpDiastolic}`;
    }
  }

  // TODO: create enum redo dates
  private setObComplete(): void {
    let obComplete = "";

    if (this.pregnancyStage && this.createdOn) {
      obComplete =
        this.pregnancyStage === "early-pregnancy" ||
        this.pregnancyStage === "first-trimester"
          ? "OB Complete less than 14 weeks"
          : "OB complete greater than 14 weeks";
    }

    this.obComplete = obComplete;
  }

  private setAddress(): void {
    this.setStreetAddress();
    this.setCityStateAddress();
  }

  private setStreetAddress(): void {
    this.streetAddress = undefined;
    if (this.facilityAddress1) {
      this.streetAddress = this.facilityAddress2
        ? `${this.facilityAddress1}, ${this.facilityAddress2}`
        : `${this.facilityAddress1}`;
    }
  }

  private setCityStateAddress(): void {
    this.cityStateAddress = undefined;
    if (this.facilityCity) {
      if (this.facilityState && this.facilityZipCode) {
        this.cityStateAddress = `${this.facilityCity}, ${this.facilityState}, ${this.facilityZipCode}`;
      } else if (this.facilityState && !this.facilityZipCode) {
        this.cityStateAddress = `${this.facilityCity}, ${this.facilityState}`;
      } else {
        this.cityStateAddress = `${this.facilityCity}`;
      }
    }
  }

  private setDoctorLocked(): void {
    this.isDoctorLocked = !!this.physicianUserId;
  }

  private transformIndications(): void {
    const fetalEchoIndications = [];
    const maternalEchoIndications = [];
    const otherIndications = [];
    let aEchoIndications = [];

    if (typeof this.echoIndications === "string") {
      aEchoIndications = this.echoIndications.split(",");
    }

    // sort indications into maternal or fetal
    aEchoIndications.forEach((item) => {
      if (item.startsWith("fa-")) {
        fetalEchoIndications.push(EchoIndications[item]);
      }
      if (item.startsWith("m-")) {
        maternalEchoIndications.push(EchoIndications[item]);
      }
      if (item.startsWith("o-")) {
        otherIndications.push(EchoIndications[item]);
      }
    });
    this.fetalEchoIndications = fetalEchoIndications;
    this.maternalEchoIndications = maternalEchoIndications;
    this.otherIndications = otherIndications;
  }
}
