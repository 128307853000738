<ng-select
  name="evaluationValue"
  (ngModelChange)="update($event)"
  #evaluationValue="ngModel"
  [(ngModel)]="model.evaluationValue"
  [disabled]="isDisabled"
>
  <ng-option
    *ngFor="let selectOption of selectOptions"
    [value]="selectOption.value"
  >
    {{selectOption.key}}
  </ng-option>
</ng-select>
