import * as yup from "yup";
import { evaluationSelectors } from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographer2ndTriV2IntercranialFacialSchema = yup
  .object()
  .shape({
    cerebellarDiameter: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Transcerebellar Diameter (Hill, 1990)(mm)",
        dicomUnit: "mm",
      } as FieldMeta)
      .required("Transcerebellar diameter is required")
      .test(
        "row-test",
        "Transcerebellar diameter should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    cerebellarDiameterCalcPerc: yup.string().meta({
      control: "tcd-calc",
      label: "Percentile:",
    } as FieldMeta),
    cerebellarDiameterCalcGestAge: yup.string().meta({
      control: "tcd-calc",
      label: "Estimated Gestational Age:",
    } as FieldMeta),
    cisternaMagna: yup
      .number()
      .required("Cisterna Magna is required")
      .meta({
        control: "dicom-selector",
        label: "Cisterna Magna(mm)",
        dicomUnit: "mm",
      } as FieldMeta)
      .test(
        "row-test",
        "Cisterna Magna should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    nuchalFold: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Nuchal Fold(mm)",
        dicomUnit: "mm",
      } as FieldMeta)
      .required("Nuchal Fold is required")
      .test("row-test", "Nuchal Fold should be positive integer", (v: any) => {
        return Number(v) >= 0;
      }),
    lateralVentricle: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Lateral Ventricle(mm)",
        dicomUnit: "mm",
      } as FieldMeta)
      .required("Lateral Ventricle is required")
      .test(
        "row-test",
        "Lateral Ventricle should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    choroidPlexusObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Choroid Plexus",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    choroidPlexusObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    midlineFalxObsEval: yup
      .string()
      .meta({
        control: "radio-list",
        label: "Midline Falx",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    midlineFalxObs: yup.string().meta({
      control: "textarea",
    } as FieldMeta),
    cavumSeptiPellucidiObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Cavum Septi Pellucidi",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    cavumSeptiPellucidiObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    craniumObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Cranium",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    craniumObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    neckSkinObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Neck/Skin",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    neckSkinObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    profileObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Profile",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    profileObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    noseLipsObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Nose/Lips",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    noseLipsObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    palateObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Palate",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    palateObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    orbitsObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Orbits",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    orbitsObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
  })
  .required();

type Sonographer2ndTriV2IntercranialFacialType = yup.InferType<
  typeof sonographer2ndTriV2IntercranialFacialSchema
>;

export {
  sonographer2ndTriV2IntercranialFacialSchema,
  Sonographer2ndTriV2IntercranialFacialType,
};
