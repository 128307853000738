import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

@Directive({
  selector: "[appTextareaGrow]",
})
export class TextareaGrowDirective implements OnChanges {
  @Input() minRows = 1;
  @Input() maxRows = 7;
  @Input() input: any; // We use this as a listener for the resize. Using value causes issues.

  private offsetHeight = 0;
  private lineHeight = 32;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.input) {
      this.resize();
    }
  }

  /**
   * From a pixel string return a number
   * @param val
   */
  getPixelNumber(val: string): number {
    return Number(val.replace("px", ""));
  }

  /**
   * calculates the actual line-height from the font-size and line-height
   * using an equation found in a font-size-calculator from
   * https://www.leserlich.info/werkzeuge/schriftgroessenrechner/index-en.php
   */
  findLineHeight(): void {
    const el = this.elementRef.nativeElement;
    const computedStyle = getComputedStyle(el);
    const height = computedStyle.getPropertyValue("line-height");
    const lineHeight = this.getPixelNumber(height);

    // font-size-calculator equation
    this.lineHeight = Math.floor(lineHeight);
    this.elementRef.nativeElement.style.minHeight = this.lineHeight;
  }

  resize(): void {
    try {
      const input = this.elementRef.nativeElement;
      this.findLineHeight();
      const scrollHeight = input.scrollHeight;
      const rows = Math.floor(
        (scrollHeight - this.offsetHeight) / this.lineHeight
      );

      if (rows > this.maxRows) {
        input.rows = this.maxRows;
      } else if (this.minRows !== 1 && rows <= this.minRows) {
        input.rows = this.minRows;
      } else {
        input.style.height = scrollHeight + "px";
      }
    } catch (error) {
      console.log("Error in resize", error);
    }
  }
}
