import { PipeTransform, Pipe } from "@angular/core";
// import { DataNotEnteredPipe } from "./data-not-entered.pipe";

@Pipe({ name: "append" })
export class AppendPipe implements PipeTransform {
  transform(val: any, appendValue: string): string {
    if (val === undefined || val === "" || val === null) {
      /**
       * Commenting these changes for now until I can get clarification
       * from Celine on why this change was originally made
       */
      // if (appendValue.includes("(")) {
      return "";
      // }
      // return "Not Visualized";
    }
    return `${val} ${appendValue}`;
  }
}
