import { Clinic } from "./clinics-response";
import { Patient } from "./patient";
import { PatientOrderForm } from "./patient-order-response";
import { PatientOrderInfoBrief } from "./patient-order-status-response";

export interface PatientOrderMeta {
  patientOrder: PatientOrderForm;
  patient: Patient;
  infoBrief: PatientOrderInfoBrief;
  clinic: Clinic;
}

/**
 * This constant is referenced by many components details on each property are listed below
 * - id is simply a unique ID for each object
 * - cptCodes is the clinical code(s) which this object apply to
 * - label is the human readable label to display
 * - template is used for the Impressions template in Evaluation. Values wrapped with {___} get replaced by available values
 * - checked is used by the configuration component to determine if the checkbox should be checked
 * - visibleAnatomy is the anatomy that is selected by default on the report
 */
export type CPTCodesType = typeof CPTCodes;

export const CPTCodes = [
  {
    id: 1,
    cptCodes: ["76801"],
    label: "Early OB",
    template: `Singleton living fetus with a gestational age {GA} based on reported clinical dates. Current growth parameters are/are not consistent with the expected gestational age. Today's ultrasound corresponds to {TODAY_GA}. Normal early fetal evaluation. Normal appearance of the adnexa.`,
    checked: false,
    visibileAnatomy: [
      "fetus_presentation",
      "gestations",
      "embryo",
      "yolk_sac",
      "uterus",
      "ovary",
      "crown_rump_length",
      "gestational_sac",
      "cardiac_activity",
      "fetal_heart_rate",
    ],
  },
  {
    id: 2,
    cptCodes: ["76805"],
    label: "Anatomical Survey",
    template: `Singleton living fetus at gestation age of {GA}. Normal initial fetal anatomic survey. Current fetal growth estimates {EFW} and measures at the {EFW_PERCENTILE} percentile for gestational age. Normal amniotic fluid. Normal transabdominal cervical length without evidence of shortening or funneling. Normal appearance of the bilateral adnexa.`,
    checked: false,
    visibileAnatomy: [
      "fetus_presentation",
      "cervix",
      "cervix_length",
      "placenta",
      "placental_cord_insertion",
      "umbilical_cord",
      "plac_to_cvx",
      "mvp",
      "amniotic_fluid",
      "ovary",
      "bpd",
      "hc",
      "ac",
      "fl",
      "posterior_fossa",
      "trans_cereb_diam",
      "cisterna_magna",
      "nuchal_fold",
      "lateral_ventricle",
      "choroid_plexus",
      "midline_falx",
      "cavum_septi_pellucidi",
      "cranium",
      "neck-skin",
      "profile",
      "nasal_bone",
      "nose-lips",
      "palate",
      "orbits",
      "cardiac_activity",
      "fetal_heart_rate",
      "4_chamber_heart",
      "LVOT",
      "RVOT",
      "3VV-3VTV",
      "situs",
      "aortic_arch",
      "sup_&_inf_venae_cavae",
      "ductal_arch",
      "diaphragm",
      "stomach",
      "kidney",
      "bladder",
      "abdominal_cord_insert",
      "genitalia",
      "spine",
      "leg",
      "foot",
      "arm",
      "hand",
    ],
  },
  {
    id: 3,
    cptCodes: ["76801", "76813"],
    label: "Nuchal Translucency",
    template: `Singleton living fetus with a gestational age {GA} based on reported clinical dates. Current growth parameters are/are not consistent with the expected gestational age. Today's ultrasound corresponds to {TODAY_GA}. Nuchal Translucency measures within normal limits at {NT}. Nasal bone is present. Normal early fetal evaluation. Normal appearance of the adnexa.`,
    checked: false,
    visibileAnatomy: [
      "fetus_presentation",
      "cervix",
      "placenta",
      "placental_cord_insertion",
      "amniotic_fluid",
      "uterus",
      "ovary",
      "crown_rump_length",
      "cardiac_activity",
      "fetal_heart_rate",
      "nuchal_translucency",
      "choroid_plexus",
      "midline_falx",
      "cranium",
      "profile",
      "nasal_bone",
      "orbits",
      "situs",
      "diaphragm",
      "stomach",
      "kidney",
      "abdominal_cord_insert",
      "bladder",
      "leg",
      "foot",
      "arm",
      "hand",
    ],
  },
  {
    id: 4,
    cptCodes: ["76816", "76819"],
    label: "Growth + BPP",
    template: `Singleton living fetus at gestational age of {GA}. Current fetal growth estimates {EFW} and measures at the {EFW_PERCENTILE} percentile for gestational age. Normal amniotic fluid volume. AFI measures {AFI}. Biophysical profile score of {BPP_SCORE} out of 8. {FETAL_PRESENTATION} presentation. A fetal anatomy survey was previously performed and is not repeated on today's exam.`,
    checked: false,
    visibileAnatomy: [
      "fetus_presentation",
      "cervix",
      "cervix_length",
      "placenta",
      "placental_cord_insertion",
      "umbilical_cord",
      "plac_to_cvx",
      "amniotic_fluid",
      "afi",
      "bpd",
      "hc",
      "ac",
      "fl",
      "posterior_fossa",
      "lateral_ventricle",
      "cardiac_activity",
      "fetal_heart_rate",
      "4_chamber_heart",
      "LVOT",
      "RVOT",
      "3VV-3VTV",
      "diaphragm",
      "stomach",
      "kidney",
      "bladder",
      "gross_body_movement",
      "fetal_tone",
      "fetal_breathing",
      "amniotic_fluid_volume",
    ],
  },
  {
    id: 5,
    cptCodes: ["76816"],
    label: "Growth/Follow Up",
    template: `Singleton living fetus at gestational age of {GA}. Current fetal growth estimates {EFW} and measures at the {EFW_PERCENTILE} percentile for gestational age. Normal amniotic fluid volume. AFI measures {AFI}. {FETAL_PRESENTATION} presentation. A fetal anatomy survey was previously performed and is not repeated on today's exam.`,
    checked: false,
    visibileAnatomy: [
      "fetus_presentation",
      "cervix",
      "cervix_length",
      "placenta",
      "umbilical_cord",
      "amniotic_fluid",
      "afi",
      "bpd",
      "hc",
      "ac",
      "fl",
      "posterior_fossa",
      "lateral_ventricle",
      "cardiac_activity",
      "fetal_heart_rate",
      "4_chamber_heart",
      "LVOT",
      "RVOT",
      "3VV-3VTV",
      "diaphragm",
      "stomach",
      "kidney",
      "bladder",
    ],
  },
  {
    id: 6,
    cptCodes: ["76819"],
    label: "BPP",
    template: `Singleton living fetus at gestational age of {GA}. Normal amniotic fluid volume. AFI measures {AFI}. Biophysical profile score of {BPP_SCORE} out of 8. {FETAL_PRESENTATION} presentation. A fetal anatomy survey was previously performed and is not repeated on today's exam.`,
    checked: false,
    visibileAnatomy: [
      "fetus_presentation",
      "placenta",
      "amniotic_fluid",
      "afi",
      "cardiac_activity",
      "fetal_heart_rate",
      "diaphragm",
      "stomach",
      "kidney",
      "bladder",
      "gross_body_movement",
      "fetal_tone",
      "fetal_breathing",
      "amniotic_fluid_volume",
    ],
  },
];

export const ViewLimitationsSelections = [
  { key: "multipleGestation", value: "Multiple Gestation" },
  { key: "constantFetalMovement", value: "Constant Fetal Movement" },
  { key: "earlyGestationalAge", value: "Early Gestational Age" },
  { key: "lateGestationalAge", value: "Late Gestational Age" },
  { key: "maternalBodyHabitus", value: "Maternal Body Habitus" },
  { key: "fetus_presentation", value: "Presentation" },
];
