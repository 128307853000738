import { BrowserModule } from "@angular/platform-browser";
import { ErrorHandler, Injector, NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { Interceptor } from "src/app/core/interceptors/http.interceptor";
import { JwtInterceptor } from "src/app/core/interceptors/jwt.interceptor";
import { AppRoutingModule } from "src/app/app.routing.module";
import { AppComponent } from "src/app/app.component";
import { AuthGuard } from "src/app/core/guard/auth.guard";
import { BBIErrorHandler } from "./core/services/error-handler.service";
import { CoreModule } from "src/app/core/core.module";
import { GlobalModule } from "src/app/global/global.module";
import { TenantGuard } from "src/app/core/guard/tenant.guard";
import { AdminGuard } from "src/app/core/guard/admin.guard";
import { RedirectGuard } from "src/app/core/guard/redirect.guard";
import {
  NgbDateParserFormatter,
  NgbDateAdapter,
} from "@ng-bootstrap/ng-bootstrap";
import { CustomDateParserFormatter } from "src/app/global/date-utils/custom-date-parser-formatter";
import { CustomAdapter } from "src/app/global/date-utils/custom-adapter";
import { HotkeyModule } from "angular2-hotkeys";

@NgModule({
  imports: [
    BrowserModule,
    GlobalModule,
    CoreModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HotkeyModule.forRoot(),
  ],
  declarations: [AppComponent],
  providers: [
    AuthGuard,
    TenantGuard,
    RedirectGuard,
    AdminGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    {
      provide: ErrorHandler,
      useFactory: (httpClient: HttpClient, injector: Injector): any =>
        new BBIErrorHandler(httpClient, injector),
      deps: [HttpClient, Injector],
    },
    { provide: NgbDateAdapter, useClass: CustomAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
