import * as yup from "yup";
import { sonographerDiagnosticsThirdTriImpressions } from "../../global/constants/sonographer-constants";

const sonographer3rdTriImpressionsSchema = yup
  .object()
  .shape({
    impression: yup
      .string()
      .oneOf(sonographerDiagnosticsThirdTriImpressions)
      .required("Impression is required")
      .default(sonographerDiagnosticsThirdTriImpressions[0]),
    impressionNotes: yup.string(),
    additionalNotes: yup.string(),
  })
  .required();

type Sonographer3rdTriImpressionsType = yup.InferType<
  typeof sonographer3rdTriImpressionsSchema
>;

export {
  sonographer3rdTriImpressionsSchema as Sonographer3rdTriImpressionsSchema,
  Sonographer3rdTriImpressionsType,
};
