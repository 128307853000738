import * as yup from "yup";
import { sonographerDiagnosticsImpressions } from "../../global/constants/sonographer-constants";

const sonographer2ndTriImpressionsSchema = yup
  .object()
  .shape({
    impression: yup
      .string()
      .oneOf(sonographerDiagnosticsImpressions)
      .required("Impression is required")
      .default(sonographerDiagnosticsImpressions[0]),
    impressionNotes: yup.string(),
    additionalNotes: yup.string(),
  })
  .required();

type Sonographer2ndTriImpressionsType = yup.InferType<
  typeof sonographer2ndTriImpressionsSchema
>;

export {
  sonographer2ndTriImpressionsSchema as Sonographer2ndTriImpressionsSchema,
  Sonographer2ndTriImpressionsType,
};
