import * as yup from "yup";
import { FieldMeta } from "../field-meta";

const sonographer3rdTriV2FetalBioSchema = yup
  .object()
  .shape({
    estimatedFetalAge: yup.string().meta({
      control: "fa-calc",
      label: "Estimated Fetal Age:",
    } as FieldMeta),
    estimatedFetalWeight: yup.string().meta({
      control: "fw-calc",
      label: "Estimated Fetal Weight:",
    } as FieldMeta),
    biparietalDiameter: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Biparietal Diameter (Hadlock, 1984)(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test(
        "bpd-test",
        "This value must be a positive integer or null",
        (v: any) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Gestational Sac Diameter is required"),
    biparietalDiameterCalcPerc: yup.string().meta({
      control: "bpd-calc",
      label: "Percentile:",
    } as FieldMeta),
    biparietalDiameterCalcGestAge: yup.string().meta({
      control: "bpd-calc",
      label: "Estimated Gestational Age:",
    } as FieldMeta),
    headCircumference: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Head Circumference (Hadlock, 1984)(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test(
        "biparietalDiameter-test",
        "This value must be a positive integer or null",
        (v) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Head circumference is required"),
    headCircumferenceCalcPerc: yup.string().meta({
      control: "hc-calc",
      label: "Percentile:",
    } as FieldMeta),
    headCircumferenceCalcGestAge: yup.string().meta({
      control: "hc-calc",
      label: "Estimated Gestational Age:",
    } as FieldMeta),
    occipitalFrontDiameterCalc: yup.string().meta({
      control: "ofd-calc",
      label: "Occipital Frontal Diameter (Salomon, 2011)(cm):",
    } as FieldMeta),
    cephalicIndexCalc: yup.string().meta({
      control: "ci-calc",
      label: "Cephalic Index(CI) (Hadlock, 1981):",
    } as FieldMeta),
    cephalicIndexRange: yup.string().meta({
      control: "empty-control",
    } as FieldMeta),
    abdominalCircumference: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Abdominal Circumference (Hadlock, 1984)(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test(
        "abc-test",
        "This value must be a positive integer or null",
        (v: any) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Abdominal Circumference is required"),
    abdominalCircumferenceCalcPerc: yup.string().meta({
      control: "ac-calc",
      label: "Percentile:",
    } as FieldMeta),
    abdominalCircumferenceCalcGestAge: yup.string().meta({
      control: "ac-calc",
      label: "Estimated Gestational Age:",
    } as FieldMeta),
    hcAcRatioCalc: yup.string().meta({
      control: "hcac-calc",
      label: "HC/AC Ratio (Campbell, 1977):",
    } as FieldMeta),
    hcAcRatioRange: yup.string().meta({
      control: "empty-control",
    } as FieldMeta),
    femurLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Femur Length (Hadlock, 1984)(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test(
        "femur-test",
        "This value must be a positive integer or null",
        (v: any) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Femur Length is required"),
    femurLengthCalcPerc: yup.string().meta({
      control: "fl-calc",
      label: "Percentile:",
    } as FieldMeta),
    femurLengthCalcGestAge: yup.string().meta({
      control: "fl-calc",
      label: "Estimated Gestational Age:",
    } as FieldMeta),
    flBpdRatioCalc: yup.string().meta({
      control: "flbpd-calc",
      label: "FL/BPD Ratio (Hohler, 1981):",
    } as FieldMeta),
    flBpdRatioRange: yup.string().meta({
      control: "empty-control",
    } as FieldMeta),
    flAcRatioCalc: yup.string().meta({
      control: "flac-calc",
      label: "FL/AC Ratio (Hadlock, 1983):",
    } as FieldMeta),
    flAcRatioRange: yup.string().meta({
      control: "empty-control",
    } as FieldMeta),
    humerusLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Humerus Length (Jeanty, 1984)(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test(
        "humerusLength-test",
        "This value must be a positive integer or null",
        (v: any) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Humerus Length is required"),
    humerusLengthCalcPerc: yup.string().meta({
      control: "hl-calc",
      label: "Percentile:",
    } as FieldMeta),
    humerusLengthCalcGestAge: yup.string().meta({
      control: "hl-calc",
      label: "Estimated Gestational Age:",
    } as FieldMeta),
  })
  .required();

type Sonographer3rdTriV2FetalBioType = yup.InferType<
  typeof sonographer3rdTriV2FetalBioSchema
>;

export { sonographer3rdTriV2FetalBioSchema, Sonographer3rdTriV2FetalBioType };
