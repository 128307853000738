import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { UserProfile } from "../../models/user-profile";

@Injectable({ providedIn: "root" })
export class ConfigStore {
  private _profile: BehaviorSubject<UserProfile> = new BehaviorSubject(
    undefined
  );

  public readonly profile: Observable<UserProfile> =
    this._profile.asObservable();

  public setProfile(profile: UserProfile): void {
    this._profile.next(profile);
  }
}
