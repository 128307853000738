import * as yup from "yup";
import { evaluationSelectors } from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographer1stTriV2IntercranialFacialSchema = yup
  .object()
  .shape({
    nuchalTranslucency: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Nuchal Translucency(mm)",
        dicomUnit: "mm",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test("nt-test", "This value must be a positive integer or null", (v) => {
        return typeof v === "number" && v >= 0;
      })
      .required("Nuchal Translucency is required"),
    cavumSeptiPellucidiObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Cavum Septi Pellucidi",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    cavumSeptiPellucidiObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    craniumObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Cranium",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    craniumObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
  })
  .required();

type Sonographer1stTriV2IntercranialFacialType = yup.InferType<
  typeof sonographer1stTriV2IntercranialFacialSchema
>;

export {
  sonographer1stTriV2IntercranialFacialSchema,
  Sonographer1stTriV2IntercranialFacialType,
};
