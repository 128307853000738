import * as yup from "yup";
import {
  sonographerPregnancyTypeSelections,
  sonographerGestationalSacLocations,
  evaluationSelectors,
  sonographerYolkSacEval,
} from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographerEpV2GeneralEvalSchema = yup
  .object()
  .shape({
    cervixObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Cervix",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    cervixObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    cervixLength: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Cervix Length(cm)",
        dicomUnit: "cm",
      } as FieldMeta)
      .required("Cervix length is required")
      .test(
        "row-test",
        "Cervix length should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    fetusPregnancy: yup
      .string()
      .meta({
        control: "radio-list",
        label: "Fetus Pregnancy",
        options: sonographerPregnancyTypeSelections.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(sonographerPregnancyTypeSelections)
      .required("Fetus Pregnancy is required"),
    fetalHeartRate: yup
      .number()
      .meta({
        control: "input-number",
        label: "Best Gestation Age Days",
      } as FieldMeta)
      .typeError("This value must be a positive integer")
      .test(
        "fetalHeartRate-test",
        "This value must be a positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      )
      .required("Fetal heart rate is required"),
    cardiacActivity: yup.boolean().meta({
      control: "radio-list",
      label: "Fetus Pregnancy",
      options: [
        { key: "Present", value: true } as Option,
        { key: "Absent", value: false } as Option,
      ],
    } as FieldMeta),
    gestationalSacVis: yup.boolean().meta({
      control: "checkbox",
      label: "Gestational Sac Vis",
      options: [
        { key: "true", value: true } as Option,
        { key: "false", value: false } as Option,
      ],
    } as FieldMeta),
    gestationalSacLocation: yup
      .string()
      .meta({
        control: "radio-list",
        label: "Gestational Sac Location",
        options: sonographerGestationalSacLocations.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .when("gestationalSacVis", {
        is: (value: boolean) => {
          return value === true;
        },
        then: yup
          .string()
          .oneOf(sonographerGestationalSacLocations)
          .required("Gestational Sac Location is required"),
        otherwise: yup.string().nullable(),
      }),
    embryoVis: yup
      .boolean()
      .meta({
        control: "checkbox",
        label: "Embryo Vis",
        options: [
          { key: "true", value: true } as Option,
          { key: "false", value: false } as Option,
        ],
      } as FieldMeta)
      .when("gestationalSacVis", {
        is: (value: boolean) => {
          return value === true;
        },
        then: yup.boolean().required("Embryo visualization is required"),
        otherwise: yup.boolean().nullable(),
      }),
    yolkSacVis: yup.boolean().meta({
      control: "checkbox",
      label: "Yolk Sac Vis",
      options: [
        { key: "true", value: true } as Option,
        { key: "false", value: false } as Option,
      ],
    } as FieldMeta),
    yolkSacEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        options: sonographerYolkSacEval.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(sonographerYolkSacEval)
      .required("Yolk Sac Evaluation selection is required"),
  })
  .required();

type SonographerEpV2GeneralEvalType = yup.InferType<
  typeof sonographerEpV2GeneralEvalSchema
>;

export { sonographerEpV2GeneralEvalSchema, SonographerEpV2GeneralEvalType };
