import { Component } from "@angular/core";
import packageJson from "../../../../../package.json";

@Component({
  selector: "bbi-footer",
  templateUrl: "./bbi-footer.component.html",
  styleUrls: ["./bbi-footer.component.scss"],
})
export class BbiFooterComponent {
  public year = new Date().getFullYear();
  public version = packageJson.version;
}
