/**
 * Template sets anatomy appearing in Diagnostics.
 * When ever this is changed/updated the ids a corresponding change needs
 * to happen in the corresponding file that is used for the gallery view:
 * src/app/modules/patient-order/evaluation/models/evaluation-constants.ts
 */
export const earlyPregnancyTemplate = [
  {
    sectionName: "General Evaluation",
    templates: [
      {
        name: "Gestations",
        id: "gestationsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Embryo",
        id: "embryoVis",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Fetal Position",
        id: "fetalPosition",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Cervix",
        id: "cervixObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "CL",
        id: "cervixLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Placenta",
        id: "placentaObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Yolk Sac",
        id: "yolkSacObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "YS",
        id: "yolkSacLength",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        name: "Amniotic Fluid",
        id: "afvObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Maternal Structures",
    templates: [
      {
        name: "Uterus",
        tool: "ObservationArrow",
        id: "uterusObs",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Extrauterine Mass",
        id: "extrauterineMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "EU MASS L",
        id: "extrauterineMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "EU MASS H",
        id: "extrauterineMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "EU MASS W",
        id: "extrauterineMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Right Ovary",
        id: "rightOvaryObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "RT OV L",
        id: "rightOvaryLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RT OV H",
        id: "rightOvaryHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RT OV W",
        id: "rightOvaryWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Right Adnexal Mass",
        id: "rightAdnexalMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "RA MASS L",
        id: "rightAdnexalMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RA MASS H",
        id: "rightAdnexalMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RA MASS W",
        id: "rightAdnexalMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Left Ovary",
        id: "leftOvaryObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "LT OV L",
        id: "leftOvaryLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        id: "leftOvaryHeight",
        name: "LT OV H",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LT OV W",
        id: "leftOvaryWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Left Adnexal Mass",
        id: "leftAdnexalMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "LA MASS L",
        id: "leftAdnexalMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LA MASS H",
        id: "leftAdnexalMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LA MASS W",
        id: "leftAdnexalMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
    ],
  },
  {
    sectionName: "Biometry",
    templates: [
      {
        tool: "CustomLength",
        name: "CRL",
        id: "crownRumpLength",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        name: "Gestational Sac",
        id: "gestationalSacLocationObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "GS",
        id: "gestationalSacDiameter",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
    ],
  },
  {
    sectionName: "Brain",
    templates: [
      {
        name: "Cranium",
        id: "craniumObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Cardiac",
    templates: [
      {
        name: "Cardiac Activity",
        id: "cardiacActivity",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Fetal Heart Rate",
        id: "fetalHeartRate",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
];
