<div class="form-check cpt-check" *ngFor="let code of cptCodes">
  <input 
      type="checkbox"
      class="form-check-input"
      [name]="code.id" 
      [id]="code.id"
      [checked]="code.checked"
      (click)="setVisitType(code)"
  />
  <label *ngIf="currentUserRole === 'DRIVER'" class="form-check-label cpt-check-label" [for]="code.id">{{code.label}}</label>
  <label *ngIf="currentUserRole !== 'DRIVER'" class="form-check-label cpt-check-label" [for]="code.id">{{code.label}} ({{code.cptCodes.join(", ")}})</label>
</div>
