import * as yup from "yup";
import { evaluationSelectors } from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographer3rdTriV2ChestHeartSchema = yup
  .object()
  .shape({
    fetalHeartRate: yup
      .number()
      .meta({
        control: "dicom-selector",
        label: "Fetal Heart Rate(bpm)",
        dicomUnit: "bpm",
      } as FieldMeta)
      .required("Fetal Heart Rate is required")
      .test(
        "row-test",
        "Fetal Heart Rate should be positive integer",
        (v: any) => {
          return Number(v) >= 0;
        }
      ),
    heart4ObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "4 Chamber Heart",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    heart4Obs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    lvotObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "LVOT",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    lvotObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    rvotObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "RVOT",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    rvotObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    heart3ObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "3VV/3VTV",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    heart3Obs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    situsObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Situs",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    situsObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    aorticObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Aortic Arch",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    aorticObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    siVcObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Superior & Inferior Venae Cavae",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    siVcObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    ductalArchObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Ductal Arch",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    ductalArchObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    diaphragmObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Diaphragm",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    diaphragmObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
  })
  .required();

type Sonographer3rdTriV2ChestHeartType = yup.InferType<
  typeof sonographer3rdTriV2ChestHeartSchema
>;

export {
  sonographer3rdTriV2ChestHeartSchema,
  Sonographer3rdTriV2ChestHeartType,
};
