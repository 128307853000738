import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable, BehaviorSubject, throwError } from "rxjs";

import {
  CommentNote,
  CornerstoneTemplate,
  MeasurementNote,
  MeasurementNoteResponse,
  StudyNote,
  TagNote,
  LockedReportResponse,
  TagNoteDelete,
} from "src/app/models";
import { deprecatedStudyNotes } from "src/app/modules/patient-order/evaluation/models/evaluation-constants";
import { TenantService } from "src/app/core/services/tenant.service";
import { earlyPregnancyTemplate } from "../stores/template-jsons/early-pregnancy-template";
import { firstTrimesterTemplate } from "../stores/template-jsons/first-trimester-template";
import { secondTrimesterTemplate } from "../stores/template-jsons/second-trimester-template";
import { thirdTrimesterTemplate } from "../stores/template-jsons/third-trimester-template";
import { AlertService } from "./alert.service";
import { customToolsTemplate } from "../stores/template-jsons/custom-tools";
import { PatientOrderService } from "./patient-order.service";
import { catchError, retry } from "rxjs/operators";
import { v4 as uuidv4 } from "uuid";

@Injectable({ providedIn: "root" })
export class AnnotationService {
  private baseUrl: string;
  private _studyNotesBS: BehaviorSubject<StudyNote[]>;
  public studyId: string;
  public studyNotes: StudyNote[];

  public retryQueue = new Map(); //The key is the ID and the value is the number of retries so far

  constructor(
    private _http: HttpClient,
    private _alertService: AlertService,
    private _ts: TenantService,
    private _patientOrderService: PatientOrderService
  ) {
    /**
     *  Tenant needs to be called in the function because we have endpoints
     *  that utilize these methods which haven't set the tenant URL prior
     *  to be called. So, when the constructor calls for the tenant it is null
     **/
    this.baseUrl = `${environment.bbApiUrl}${environment.diagnosticPort}`;
    this._studyNotesBS = new BehaviorSubject<StudyNote[]>([]);
  }

  public msgOptions = {
    autoClose: false,
    keepAfterRouteChange: false,
  };

  public getStudyNotes(studyId?: string): Promise<StudyNote[]> {
    if (!studyId) studyId = this.studyId;
    return new Promise((resolve, reject) => {
      this._http
        .get(`${this.baseUrl}/${this._ts.getTenant()}/note/study/${studyId}`)
        .toPromise()
        .then((res: StudyNote[]) => {
          // Initialize studyNotes to an empty array so we start fresh.
          this.studyNotes = [];
          res.forEach((sn) => {
            // If its not a measurement just add it.
            if (sn.noteType !== "MEASUREMENT") {
              this.studyNotes.push(sn);
              return;
            }

            // If it is a measurement filter out any ones that were moved off screen to delete but didn't get deleted.
            const fsn = {
              ...sn,
              data: sn.data.filter(
                (a) =>
                  JSON.parse(a.csJson)?.handles.start.x > 0 &&
                  JSON.parse(a.csJson)?.handles.start.y > 0
              ),
            };
            this.studyNotes.push(fsn);
          });
          return resolve(this.studyNotes);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );

          // TODO: Add this to logging service
          // console.log("Error in getStudyNotes", error);
          reject(error);
        });
    });
  }

  getStudyNotesObservable(): Observable<StudyNote[]> {
    return this._studyNotesBS.asObservable();
  }

  setStudyNotesObservable(studyId: string | null = null): Promise<StudyNote[]> {
    try {
      // Set studyId if its passed in. This prevents us from passing it in everytime.
      // We will receive it on the first call by the resolver so this value should remain set throughout the life of the page.
      if (studyId) {
        this.studyId = studyId;
      }
      return new Promise((resolve) => {
        this.getStudyNotes(this.studyId)
          .then((studyNotes: StudyNote[]) => {
            this._studyNotesBS.next(studyNotes);
            return resolve(studyNotes);
          })
          .catch((error) => {
            this._studyNotesBS.next([]);
            this._alertService.error(
              `Error ${
                error.statusCode ? error.statusCode : ""
              }: An error was encountered, please refresh the page and try again.`,
              this.msgOptions
            );
            // TODO: Add this to logging service
            // console.log(
            //   "Error getting study notes in annotationService constructor.",
            //   error
            // );
          });
      });
    } catch (error) {
      console.log("Error in setStudyNotesObservable", error);
    }
  }

  public getDeprecatedStudyNotes(
    studyNotes: StudyNote[]
  ): typeof deprecatedStudyNotes {
    const deprecatedNotes = { ...deprecatedStudyNotes }; // Reset all to false
    const deprecatedKeys = Object.keys(deprecatedNotes);
    studyNotes.forEach((n) => {
      const obsKey = n.key.replace("_radio", "").replace("_comment", "");
      if (deprecatedKeys.includes(obsKey)) {
        deprecatedNotes[obsKey] = true;
      }
    });
    return deprecatedNotes;
  }

  public async getTagsForMom(sweepId: string): Promise<string[]> {
    if (!this.studyNotes) await this.getStudyNotes(this.studyId);
    return new Promise((resolve) => {
      let taggedImages = [];
      this.studyNotes.forEach((i) => {
        if (i.key === "paraMadre") {
          taggedImages = i.data
            .filter((t) => t.sweepId === sweepId)
            .map((t) => t.frameId);
        }
      });
      resolve(taggedImages);
    });
  }

  public setTagForMom(note: TagNote): Promise<StudyNote> {
    return new Promise((resolve, reject) => {
      const body = note;

      this._http
        .post(`${this.baseUrl}/${this._ts.getTenant()}/note/tag`, body)
        .toPromise()
        .then((res: StudyNote) => {
          return resolve(res);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          this._alertService.error(
            "Error tagging image for mom. Please try again."
          );
          reject(error);
        });
    });
  }

  public removeTagForMom(body: TagNoteDelete): Promise<StudyNote> {
    return new Promise((resolve, reject) => {
      this._http
        .request(
          "delete",
          `${this.baseUrl}/${this._ts.getTenant()}/note/tag/${body.tagId}`,
          { body }
        )
        .toPromise()
        .then((res: StudyNote) => {
          return resolve(res);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject(error);
        });
    });
  }

  public upsertNote(note: MeasurementNote, retryId?: string): Promise<boolean> {
    try {
      let retries = 0;
      if (retryId) retries = this.retryQueue.get(retryId);
      else retryId = uuidv4();
      if (retries > 5) {
        this._alertService.error(
          `We weren't able to save the ${note.key} annotation. Please refresh and try again.`,
          this.msgOptions
        );
        return;
      }
      return new Promise((resolve) => {
        const body = note;

        this._http
          .post(
            `${this.baseUrl}/${this._ts.getTenant()}/note/measurement`,
            body
          )
          .toPromise()
          .then((res: any) => {
            // Retry logic
            if (!res.id) {
              this.retryQueue.set(retryId, retries + 1);
              setTimeout(() => {
                this.upsertNote(note, retryId);
              }, retries * 1000);
            }
            this.setStudyNotesObservable(note.studyId);
            return resolve(true);
          })
          .catch(() => {
            this.retryQueue.set(retryId, retries + 1);
            setTimeout(() => {
              this.upsertNote(note, retryId);
            }, retries * 1000);
          });
      });
    } catch (error) {
      console.log("Error in upsertNote", error);
    }
  }

  public updateNoteValue(
    noteId: string,
    evaluationValue: string | number,
    retryId?: string
  ): Promise<boolean> {
    let retries = 0;
    if (retryId) retries = this.retryQueue.get(retryId);
    else retryId = uuidv4();
    if (retries > 5) {
      this._alertService.error(
        `We weren't able to update the note value of ${evaluationValue}. Please refresh and try again.`,
        this.msgOptions
      );
      return;
    }

    return new Promise((resolve) => {
      const body = {
        noteId,
        value: evaluationValue,
      };

      this._http
        .patch(
          `${
            this.baseUrl
          }/${this._ts.getTenant()}/note/${noteId}/${evaluationValue}`,
          body
        )
        .toPromise()
        .then((res: any) => {
          // Retry logic
          if (!res.id) {
            this.retryQueue.set(retryId, retries + 1);
            setTimeout(() => {
              this.updateNoteValue(noteId, evaluationValue, retryId);
            }, retries * 1000);
          }
          this.setStudyNotesObservable();
          return resolve(true);
        })
        .catch(() => {
          this.retryQueue.set(retryId, retries + 1);
          setTimeout(() => {
            this.updateNoteValue(noteId, evaluationValue, retryId);
          }, retries * 1000);
        });
    });
  }

  public upsertFrameConfig(
    frameId: string,
    sweepId: string,
    studyId: string,
    config: any,
    updateSweeps?: boolean,
    retryId?: string
  ): Promise<boolean> {
    let retries = 0;
    if (retryId) retries = this.retryQueue.get(retryId);
    else retryId = uuidv4();
    if (retries > 5) {
      this._alertService.error(
        `We weren't able to update the frame zoom, pan, or brightness of frame ${frameId}. Please refresh and try again.`,
        this.msgOptions
      );
      return;
    }

    return new Promise((resolve) => {
      const body = {
        id: frameId,
        studyId,
        sweepId,
        config,
      };
      this._http
        .post(`${this.baseUrl}/${this._ts.getTenant()}/frame/config`, body)
        .toPromise()
        .then(async (res: boolean) => {
          if (!res) {
            this.retryQueue.set(retryId, retries + 1);
            setTimeout(() => {
              this.upsertFrameConfig(
                frameId,
                sweepId,
                studyId,
                config,
                updateSweeps,
                retryId
              );
            }, retries * 1000);
          }

          this.setStudyNotesObservable();
          if (updateSweeps) {
            await this._patientOrderService.setSweepsObservable();
          }
          return resolve(res);
        })
        .catch(() => {
          this.retryQueue.set(retryId, retries + 1);
          setTimeout(() => {
            this.upsertFrameConfig(
              frameId,
              sweepId,
              studyId,
              config,
              updateSweeps,
              retryId
            );
          }, retries * 1000);
        });
    });
  }

  public upsertComment(note: CommentNote, retryId?: string): Promise<boolean> {
    let retries = 0;
    if (retryId) retries = this.retryQueue.get(retryId);
    else retryId = uuidv4();
    if (retries > 5) {
      this._alertService.error(
        `We weren't able to update the comment for ${note.key}. Please refresh and try again.`,
        this.msgOptions
      );
      return;
    }

    return new Promise((resolve) => {
      const body = note;

      this._http
        .post(`${this.baseUrl}/${this._ts.getTenant()}/note/comment`, body)
        .toPromise()
        .then((res: any) => {
          if (!res.id) {
            this.retryQueue.set(retryId, retries + 1);
            setTimeout(() => {
              this.upsertComment(note, retryId);
            }, retries * 1000);
          }

          this.setStudyNotesObservable(note.studyId);
          return resolve(true);
        })
        .catch(() => {
          this.retryQueue.set(retryId, retries + 1);
          setTimeout(() => {
            this.upsertComment(note, retryId);
          }, retries * 1000);
        });
    });
  }

  public getMeasurementNotes(
    sweepId: string
  ): Promise<MeasurementNoteResponse[]> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          `${
            this.baseUrl
          }/${this._ts.getTenant()}/note/measurement/sweep/${sweepId}`
        )
        .toPromise()
        .then((res: MeasurementNoteResponse[]) => {
          return resolve(res);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject(error);
        });
    });
  }

  public async deleteMeasurement(
    csUUID: string,
    studyId: string,
    retryId?: string
  ): Promise<MeasurementNoteResponse[]> {
    let retries = 0;
    if (retryId) retries = this.retryQueue.get(retryId);
    else retryId = uuidv4();
    if (retries > 5) {
      this._alertService.error(
        `We weren't able to update the delete a measurement. Please refresh and try again.`,
        this.msgOptions
      );
      return;
    }

    try {
      const res: any = (await this._http
        .delete(
          `${this.baseUrl}/${this._ts.getTenant()}/note/measurement/${csUUID}`
        )
        .toPromise()) as MeasurementNoteResponse[];
      if (!res) {
        this.retryQueue.set(retryId, retries + 1);
        setTimeout(() => {
          this.deleteMeasurement(csUUID, studyId, retryId);
        }, retries * 1000);
      }
      await this.setStudyNotesObservable(studyId);
      return res;
    } catch {
      this.retryQueue.set(retryId, retries + 1);
      setTimeout(() => {
        this.deleteMeasurement(csUUID, studyId, retryId);
      }, retries * 1000);
    }
  }

  public getCornerstoneTemplateById(annotationId: string): CornerstoneTemplate {
    const templates = [
      ...earlyPregnancyTemplate.flatMap((t) => t.templates),
      ...firstTrimesterTemplate.flatMap((t) => t.templates),
      ...secondTrimesterTemplate.flatMap((t) => t.templates),
      ...thirdTrimesterTemplate.flatMap((t) => t.templates),
      ...Object.values(customToolsTemplate.templates),
    ];

    return templates.find((t) => t.id === annotationId);
  }

  public async updateSexAnnotations(
    studyId: string,
    value: string
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const body = {
        studyId,
        value,
      };

      this._http
        .post(`${this.baseUrl}/${this._ts.getTenant()}/note/update-sex`, body)
        .toPromise()
        .then(() => {
          this.setStudyNotesObservable(studyId);
          return resolve(true);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject(error);
        });
    });
  }

  public async getLockedReport(studyId: string): Promise<LockedReportResponse> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          `${
            this.baseUrl
          }/${this._ts.getTenant()}/report/final/study/${studyId}`
        )
        .toPromise()
        .then((res: LockedReportResponse) => {
          return resolve(res);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          reject(error);
        });
    });
  }

  /**
   * upsertFrameConfigObs
   *
   * @param frameId
   * @param sweepId
   * @param studyId
   * @param config
   */
  public upsertFrameConfig$(
    frameId: string,
    sweepId: string,
    studyId: string,
    config: any
  ): Observable<any> {
    const body = {
      id: frameId,
      studyId,
      sweepId,
      config,
    };
    return this._http
      .post(`${this.baseUrl}/${this._ts.getTenant()}/frame/config`, body)
      .pipe(
        retry(3),
        catchError((errorRes) => {
          this._alertService.error(
            `Error ${
              errorRes.statusCode ? errorRes.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          // TODO: Add this to logging service
          // console.log("Error", error);
          return throwError(errorRes);
        })
      );
  }
}
