import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "src/app/core/services/auth.service";
import { BBIErrorHandler } from "src/app/core/services/error-handler.service";

@Injectable({ providedIn: "root" })
export class Interceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _errorHandler: BBIErrorHandler
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // catchError((err ) => {
      //   this._errorHandler.handleError(err);
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse && event.status === 401) {
          // TODO: Add this to logging service
          // console.log(event);
        }
      })
    );
  }
}
