<div #dropdown ngbDropdown  class="d-inline-block">
  <button type="button" id="noteDropdown" class="btn header-button" [disabled]="isModal" ngbDropdownToggle (click)="toggleDropdown()" >
    <div class="patient-icon dropdown-caret">{{patient.firstName}} {{patient.lastName}}</div>
  </button>
  <div id="patient-notes" class="px-2 py-2" ngbDropdownMenu aria-labelledby="patient-notes">
    <form>
      <div class="row">
        <div class="col-6 text-left fw-bold">Patient DOB: &nbsp; {{patient.dob | dicomDateTransform}}</div>
        <div class="col-6" *ngIf="canClaim">
          <div class="switch-container float-right"
               gaEvent="survey_claim_button" gaCategory="patient_order_survey" gaLabel="Survey Claim Button">
            <span (click)="claimOrder(true)" [ngClass]="hasSonographerAssigned ? 'active' : ''">Claim</span>
            <div (click)="claimOrder(!hasSonographerAssigned)" [ngClass]="hasSonographerAssigned ? 'circle-left' : 'circle-right'" class="switcher">
              <div class="circle"></div>
            </div>
            <span (click)="claimOrder(false)" [ngClass]="!hasSonographerAssigned ? 'active' : ''">Unclaim</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-left fw-bold">Patient Notes:</div>
      </div>
      <div class="row">
        <div class="col-12 text-left mb-3" >
          <textarea
            id="patientNotesDropdown"
            #patientNotesDropdown
            class="form-control"
            [(value)]="patient.notes"
            appTextareaGrow
            rows="5"
            [minRows]="5"
            [maxRows]="15"
            maxlength="3000"
            (input)="updateNotesAfterTimeout($event.target.value)"
            (blur)="updateNotes($event.target.value)"
          ></textarea>
      </div>
      </div>
    </form>
  </div>
</div>
