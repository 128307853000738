import * as yup from "yup";
import {
  sonographerFetalAnatomyAppearanceSelections,
  sonographerFetalAnatomyViewLimitations,
} from "../../global/constants/sonographer-constants";

const validationText = "An appearence selection is required";
const isFunc = (value: boolean): boolean => {
  return value; // ie cranium was visualized
};
const thenStatement = yup
  .string()
  .oneOf(sonographerFetalAnatomyAppearanceSelections)
  .required(validationText)
  .default(sonographerFetalAnatomyAppearanceSelections[0]);

const sonographer3rdTriFetalAnatomySchema = yup
  .object()
  .shape({
    craniumVis: yup.boolean().default(false),
    craniumApp: yup.string().when("craniumVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    craniumNotes: yup.string(),
    cavumVis: yup.boolean().default(false),
    cavumApp: yup.string().when("cavumVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    cavumNotes: yup.string(),
    choroidVis: yup.boolean().default(false),
    choroidApp: yup.string().when("choroidVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    choroidNotes: yup.string(),
    midlineVis: yup.boolean().default(false),
    midlineApp: yup.string().when("midlineVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    midlineNotes: yup.string(),
    neckSkinVis: yup.boolean().default(false),
    neckSkinApp: yup.string().when("neckSkinVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    neckSkinNotes: yup.string(),
    profileVis: yup.boolean().default(false),
    profileApp: yup.string().when("profileVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    profileNotes: yup.string(),
    noseLipsVis: yup.boolean().default(false),
    noseLipsApp: yup.string().when("noseLipsVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    noseLipsNotes: yup.string(),
    situsVis: yup.boolean().default(false),
    situsApp: yup.string().when("situsVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    situsNotes: yup.string(),
    heart4Vis: yup.boolean().default(false),
    heart4App: yup.string().when("heart4Vis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    heart4Notes: yup.string(),
    heart3Vis: yup.boolean().default(false),
    heart3App: yup.string().when("heart3Vis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    heart3Notes: yup.string(),
    rvotVis: yup.boolean().default(false),
    rvotApp: yup.string().when("rvotVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    rvotNotes: yup.string(),
    lvotVis: yup.boolean().default(false),
    lvotApp: yup.string().when("lvotVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    lvotNotes: yup.string(),
    diaphragmVis: yup.boolean().default(false),
    diaphragmApp: yup.string().when("diaphragmVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    diaphragmNotes: yup.string(),
    stomachVis: yup.boolean().default(false),
    stomachApp: yup.string().when("stomachVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    stomachNotes: yup.string(),
    kidneyVis: yup.boolean().default(false),
    kidneyApp: yup.string().when("kidneyVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    kidneyNotes: yup.string(),
    abdCordVis: yup.boolean().default(false),
    abdCordApp: yup.string().when("abdCordVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    abdCordNotes: yup.string(),
    bladderVis: yup.boolean().default(false),
    bladderApp: yup.string().when("bladderVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    bladderNotes: yup.string(),
    umbCordVis: yup.boolean().default(false),
    umbCordApp: yup.string().when("umbCordVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    umbCordNotes: yup.string(),
    spineVis: yup.boolean().default(false),
    spineApp: yup.string().when("spineVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    spineNotes: yup.string(),
    upperExtVis: yup.boolean().default(false),
    upperExtApp: yup.string().when("upperExtVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    upperExtNotes: yup.string(),
    lowerExtVis: yup.boolean().default(false),
    lowerExtApp: yup.string().when("lowerExtVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    lowerExtNotes: yup.string(),
    genitaliaVis: yup.boolean().default(false),
    genitaliaApp: yup.string().when("genitaliaVis", {
      is: isFunc,
      then: thenStatement,
      otherwise: yup.string(),
    }),
    genitaliaNotes: yup.string(),
    viewLimitations: yup
      .array()
      .of(yup.mixed().oneOf(sonographerFetalAnatomyViewLimitations))
      .default([]),
  })
  .required();

type Sonographer3rdTriFetalAnatomyType = yup.InferType<
  typeof sonographer3rdTriFetalAnatomySchema
>;

export {
  sonographer3rdTriFetalAnatomySchema as Sonographer3rdTriFetalAnatomySchema,
  Sonographer3rdTriFetalAnatomyType,
};
