import { Directive } from "@angular/core";
import {
  Validator,
  NG_VALIDATORS,
  ValidatorFn,
  FormControl,
} from "@angular/forms";

@Directive({
  selector: "[patientIdValidator]",
  providers: [
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useClass: PatientIdValidatorDirective,
      multi: true,
    },
  ],
})
export class PatientIdValidatorDirective implements Validator {
  validator: ValidatorFn;
  constructor() {
    this.validator = this.patientIdValidator();
  }

  validate(c: FormControl): any {
    return this.validator(c);
  }

  patientIdValidator(): ValidatorFn {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    return (control: FormControl) => {
      if (control.value != null && control.value !== "") {
        const letterRequirement = /[A-Za-z]/.test(control.value) ? 1 : 0;
        const numberRequirement = /\d/.test(control.value) ? 1 : 0;
        const isValid = letterRequirement + numberRequirement;
        if (isValid) {
          return null;
        }
        return {
          charactervalidator: { valid: false },
        };
      }
      return null;
    };
  }
}
