  <footer class="page-footer col-lg-3 col-md-5 col-sm-8 no-print">
    <div class="container-fluid footer-content">
        <!-- <div class="row">
          <div class="col-12 col-sm-6">
            Support | Contact Us | Disclaimer | Privacy Policy
          </div>
        </div> -->
      <div class="row" >
        <div class="my-auto mx-auto">
            <p class="mb-0">BB Imaging® TeleScan®V{{version}}</p>
            <p class="mb-0">9701 Brodie Lane Austin, TX 78748</p>
            <p class="mb-0"><a href="https://www.bbimaging.net/" target="_blank">www.bbimaging.net</a></p>
            <p class="mb-0">UDI: *+G224TELESCAN0/$$71.0Z*</p>
          </div>
      </div>
    </div>
  </footer>
