import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { throwError, Observable, BehaviorSubject } from "rxjs";
import { newPatient, Patient, PatientListResponse } from "src/app/models";
import { JWTTokenService } from "src/app/core/services/jwt.service";
import { TenantService } from "src/app/core/services/tenant.service";
import { environment } from "src/environments/environment";
import { AlertService } from "./alert.service";

@Injectable({ providedIn: "root" })
export class PatientService {
  private patientUrl: string;
  public currentPatient = newPatient();
  public msgOptions = {
    autoClose: true,
    keepAfterRouteChange: false,
  };

  private _currentPatient$: BehaviorSubject<Patient> =
    new BehaviorSubject<Patient>(null);

  constructor(
    private _http: HttpClient,
    private _jwt: JWTTokenService,
    private _alertService: AlertService,
    private _ts: TenantService
  ) {
    this.patientUrl = `${environment.bbApiUrl}${environment.patientPort}`;
  }

  getPatient$(): Observable<Patient> {
    return this._currentPatient$;
  }

  getCurrentPatient(): Observable<Patient> {
    return this._currentPatient$.asObservable();
  }

  setCurrentPatient(patient: Patient): void {
    this.currentPatient = patient || newPatient();
    if (
      this.currentPatient.institutionId === "" &&
      this._jwt.getParsedToken().institutionId
    ) {
      this.currentPatient.institutionId =
        this._jwt.getParsedToken().institutionId;
    }
    this._currentPatient$.next(patient);
  }

  getPatients(
    institutionId: string,
    pageSize = 10,
    currentPage = 1,
    search = "",
    orderBy = ""
  ): Observable<PatientListResponse> {
    const url = `${
      this.patientUrl
    }/${this._ts.getTenant()}/patients/institution/${institutionId}/search`;
    let params = new HttpParams();
    params = params.append("pageSize", `${pageSize}`);
    params = params.append("currentPage", `${currentPage}`);
    params = params.append("lastname", search);
    params = params.append("orderBy", orderBy);

    return this._http.get(url, { params }).pipe(
      map((res: PatientListResponse) => {
        return res;
      }),
      catchError((errorRes) => {
        return throwError(errorRes);
      })
    );
  }

  getPatient(patientId: string): Promise<Patient> {
    const url = `${
      this.patientUrl
    }/${this._ts.getTenant()}/patient/${patientId}`;
    return new Promise((resolve) => {
      this._http
        .get<Patient>(url)
        .toPromise()
        .then((res) => {
          this.setCurrentPatient(res);
          return resolve(res);
        })
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
          throwError(error);
        });
    });
  }

  getPatientObservable(patientId: string): Observable<Patient> {
    const url = `${
      this.patientUrl
    }/${this._ts.getTenant()}/patient/${patientId}`;
    return this._http.get(url).pipe(
      map((res: Patient) => {
        return res;
      }),
      catchError((errorRes) => {
        this._alertService.error(
          `Error ${
            errorRes.statusCode ? errorRes.statusCode : ""
          }: An error was encountered, please refresh the page and try again.`,
          this.msgOptions
        );
        return throwError(errorRes);
      })
    );
  }

  storePatient(patient: Patient): Promise<Patient> {
    const url = `${this.patientUrl}/${this._ts.getTenant()}/patient`;
    if (!patient.institutionId || patient.institutionId === "") {
      patient.institutionId = this._jwt.getParsedToken().institutionId;
    }

    if (patient.id) {
      return new Promise((resolve) => {
        this._http
          .put<Patient>(url, patient)
          .toPromise()
          .then((res) => {
            this._currentPatient$.next(res);
            return resolve(res);
          })
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          .catch((error) => {
            this._alertService.error(
              `Error ${
                error.statusCode ? error.statusCode : ""
              }: An error was encountered, please refresh the page and try again.`,
              this.msgOptions
            );
          });
      });
    }
    return new Promise((resolve) => {
      this._http
        .post<Patient>(url, patient)
        .toPromise()
        .then((res) => {
          return resolve(res);
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((error) => {
          this._alertService.error(
            `Error ${
              error.statusCode ? error.statusCode : ""
            }: An error was encountered, please refresh the page and try again.`,
            this.msgOptions
          );
        });
    });
  }

  //=============== Observables =================

  getPatientObs(patientId: string): Observable<Patient> {
    const url = `${
      this.patientUrl
    }/${this._ts.getTenant()}/patient/${patientId}`;
    return this._http.get(url).pipe(
      map((res: Patient) => {
        return res;
      })
    );
  }
}
