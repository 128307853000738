<nav class="navbar">
  <div class="align-items-center navbar-left scrolling-navbar ml-4">
    <div class="row">
      <div class="col-12">
        <img src="./assets/images/telescanlogo-registered.svg" class="logo-nav">
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">{{institutionName}}</div>
    </div>
  </div>

  <div class="navbar-right" *ngIf="createUserRegisterPage == false">
    <div class="user-icons">
      <a gaEvent="admin_header_admin" gaCategory="admin_header_logged_in" gaLabel="Admin Header Admin"
      class="d-inline-block navmenu-icon-spacing admin-icon-link" (click)="routeTo('adminList')" >
        <img src="assets/images/adminicon.svg" class="d-inline-block align-text-bottom">
        <span class="navmenu-icon-text">Admin</span>
      </a>
      <a gaEvent="admin_header_orders" gaCategory="admin_header_logged_in" gaLabel="Admin Header Orders"
        class="d-inline-block navmenu-icon-spacing admin-icon-link" (click)="routeTo('orderList')">
        <img src="assets/images/ordersicon.svg" class="d-inline-block align-text-bottom">
        <span class="navmenu-icon-text">Orders</span>
      </a>
      <a gaEvent="admin_header_display_name" gaCategory="admin_header_logged_in" gaLabel="Admin Header Display Name"
      class="d-inline-block navmenu-icon-spacing align-content-center admin-icon-link navbar-profile-link" [routerLink]="">
        <img alt="Profile Picture" class="d-inline-block align-text-bottom" src="assets/images/profileicon.svg" />
        <span class="navmenu-icon-text">Hi, {{displayName || "Admin"}}</span>
      </a>
      <div id="user_menu_section">
        <div id="user_menu">
          <div gaEvent="admin_header_view_profile" gaCategory="admin_header_logged_in" gaLabel="Admin Header View Profile" class="user_menu_link user_menu_first" (click)="viewProfile()">View Profile</div>
          <div gaEvent="admin_header_log_out" gaCategory="admin_header_logged_in" gaLabel="Admin Header Log Out" class="user_menu_link user_menu_last" (click)="onSignOut()">Log out</div>
        </div>
      </div>
    </div>
  </div>
</nav>

<!--title for admin page-->
<div class="container-fluid mt-3 mb-1" *ngIf="mainPageHeader && mainPageHeader !== ''">
  <div class="container-fluid">
    <div class="admin-header-title">
      <h2>{{mainPageHeader}}</h2>
    </div>
  </div>
</div>
