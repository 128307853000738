import { CornerstoneTemplate } from "src/app/models";

export const customToolsTemplate = {
  sectionName: "customTools",
  templates: {
    customObs: {
      name: "Custom Observation",
      id: "customObs",
      tool: "ObservationArrow",
      mouseButton: 1,
      templateCat: "Observation",
    },
    customRuler: {
      tool: "CustomLength",
      name: "Custom Ruler",
      id: "customRuler",
      mouseButton: 1,
      displayUnit: "cm",
      templateCat: "Measurement",
    },
    customEllipse: {
      tool: "CustomEllipse",
      name: "Custom Circumference",
      id: "customCircumference",
      mouseButton: 1,
      displayUnit: "cm",
      templateCat: "Measurement",
    },
  },
};

/**
 *
 * @param value Custom Tool ID
 * @returns Custom Tool Template, otherwise undefined
 */
export const getCustomToolById = (value): CornerstoneTemplate => {
  return Object.values(customToolsTemplate.templates).find(
    (c) => c.id === value
  );
};
