import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
//import dayjs from "dayjs";

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 * model date is represented as: 20230502 or YYYYMMDD
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {
  /**
   * Takes a string date in the format of 20230502 or YYYYMMDD of type number
   * and turns into a NgbDateStruct
   * @param value
   */
  fromModel(value: string | number | null): NgbDateStruct | null {
    if (!value) {
      return null;
    }
    const val = typeof value === "number" ? value.toString() : value;
    const year = val.slice(0, 4);
    const month = val.slice(4, 6);
    const day = val.slice(6, 8);

    return {
      day: Number.parseInt(day, 10),
      month: Number.parseInt(month, 10),
      year: Number.parseInt(year, 10),
    };
  }

  /**
   * Takes NgbDateStruct a transforms it into a string date in the format
   * of 20231102 or YYYYMMDD
   * @param date
   */
  toModel(date: NgbDateStruct | null): string | null {
    if (date === null) {
      return null;
    }
    const month = date.month.toString().padStart(2, "0");
    const day = date.day.toString().padStart(2, "0");
    const sDate = String(date.year + month + day);
    const validate = moment(sDate, "YYYYMMDD", true);
    return validate.isValid() ? sDate : "";
    // const validate = dayjs(sDate, "YYYYMMDD", true).isValid();
    // return validate ? sDate : "";
  }
}
