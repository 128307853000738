import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { JWTTokenService } from "src/app/core/services/jwt.service";
import { AuthService } from "src/app/core/services/auth.service";
import { UserService } from "src/app/core/services/user.service";
import { InstitutionService } from "../../services/institution.service";

@Component({
  selector: "bbi-admin-header",
  templateUrl: "./bbi-admin-header.component.html",
  styleUrls: ["./bbi-admin-header.component.scss"],
})
export class BbiAdminHeaderComponent implements OnInit {
  displayName: string;
  mainPageHeader = "";
  createUserRegisterPage = false;
  institutionName = "";

  constructor(
    private _authService: AuthService,
    private _userService: UserService,
    private _jwtService: JWTTokenService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _institutionService: InstitutionService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this._userService.currentUser) {
      this.displayName = `${this._userService.currentUser.firstName}`;
    } else {
      this._userService
        .refreshSetup()
        .then(() => {
          this.displayName = `${this._userService.currentUser.firstName}`;
          return true;
        })
        .catch(() => {});
    }

    this.updateHeader();
    this._router.events.subscribe(() => {
      this.updateHeader();
    });
    this.institutionName =
      await this._institutionService.getInstitutionNameById(
        this._jwtService.getParsedToken().institutionId
      );
  }

  menuButtonClick = (e: { stopPropagation: () => void }): void => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent("HTMLEvents");
      event.initEvent("resize", false, false);
      window.dispatchEvent(event);
    }, 350);
  };

  onSignOut(): void {
    this._authService
      .signOut()
      // eslint-disable-next-line promise/always-return
      .then(() => {
        this._router.navigate(["/login"]);
      })
      .catch(() => {
        // TODO: Add this to logging service
        // console.log("Error signing out the user.", error);
      });
  }

  viewProfile(): void {
    this._router.navigate(["/user/view-profile"]);
  }

  updateHeader(): void {
    const { url } = this._router;
    if (url.includes("/admin")) this.mainPageHeader = "Admin";
    else if (url.includes("patient-order/list")) this.mainPageHeader = "Orders";
    else if (url.includes("user/view-profile")) this.mainPageHeader = "Profile";
    else if (url.includes("user/register")) this.mainPageHeader = "Register";
    else this.mainPageHeader = "";

    this.createUserRegisterPage = url.includes("user/register");
  }

  // all this to avoid the never ending spinner
  routeTo(pageName: string): void {
    const { url } = this._router;
    if (pageName === "orderList" && !url.includes("/patient-order/list")) {
      this._router.navigate(["/patient-order/list"]);
    }
    if (pageName === "adminList" && !url.includes("/admin/list")) {
      this._router.navigate(["../../admin/list"], {
        relativeTo: this._activatedRoute,
      });
    }
  }
}
