import { Injectable } from "@angular/core";
import {
  CPTCodes,
  CPTCodesType,
  ModalListItem,
  StudyNote,
} from "src/app/models";
import { PatientOrderService } from "./patient-order.service";
import {
  AnnotationFilterListType,
  configToIdsMap,
} from "src/app/modules/patient-order/report/models/report-view.model";
import {
  AnatomyTemplate,
  FullAnatomyTemplateType,
  fullAnatomyTemplate,
} from "../stores/full-anatomy.template";
import {
  deprecatedStudyNotes,
  evaluationSections,
} from "src/app/modules/patient-order/evaluation/models/evaluation-constants";

@Injectable({ providedIn: "root" })
export class CptService {
  public CPTCodes = CPTCodes.filter((code) => code.id !== 4); // Filter out the Growth + BPP element
  public fullAnatomyTemplateMap = {};

  constructor(private _patientOrderService: PatientOrderService) {
    // create map from fullAnatomyTemplate
    fullAnatomyTemplate.forEach((section) => {
      section.templates.forEach((template) => {
        this.fullAnatomyTemplateMap[template.id] = template;
      });
    });
  }

  /**
   * Returns an array of CPT code objects for a patient order.
   * @param reportConfig Array of objects representing the anatomy to be included
   * in a patient order. This is the same as reportConfig in a patient order.
   * @returns Array of CPT code objects
   */
  public getCptCodes(
    reportConfig: AnnotationFilterListType[] | string
  ): CPTCodesType {
    try {
      const config =
        typeof reportConfig === "string"
          ? JSON.parse(reportConfig)
          : reportConfig;
      const cptCodes = CPTCodes.filter((code) => code.id !== 4); // Filter out the Growth + BPP element

      // If there is no sections array, return CPT codes array all unchecked
      if (!config || config.length === 0) {
        cptCodes.forEach((cptObj) => {
          cptObj.checked = false;
        });
      } else {
        // Parse reportConfig for checked anatomy and check CPT codes accordingly
        cptCodes.forEach((cptObj, idx) => {
          cptCodes[idx].checked = true;
          cptObj.visibileAnatomy.forEach((id) => {
            config.forEach((section) => {
              section.options.forEach((option) => {
                // If any anatomy options aren't selected then the code shouldn't be checked
                if (option.id === id && !option.checked) {
                  cptCodes[idx].checked = false;
                }
              });
            });
          });
        });
      }
      return cptCodes;
    } catch (error) {
      console.warn("Error in getCptCodes:", error);
    }
  }

  /**
   * Unchecks Growth/Follow if Anatomical Survey is checked. Growth/Follow Up is a
   * subset of Anatomical Survey and they should not both be checked at the same time.
   * @param cptCodes Array of CPT code objects
   * @returns Array of CPT code objects
   */
  public filterGrowthFollowUp(cptCodes: CPTCodesType): CPTCodesType {
    let hasAnatSurv = false;
    const updatedCptCodes = JSON.parse(JSON.stringify(cptCodes));
    updatedCptCodes.forEach((cptObj) => {
      if (cptObj.checked) {
        // If this is a 76805 set hasAnatSurv flag to true
        if (cptObj.id === 2) {
          hasAnatSurv = true;
        }
        // If this is a 76805 exam skip displaying 76816 because it is a subset of a 76805 but should not display like that
        if (hasAnatSurv && cptObj.id === 5) {
          cptObj.checked = false;
        }
      }
    });
    return updatedCptCodes;
  }

  /**
   * Updates whether a CPT code and its visible anatomy are checked or unchecked.
   * Sets the checked property of anatomy in reportConfig for a patient order.
   * @param cptCode Individual CPT code object
   * @returns Array of CPT code objects
   */
  public async setCptCode(
    cptCode: CPTCodesType[number]
  ): Promise<CPTCodesType> {
    try {
      const codeChecked = cptCode.checked;
      const patientOrder = this._patientOrderService.get();

      if (codeChecked) {
        // Check the anatomy for the checked CPT code
        const anatomyIds = cptCode.visibileAnatomy;
        patientOrder.reportConfig.forEach((section) => {
          let sectionChecked = true;
          section.options.forEach((anatomy) => {
            if (anatomyIds.includes(anatomy.id)) anatomy.checked = codeChecked;
            if (!anatomy.checked) sectionChecked = false;
          });
          section.checked = sectionChecked;
        });
      } else {
        // If a CPT code is unchecked, all CPT codes (and anatomy) should be unchecked
        patientOrder.reportConfig.forEach((section) => {
          section.checked = false;
          section.options.forEach((option) => {
            option.checked = false;
          });
        });
      }
      const config = JSON.stringify(patientOrder.reportConfig);
      await this._patientOrderService.updateOrderField(patientOrder.id, [
        { name: "reportConfig", value: config },
      ]);
      return this.getCptCodes(patientOrder.reportConfig);
    } catch (error) {
      console.warn("Error in setCptCodes:", error);
    }
  }

  public isAnatomyVisible(id): boolean {
    const reportConfigMap = this._patientOrderService.getReportConfigMap();

    // If the property of the id is undefined return true and only return false
    // the value is explicity set to false
    return reportConfigMap[id] && reportConfigMap[id]["checked"] !== false
      ? true
      : false;
  }

  public isIdVisible(id): boolean {
    const anatomyId = this.getIdFromVis(id);
    return this.isAnatomyVisible(anatomyId);
  }

  /**
   *
   * @param id ID from the reportConfig object which is checked
   * Returns a list of the anatomy IDs which should be shown for that visibility
   * Example: Input = cervix Output = ["cervixLength", "cervixObs", "cervixComment", "cervixRadio"]
   */
  public getIdsForVis(id): string[] {
    return configToIdsMap[id] || [];
  }

  /**
   *
   * @param id Anatomy ID
   * Returns the ID that anatomy belongs to in the reportConfig
   * Example: Input = cervixLength Output = cervix
   */
  public getIdFromVis(vis): string {
    const entry = Object.entries(configToIdsMap).find((obj) =>
      obj[1].find((anatId) => anatId === vis)
    );
    return entry && entry[0] ? entry[0] : "";
  }

  /**
   *
   * @param cptIds IDs from the reportConfig property. Default is the reportConfig of the current patient order
   * @returns FullAnatomyTemplate object
   */
  public getAnatomyFromCptIds(cptIds?: string[]): {
    [value: string]: ModalListItem;
  } {
    if (!cptIds) {
      cptIds = this._patientOrderService
        .get()
        .reportConfig.flatMap((section) => {
          return section.options.filter((option) => option.checked);
        })
        .map((a) => a.id);
    }
    const res = {};
    cptIds.forEach((cptId) => {
      this.getIdsForVis(cptId).forEach((anatId) => {
        const template = this.fullAnatomyTemplateMap[anatId];
        if (template) {
          res[anatId] = template;
        }
      });
    });
    return res;
  }

  getAllAnatomy(): {
    [value: string]: ModalListItem;
  } {
    const res = {};

    fullAnatomyTemplate
      .flatMap((s) => s.templates)
      .forEach((a) => (res[a.id] = a));
    return res;
  }

  /**
   * Returns an array of anatomy fields that should be included in a study for a
   * given section in evaluation based on which anatomy are checked in reportConfig.
   * @param selectedSection Section of evaluation. Must be one of const evaluationSections.type
   * @param reportConfig Property of a patient order
   * @returns Array of strings representing anatomy IDs for a section
   */
  public getAnatomyFields(
    selectedSection: string,
    reportConfig?: AnnotationFilterListType[]
  ): string[] {
    try {
      if (!reportConfig)
        reportConfig = this._patientOrderService.get()?.reportConfig;
      const sectionLabel = evaluationSections.find((section) => {
        return section.type === selectedSection;
      })?.label;
      const evalSection = reportConfig.find((section) => {
        return section.label === sectionLabel;
      });
      const sectionAnatomy: string[] = [];
      evalSection?.options.forEach((anatomy) => {
        // Map checked anatomy from report config to evaluation fields
        if (anatomy.checked) {
          const fields: string[] = configToIdsMap[anatomy.id] || [];
          fields.forEach((field) => {
            if (!sectionAnatomy.includes(field)) sectionAnatomy.push(field);
          });
        }
      });
      return sectionAnatomy;
    } catch (error) {
      console.warn(`Error in getAnatomyFields for ${selectedSection}`, error);
      return [];
    }
  }

  getCptLabel(
    reportConfig: AnnotationFilterListType[] | string,
    showCodes?: boolean
  ): string {
    let cptLabel = "";
    const cptCodes = this.filterGrowthFollowUp(this.getCptCodes(reportConfig));
    cptCodes.forEach((cptCode) => {
      if (cptCode.checked) {
        // If this is NOT the first label add the seperator
        cptLabel += cptLabel.length > 0 ? ", " : "";
        // Add the label
        cptLabel += cptCode["label"];
        // Add CPT code if specified
        if (showCodes) cptLabel += ` (${cptCode["cptCodes"]})`;
      }
    });
    if (cptLabel.length === 0) cptLabel = "--";
    return cptLabel;
  }

  getAnatomyNameFromId(anatId: string): string {
    try {
      const obsId = anatId.replace("_radio", "").replace("_comment", "");
      if (obsId === "sexEval") return "Sex";
      const template = fullAnatomyTemplate
        .flatMap((s) => s.templates)
        .find((t) => t.id === obsId);
      return template?.name || "";
    } catch (error) {
      console.error("Error in getAnatomyNameFromId", error);
    }
  }

  /**
   * Returns an array of anatomy IDs for radio buttons which do not have a selection.
   * Radio buttons belonging to anatomy that is not checked for the patient order are
   * not included.
   * @param studyNotes Array of all study notes for the patient order
   * @returns Array of anatomy field IDs
   */
  getMissingRadioNotes(studyNotes: StudyNote[]): string[] {
    try {
      const missingRadioNotes = [];
      const patientOrder = this._patientOrderService.get();
      patientOrder?.reportConfig
        ?.flatMap((section) => section.options)
        .filter((anatomy) => anatomy.checked)
        .flatMap((checkedAnatomy) => this.getIdsForVis(checkedAnatomy.id))
        .filter(
          (obsId) =>
            obsId.includes("_radio") &&
            !deprecatedStudyNotes.hasOwnProperty(obsId.split("_")[0])
        )
        .forEach((radioObsId) => {
          const radio = studyNotes?.find((sn) => sn.key === radioObsId);
          if (!radio) missingRadioNotes.push(radioObsId);
        });
      return missingRadioNotes;
    } catch (error) {
      console.error("Error in getMissingStudyNotes", error);
    }
  }

  getAnatomyObjectById(anatomyId: string): AnatomyTemplate {
    return fullAnatomyTemplate
      .flatMap((s) => s.templates)
      .find((a) => a.id === anatomyId);
  }
}
