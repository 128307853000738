import * as yup from "yup";
import { evaluationSelectors } from "../../global/constants/sonographer-constants";
import { FieldMeta, Option } from "../field-meta";

const sonographer1stTriV2AbdomenSchema = yup
  .object()
  .shape({
    stomachObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Stomach",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    stomachObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    bladderObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Bladder",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    bladderObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
    abdCordObsEval: yup
      .string()
      .meta({
        control: "radio-dicom-obs-eval",
        label: "Abdominal Cord Insertion",
        options: evaluationSelectors.map((e) => {
          return {
            key: e,
            value: e,
          } as Option;
        }),
      } as FieldMeta)
      .oneOf(evaluationSelectors),
    abdCordObs: yup.string().meta({
      control: "input-dicom-obs",
    } as FieldMeta),
  })
  .required();

type Sonographer1stTriV2AbdomenType = yup.InferType<
  typeof sonographer1stTriV2AbdomenSchema
>;

export { sonographer1stTriV2AbdomenSchema, Sonographer1stTriV2AbdomenType };
