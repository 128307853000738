/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { AlertService } from "src/app/global/services/alert.service";
import { NewUserResponse } from "src/app/models";
import { BootstrapService } from "./bootstrap.service";
import { JWTTokenService, ParsedJWT } from "./jwt.service";
import { TenantService } from "./tenant.service";
import { GAService } from "./gaservice";
import { UserService } from "./user.service";

export interface SignInCredentials {
  username: string;
  password: string;
  mfaCode: string;
}

export interface CreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface PasswordReset {
  code: string;
  newPassword: string;
}

export interface MfaInput {
  userId: string;
  mfaCode: string;
}

@Injectable({ providedIn: "root" })
export class AuthService {
  public session: any;

  constructor(
    private _bootstrapService: BootstrapService,
    private _http: HttpClient,
    private _jwt: JWTTokenService,
    private _alertService: AlertService,
    private _ts: TenantService,
    private _router: Router,
    private _gaService: GAService,
    private _userService: UserService
  ) {}

  public msgOptions = {
    autoClose: false,
    keepAfterRouteChange: false,
  };

  public signIn(credentials: SignInCredentials): Promise<ParsedJWT> {
    return new Promise((resolve, reject) => {
      const body = {
        username: credentials.username,
        password: credentials.password,
        mfaCode: credentials.mfaCode,
      };
      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/`,
          body,
          {
            responseType: "text",
          }
        )
        .toPromise()
        .then((token) => {
          // The backend returned a JSON object and indicates an invalid credentials
          if (!token || token.charAt(0) === "{") reject("Invalid credentials");
          this._jwt.setToken(token);
          const parsedJWT = this._jwt.parseJwt(token);
          if (parsedJWT?.mfaRequired) {
            this._router.navigate(["/login/mfa-code"]);
            return true;
          } else {
            return resolve(parsedJWT);
          }
        })
        .catch((error) => {
          console.log("error in Signin", error);
          const options = {
            autoClose: true,
            keepAfterRouteChange: false,
          };
          // TODO: Add this to logging service
          // console.log("Error", error);
          const res = JSON.parse(error.error);
          if (res.message === "Your account has been locked admin") {
            reject(
              new Error(
                "Your account has been locked out.  Please contact your admin to have it reset."
              )
            );
          } else if (res.message === "Your account has been locked") {
            const body = {
              username: credentials.username,
              url: window.location.origin,
            };

            this._http
              .post(
                `${environment.bbApiUrl}${
                  environment.authPort
                }/${this._ts.getTenant()}/auth/failedloginemail`,
                body,
                {
                  responseType: "text",
                }
              )
              .toPromise()
              .then((data) => {
                return false;
              })
              .catch((error) => {
                reject();
              });
            reject(
              new Error(
                "You have been locked out.  An email has been sent with a link to reset your password."
              )
            );
          } else {
            const attempts = res.message.split(":")[1];
            if (attempts > 1) {
              const attemptsLeft = 5 - attempts;
              reject(
                new Error(
                  `You have ${attemptsLeft} login attempts remaining before you will be locked out.`
                )
              );
            } else {
              reject();
            }
          }
        });
    });
  }

  public getUserFromAuthToken(authId: string): Promise<NewUserResponse> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          `
          ${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/user/${authId}/token
          `
        )
        .toPromise()
        .then((data: any) => {
          this._jwt.setToken(data.jwt);

          return resolve(data.user);
        })
        .catch((error) => {
          const { url } = this._router;
          if (!url.includes("user/register")) {
            this._alertService.error(
              `Error ${
                error.statusCode ? error.statusCode : ""
              }: An error was encountered, please refresh the page and try again.`,
              this.msgOptions
            );
          }
          // TODO: Add this to logging service
          // console.log("Error getting user ID", error);
          reject();
        });
    });
  }

  public haveUser(): string {
    return this._jwt.getToken();
  }

  public getSession(): null | ParsedJWT {
    // if (this._jwt.isTokenExpired()) {
    //   // TBD
    // } else {
    //   this._jwt.getParsedToken();
    // }
    return this._jwt.getParsedToken();
  }

  public updatePassword(newPass: string, userId = null): any {
    return new Promise<void>((resolve, reject) => {
      if (!userId) {
        userId = this._jwt.getParsedToken().userId;
      }
      const body = {
        userId,
        password: newPass,
      };

      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/passUpdate`,
          body,
          {
            responseType: "text",
          }
        )
        .toPromise()
        .then((response) => {
          if (response === "false") {
            return reject(new Error("duplicate password"));
          }
          return resolve();
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log(error);
        });
    });
  }

  public updatePasswordReset(
    userId: string,
    locked: string,
    username: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const body = {
        id: userId,
        locked,
        username,
      };

      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/clearreset`,
          body,
          {
            responseType: "text",
          }
        )
        .toPromise()
        .then((res) => {
          return resolve(res);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject();
        });
    });
  }

  public hasValidToken(): boolean {
    let valid = true;
    const parsedJWT = this._jwt.getParsedToken();
    valid = parsedJWT && !this._jwt.isTokenExpired() && !parsedJWT.mfaRequired;
    return valid;
  }

  signOut(): Promise<any> {
    return new Promise<void>((resolve) => {
      this._gaService.resetUser();
      this._jwt.setToken(null);
      this._jwt.removeToken();
      this._userService.setUserPreferences("");
      this._bootstrapService.clear();
      resolve();
    });
  }

  // TODO: remove following function? Unused/unchanged after 16 months... Andrew 3/27/23
  public completeNewPasswordChallenge(
    credentials: SignInCredentials
  ): Promise<any> {
    // To be implemented...
    return Promise.resolve({});
  }

  // TODO: remove following function? Unused/unchanged after 16 months... Andrew 3/27/23
  forgotPassword(email: string): Promise<any> {
    // To be implemented...
    return Promise.resolve({});
  }

  // TODO: remove following function? Unused/unchanged after 16 months... Andrew 3/27/23
  changePassword(
    email: string,
    resetCode: string,
    password: string
  ): Promise<any> {
    // To be implemented...
    return Promise.resolve({});
  }

  public sendPasswordResetEmail(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const body = {
        email,
      };
      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/resetemail`,
          body,
          {
            responseType: "text",
          }
        )
        .toPromise()
        .then((result) => {
          return resolve(result);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject(error);
        });
    });
  }

  public checkResetToken(token: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._http
        .get(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/user/checktoken/${token}`
        )
        .toPromise()
        .then((data: any) => {
          this._jwt.setToken(data.jwt);

          return resolve(data.user);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log(error);
          reject();
        });
    });
  }

  public validateMFA(userId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const body = {
        userId,
      };
      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/validateMfaEnabled`,
          body
        )
        .toPromise()
        .then((result) => {
          return resolve(result);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject(error);
        });
    });
  }

  public resendMfaCode(username: string, mfaCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const body = {
        username,
        mfaCode,
      };
      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/resendMfaCode`,
          body
        )
        .toPromise()
        .then((result) => {
          return resolve(result);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject(error);
        });
    });
  }

  public verifyMfaCode(mfaCode: string, username: string): Promise<ParsedJWT> {
    return new Promise((resolve, reject) => {
      const body = {
        mfaCode: mfaCode,
        username: username,
      };

      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/verifyMfaCode`,
          body,
          {
            responseType: "text",
          }
        )
        .toPromise()
        .then((token) => {
          // The backend returned a JSON object and indicates an invalid credentials
          if (token.charAt(0) === "{") reject("Invalid credentials");
          this._jwt.setToken(token);
          return resolve(this._jwt.parseJwt(token));
        })
        .catch((error) => {
          const options = {
            autoClose: true,
            keepAfterRouteChange: false,
          };
          this._alertService.error("Mfa Invalid", options);
          // TODO: Add this to logging service
          // console.log("Error", error);
          const res = JSON.parse(error.error);
          if (res.message === "Your account has been locked admin") {
            reject(
              new Error(
                "Your account has been locked out.  Please contact your admin to have it reset."
              )
            );
          } else if (res.message === "Your account has been locked") {
            this._http
              .post(
                `${environment.bbApiUrl}${
                  environment.authPort
                }/${this._ts.getTenant()}/auth/mfaLockedOutEmail`,
                body,
                {
                  responseType: "text",
                }
              )
              .toPromise()
              .then((data) => {
                return false;
              })
              .catch((error) => {
                reject();
              });
            reject(new Error("Your account has been locked out"));
          } else {
            const attempts = res.message.split(":")[1];
            if (res.message === "MFA Code is expired") {
              reject(new Error("Code has expired. Request a new code below."));
            } else if (res.message === "MFA Code did not match") {
              reject(
                new Error(
                  "The code did not match our records. Try again or request a new code below."
                )
              );
            } else {
              reject();
            }
          }
        });
    });
  }

  public saveGeneratedMfa(userId: string, mfaCode: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const body = {
        userId,
        mfaCode,
      };
      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/saveGeneratedMfa`,
          body,
          {
            responseType: "text",
          }
        )
        .toPromise()
        .then((result) => {
          return resolve(result);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject(error);
        });
    });
  }

  public sendCodeToPreferredMethod(
    userId: string,
    mfaCode: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const body = {
        userId,
        mfaCode,
      };
      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/sendCodeToPreferredMethod`,
          body
        )
        .toPromise()
        .then((result) => {
          return resolve(result);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject(error);
        });
    });
  }

  public verifyMaidenNameForImages(
    verificationToken: string,
    dob: string
  ): Promise<AuthMomResponse | null> {
    return new Promise((resolve, reject) => {
      // dob is in YYYY-MM-DD format
      const body = {
        dob,
        verificationToken,
      };
      this._http
        .post(
          `${environment.bbApiUrl}${
            environment.authPort
          }/${this._ts.getTenant()}/auth/verifymom`,
          body,
          {
            responseType: "text",
          }
        )
        .toPromise()
        .then((res) => {
          if (!res) return resolve(null);
          const resObj = JSON.parse(res) as AuthMomResponse;
          return resolve(resObj);
        })
        .catch((error) => {
          // TODO: Add this to logging service
          // console.log("Error", error);
          reject();
        });
    });
  }

  public clearJWTToken(): void {
    this._jwt.setToken(null);
    this._gaService.resetUser();
    this._jwt.removeToken();
    this._bootstrapService.clear();
  }
}
/* eslint-enable @typescript-eslint/no-unused-vars */

export interface AuthMomResponse {
  images: string[];
  firstName: string;
  createdOn: Date;
}
