<div class="login-page">
  <div class="container">
    <div class="login-centered">
      <div class="col-12 mt-5 p-0 g-0">
        <div class="row p-0 g-0">
          <div class="card auth-card row my-auto p-0">
            <div class="momBabyImage col-lg-4"></div>
            <div class="form-side col-8 col-md-6 col-sm-4">
              <a class="c-pointer">
                <span class="logo-single"></span>
              </a>
              <h6 class="mb-4">Forgot Password </h6>

              <form #passwordForm="ngForm" novalidate  (ngSubmit)="forgotPassword()" >
                <div class="mb-3">
                  <label class="form-group has-float-label">Email</label>
                  <input class="form-control" required email ngModel #email="ngModel" name="email"  (input)="validate()"/>
                  <div *ngIf="!(email?.errors?.email && passwordForm.submitted) && !email.valid && passwordForm.submitted" class="invalid_email_message">Email is required!</div>
                  <div *ngIf="email?.errors?.email && passwordForm.submitted" class="invalid_email_message">Email must be a valid address!</div>
                  <div *ngIf="!(!email.valid && passwordForm.submitted) && invalidEmail" class="invalid_email_message">Sorry there isn't a user with this email address.</div>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <a gaEvent="login_link" gaCategory="forgot_password" gaLabel="Return to Login" routerLink="/login">Return to Login</a>
                  <state-button [btnClass]="'btn btn-primary btn-lg btn-shadow'" [currentState]="buttonState"
                    [isDisabled]="buttonDisabled">
                    RESET
                  </state-button>
                </div>
              </form >

            <div class="form-side" *ngIf="messageSent">
              <h6 class="mb-4">A link to reset your password has been sent to the email provided. It is valid for 1 hour. </h6>
            </div>
            <div class="form-side" *ngIf="accountLocked">
              <h6 class="mb-4">You account has been locked out. Please contact your admin to have it reset. </h6>
            </div>
            </div>
          </div>
        </div>
        <div class="row">
          <bbi-footer></bbi-footer>
        </div>
      </div>
    </div>
  </div>
</div>

