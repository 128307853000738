import * as yup from "yup";
import {
  sonographerPregnancyTypeSelections,
  sonographerGestationalSacLocations,
  sonographerYolkSacSelections,
} from "../../global/constants/sonographer-constants";

const sonographerEpFetalEvalSchema = yup
  .object()
  .shape({
    pregnancyType: yup
      .string()
      .oneOf(sonographerPregnancyTypeSelections)
      .required("Pregnancy Type is required"),
    fetalHeartRate: yup
      .number()
      .typeError("This value must be a positive integer")
      .test(
        "fetalHeartRate-test",
        "This value must be a positive integer",
        (v) => {
          return typeof v === "number" && v >= 0;
        }
      )
      .required("Fetal heart rate is required"),
    cardiacActivity: yup.boolean().default(true),
    gestationalSacVis: yup.boolean().default(false),
    gestationalSacLocation: yup.string().when("gestationalSacVis", {
      is: (value: boolean) => {
        return value === true;
      },
      then: yup
        .string()
        .oneOf(sonographerGestationalSacLocations)
        .required("Gestational Sac Location is required"),
      otherwise: yup.string().nullable(),
    }),
    embryoVis: yup
      .boolean()
      .default(false)
      .when("gestationalSacVis", {
        is: (value: boolean) => {
          return value === true;
        },
        then: yup
          .boolean()
          .default(false)
          .required("Embryo visualization is required"),
        otherwise: yup.boolean().nullable(),
      }),
    yolkSacVis: yup
      .string()
      .oneOf(sonographerYolkSacSelections)
      .required("Yolk Sac Visualization selection is required"),
  })
  .required();

type SonographerEpFetalEvalType = yup.InferType<
  typeof sonographerEpFetalEvalSchema
>;

export {
  sonographerEpFetalEvalSchema as SonographerEpFetalEvalSchema,
  SonographerEpFetalEvalType,
};
