/**
 * Template sets anatomy appearing in Diagnostics.
 * When ever this is changed/updated the ids a corresponding change needs
 * to happen in the corresponding file that is used for the gallery view:
 * src/app/modules/patient-order/evaluation/models/evaluation-constants.ts
 */
export const firstTrimesterTemplate = [
  {
    sectionName: "General Evaluation",
    templates: [
      {
        name: "Gestations",
        id: "gestationsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Embryo",
        id: "embryoVis",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Fetal Position",
        id: "fetalPosition",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Cervix",
        id: "cervixObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "CL",
        id: "cervixLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Placenta",
        id: "placentaObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Placental Cord Insertion",
        id: "placentalCordInsertionObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Umbilical Cord",
        id: "umbCordObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Yolk Sac",
        id: "yolkSacObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "YS",
        id: "yolkSacLength",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        name: "Amniotic Fluid",
        id: "afvObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Maternal Structures",
    templates: [
      {
        name: "Uterus",
        id: "uterusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Extrauterine Mass",
        id: "extrauterineMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "EU MASS L",
        id: "extrauterineMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "EU MASS H",
        id: "extrauterineMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "EU MASS W",
        id: "extrauterineMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Right Ovary",
        id: "rightOvaryObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "RT OV L",
        id: "rightOvaryLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RT OV H",
        id: "rightOvaryHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RT OV W",
        id: "rightOvaryWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Right Adnexal Mass",
        id: "rightAdnexalMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "RA MASS L",
        id: "rightAdnexalMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RA MASS H",
        id: "rightAdnexalMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "RA MASS W",
        id: "rightAdnexalMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Left Ovary",
        id: "leftOvaryObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "LT OV L",
        id: "leftOvaryLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LT OV H",
        id: "leftOvaryHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LT OV W",
        id: "leftOvaryWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        name: "Left Adnexal Mass",
        id: "leftAdnexalMassObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "LA MASS L",
        id: "leftAdnexalMassLength",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LA MASS H",
        id: "leftAdnexalMassHeight",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
      {
        tool: "CustomLength",
        name: "LA MASS W",
        id: "leftAdnexalMassWidth",
        mouseButton: 1,
        displayUnit: "cm",
        templateCat: "Measurement",
      },
    ],
  },
  {
    sectionName: "Biometry",
    templates: [
      {
        tool: "CustomLength",
        name: "CRL",
        id: "crownRumpLength",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        name: "Gestational Sac",
        id: "gestationalSacLocationObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "GS",
        id: "gestationalSacDiameter",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
    ],
  },
  {
    sectionName: "Brain",
    templates: [
      {
        name: "Posterior Fossa",
        id: "posteriorFossaObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        tool: "CustomLength",
        name: "NT",
        id: "nuchalTranslucency",
        mouseButton: 1,
        displayUnit: "mm",
        templateCat: "Measurement",
      },
      {
        name: "RT Choroid Plexus",
        id: "rightChoroidPlexusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Choroid Plexus",
        id: "leftChoroidPlexusObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Midline Falx",
        id: "midlineFalxObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Cranium",
        id: "craniumObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Face",
    templates: [
      {
        name: "Profile",
        id: "profileObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Nasal Bone",
        id: "nasalBoneObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Orbits",
        id: "orbitsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Cardiac",
    templates: [
      {
        name: "Cardiac Activity",
        id: "cardiacActivity",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Fetal Heart Rate",
        id: "fetalHeartRate",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "4 Chamber Heart",
        id: "heart4Obs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LVOT",
        id: "lvotObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "RVOT",
        id: "rvotObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "3VV / 3VTV",
        id: "heart3Obs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Situs",
        id: "situsObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Aortic Arch",
        id: "aorticObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Superior & Inferior Venae Cavae",
        id: "siVcObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Ductal Arch",
        id: "ductalArchObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Diaphragm",
        id: "diaphragmObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Abdomen",
    templates: [
      {
        name: "Stomach",
        id: "stomachObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "RT Kidney",
        id: "rightKidneyObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Kidney",
        id: "leftKidneyObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Bladder",
        id: "bladderObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "Abdominal Cord Insert",
        id: "abdCordObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Spine",
    templates: [
      {
        name: "C Spine",
        id: "cSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "T Spine",
        id: "tSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "L Spine",
        id: "lSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "S Spine",
        id: "sSpineObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
  {
    sectionName: "Extremities",
    templates: [
      {
        name: "RT Leg",
        id: "rightLegObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "RT Foot",
        id: "rightFootObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "RT Arm",
        id: "rightArmObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "RT Hand",
        id: "rightHandObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Leg",
        id: "leftLegObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Foot",
        id: "leftFootObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Arm",
        id: "leftArmObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
      {
        name: "LT Hand",
        id: "leftHandObs",
        tool: "ObservationArrow",
        mouseButton: 1,
        templateCat: "Observation",
      },
    ],
  },
];
