import { PipeTransform, Pipe } from "@angular/core";
// import { DataNotEnteredPipe } from "./data-not-entered.pipe";

@Pipe({ name: "humanize" })
export class HumanizePipe implements PipeTransform {
  transform(input: string): string {
    // Exit early if input is undefined or null
    if (!input || input === "" || input === "null") return "";

    if (input === "notVisualized") return "Not visualized";

    // Split on hyphens and underscores
    return input
      .toLowerCase()
      .split(/[_-]+/g)
      .map((f) => f.charAt(0).toUpperCase() + f.slice(1))
      .join(" ");
  }
}
